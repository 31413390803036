import React from 'react';
const LeadershipItem = ({data}) => {
  return (
    <div className="flex flex-col space-y-2 lg:space-y-4 items-center justify-center relative max-w-[340px] w-full mx-auto">
        <div className="w-24 h-24 overflow-hidden">
            <img src={data.image} alt={data.title} className="object-contain"/>
        </div>
        <h4 className="font-PlusJakartaSans font-bold lg:text-xl xl:text-2xl text-dark-teal-500 capitalize">{data.title}</h4>
        <div dangerouslySetInnerHTML={{__html:data.description}} className="text-xs xl:text-sm font-Inter text-slate-800 text-center"></div>
    </div>
  );
};

export default LeadershipItem;