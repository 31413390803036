import React,{useEffect,useState,useRef} from 'react';
import SidebarMenuBlocks from '../components/navigation/SidebarMenuBlocks';
import { useDispatch,useSelector} from 'react-redux';

import { postData } from '../services/api';
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../helper/useWindowDimensions";
import {classNames} from "../helper/classNames";

const DefaultSidebar = ({ isMenuOpen, setIsMenuOpen, getIsChange}) => {
  const { i18n,t } = useTranslation();
  const role =  localStorage.getItem("role");
  const isTrial = localStorage.getItem("isTrial");
  const auth_user = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  //const settings = useSelector(state => state.auth?.settings);  
  const [logo,setLogo]= useState('');
  const [companyName,setCompanyName] = useState('');
  const [isStep,setIsStep] = useState(0); 
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g,''); 
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if(auth_user?._id){
      dispatch(async () => {
        try {
          const results2 = await postData('successor/list', {
            sortQuery:{'created_at':-1},
            is_interim:2,
          });
          if (results2.data && results2.data.length>0) {
            results2.data = results2.data.map((value) => {
              let isEditable = false;
              let desire =(Array.isArray(value?.desire_details) && value?.desire_details.length > 0)?value?.desire_details[0]?.isPass: ""
              let loyalty =(Array.isArray(value?.loyalty_details) && value?.loyalty_details.length > 0)?value?.loyalty_details[0]?.isPass: "";
              let retirement =(Array.isArray(value?.retirement_details) && value?.retirement_details.length > 0)?value?.retirement_details[0]?.isPass: "";              
              if(desire === true && loyalty === true && retirement === true){
                isEditable = true;
              }  
              return {
                ...value,
                isEditable:isEditable
              }
            })
            let experience = results2.data.filter((item) => !item.experience);
            let with_credit_union = results2.data.filter((item) => !item.with_credit_union);
            let education = results2.data.filter((item) => !item.education);
            let performance = results2.data.filter((item) => !item.performance);
            let loyalty = results2.data.filter((item) => !item.loyalty);
            let desire_to_advance = results2.data.filter((item) => !item.desire_to_advance);
            let retirement_window = results2.data.filter((item) => !item.retirement_window);
            if(experience.length === 0 && with_credit_union.length === 0 && education.length === 0 && performance.length === 0 && loyalty.length === 0 && desire_to_advance.length === 0 && retirement_window.length === 0){
              setIsStep(2);
              let concernRecord = results2.data.filter(item => item.concerns && item.isEditable).map(item => item.concerns.filter((row) => !row.option_id));
              let concernEmptyRecord = results2.data.filter(item => item.concerns.length === 0 && item.isEditable);
              let finalConcern = concernRecord.reduce((currentCount, row) => currentCount + row.length, 0);            
              let checkConcern = results2.data.filter((concern) => concern.overall_score>0  && concern.isEditable);
              if(checkConcern && checkConcern.length>0 && finalConcern === 0 && concernEmptyRecord.length === 0){
                setIsStep(3);
                let predictorRecord = results2.data.filter(item => item.predictors && item.isEditable).map(item => item.predictors.filter((row) => !row.option_id));  
                let predictorEmptyRecord = results2.data.filter(item => item.predictors.length === 0 && item.isEditable);            
                let finalPredictor = predictorRecord.reduce((currentCount, row) => currentCount + row.length, 0);               
                let checkPredictor = results2.data.filter((predictor) => predictor.overall_weighted_score >0 && predictor.isEditable);
                if(checkPredictor && checkPredictor.length>0 && finalPredictor === 0 && predictorEmptyRecord.length === 0){
                  setIsStep(6);
                  let competency_fit = results2.data.filter((item) => !item.competency_fit);
                  let time_frame = results2.data.filter((item) => !item.time_frame);                
                  if(competency_fit.length === 0 && time_frame.length ===0){
                    setIsStep(7);
                    const results = await postData('position/list', {
                      sortQuery:{'created_at':-1}
                    });
                    if (results.data && results.data.length>0 ) {
                      setIsStep(8);
                      let checkPosData = results.data.filter((pos) => pos.position_competencies !=='');
                      if(checkPosData && checkPosData.length>0){
                        setIsStep(9);                    
                      }
                    }
                  }
                }
                
              }
            }else{
              setIsStep(1)
            }         
          }else{
            setIsStep(1)
          }        
        } catch (err) {
          console.log(err.message);
        }
      });
    } 
  }, [dispatch,window.location.pathname,getIsChange,auth_user]); 

  
  // useEffect(() => {
  //   if(settings){
  //     setCompanyName(settings?.site_title);
  // }, [settings]); 
  //console.log('role',role)
 let sidebarMenu = [];
if( role && role === 'master-admin'){
  sidebarMenu = [
    {_id:'0', menuTitle:'', menuData:[
      {_id:'0-1',menuLabel:t('dashboard'), menuLink:'/dashboard-statistics', menuIcon:'fa-grid-2', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true, }
    ]},
    {_id:'1', menuTitle:t('user_management'), menuData:[
      {_id:'1-1',menuLabel:t('administrators'), menuLink:'/admins', menuIcon:'fa-users', menuIconColor:'text-rose-500',menuIsLink: true,isCollapse : true, },
      {_id:'1-2',menuLabel:t('companies'), menuLink:'/companies', menuIcon:'fa-building', menuIconColor:'text-amber-500',menuIsLink: true ,isCollapse : true,},
      {_id:'1-3',menuLabel:t('sales_and_consultants'), menuLink:'/salesstaff', menuIcon:'fa-handshake', menuIconColor:'text-sky-500',menuIsLink: true ,isCollapse : true,},
      {_id:'1-4',menuLabel:t('user_permission'), menuLink:'/permission', menuIcon:'fa-triangle-instrument', menuIconColor:'text-emerald-500',menuIsLink: true ,isCollapse : true,},
    ]},
    {_id:'2', menuTitle:t('plan_managment'), menuData:[
      {_id:'2-1',menuLabel:t('plans_and_pricing'), menuLink:'/pricing', menuIcon:'fa-hand-holding-dollar', menuIconColor:'text-lime-500',menuIsLink: true,isCollapse : true, },
      {_id:'2-2',menuLabel:t('plan_features'), menuLink:'/plan-features', menuIcon:'fa-tasks', menuIconColor:'text-rose-500',menuIsLink: true,isCollapse : true, },
      {_id:'2-3',menuLabel:t('subscriptions'), menuLink:'/subscriptions', menuIcon:'fa-money-simple-from-bracket', menuIconColor:'text-emerald-500',menuIsLink: true,isCollapse : true, },
      {_id:'2-4',menuLabel:t('new_account_setup'), menuLink:'/new-account', menuIcon:'fa-arrow-right-to-arc', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true, },
      {_id:'2-5',menuLabel:t('payment'), menuLink:'/payment', menuIcon:'fa-dollar-sign', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true, }
      // {_id:'2-6',menuLabel:'Activation Codes', menuLink:'/activationcodes', menuIcon:'fa-stars', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true, }

    ]},
    {_id:'5', menuTitle:t('sales'), menuData:[
      {_id:'5-1',menuLabel:t('sales_tracking'), menuLink:'/new-sale', menuIcon:'fa-users', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true, },
      {_id:'5-2',menuLabel:t('lead_tracking'), menuLink:'/lead-tracking', menuIcon:'fa-list', menuIconColor:'text-violet-500',menuIsLink: true,isCollapse : true, }
    ]},
    {_id:'4', menuTitle:t('cms'), menuData:[
      // {_id:'4-1',menuLabel:'Industry', menuLink:'/industries', menuIcon:'fa-industry', menuIconColor:'text-lime-500' },
      {_id:'4-1',menuLabel:t('positions'), menuLink:'/positions', menuIcon:'fa-user-tie', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-2',menuLabel:t('education'), menuLink:'/educations', menuIcon:'fa-graduation-cap', menuIconColor:'text-cyan-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-3',menuLabel:t('experience'), menuLink:'/experiences', menuIcon:'fa-history', menuIconColor:'text-violet-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-4',menuLabel:t('desire_to_advance'), menuLink:'/desires', menuIcon:'fa-arrow-up-short-wide', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-5',menuLabel:t('loyalty'), menuLink:'/loyalties', menuIcon:'fa-handshake', menuIconColor:'text-emerald-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-6',menuLabel:t('performance'), menuLink:'/performances', menuIcon:'fa-ribbon', menuIconColor:'text-rose-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-7',menuLabel:t('retirement_window'), menuLink:'/returements', menuIcon:'fa-clock', menuIconColor:'text-teal-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-8',menuLabel:t('possible_areas_of_concern'), menuLink:'/concern-headings', menuIcon:'fa-shield-exclamation', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-9',menuLabel:t('predictors'), menuLink:'/predictor-headings', menuIcon:'fa-wand-magic-sparkles', menuIconColor:'text-orange-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-10',menuLabel:t('position_competencies'), menuLink:'/competencies', menuIcon:'fa-circle-waveform-lines', menuIconColor:'text-purple-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-11',menuLabel:t('emergency_replacement_plan'), menuLink:'/emergency-replacement-plan', menuIcon:'fa-check-circle', menuIconColor:'text-emerald-500' ,menuIsLink: true,isCollapse : true,},
      {_id:'4-12',menuLabel:t('email_template'), menuLink:'/email-templates', menuIcon:'fa-envelope', menuIconColor:'text-yellow-500' ,menuIsLink: true,isCollapse : true,},       
      {_id:'4-13',menuLabel:t('settings'), menuLink:'/site-settings', menuIcon:'fa-wrench', menuIconColor:'text-orange-500',menuIsLink: true,isCollapse : true,},
      {_id:'4-14',menuLabel:t('faq'), menuLink:'/faq', menuIcon:'fa-question-circle', menuIconColor:'text-pink-500' ,menuIsLink: true,isCollapse : true,}, 
      {_id:'4-15',menuLabel:t('successionplan'), menuLink:'/succession-plans', menuIcon:'fa-question-circle', menuIconColor:'text-cyan-500' ,menuIsLink: true,isCollapse : true,},
      {_id:'4-16',menuLabel:t('leadership_plan'), menuLink:'/leaderships', menuIcon:'fa-people-group', menuIconColor:'text-violet-500' ,menuIsLink: true,isCollapse : true,},     
      {_id:'4-17',menuLabel:t('policy_statement'), menuLink:'/policy', menuIcon:'fa-file-contract', menuIconColor:'text-amber-500' ,menuIsLink: true,isCollapse : true,},  
      {_id:'4-18',menuLabel:t('board_effectiveness'), menuLink:'/board-effectiveness', menuIcon:'fa-list', menuIconColor:'text-yellow-500',menuIsLink: true},    
      {_id:'4-19',menuLabel:t('executive_audit_360'), menuLink:'/executive-audit-360', menuIcon:'fa-list', menuIconColor:'text-cyan-500',menuIsLink: true},
      // {_id:'4-13',menuLabel:'Instructions', menuLink:'/instructions', menuIcon:'fa-chalkboard', menuIconColor:'text-purple-500' ,menuIsLink: true,isCollapse : true,},
    ]},
    {_id:'3', menuTitle:t('front_management'), menuData:[
      {_id:'3-2',menuLabel:t('banners'), menuLink:'/banners', menuIcon:'fa-image', menuIconColor:'text-pink-500' ,menuIsLink: true},
      {_id:'3-3',menuLabel:t('about_us'), menuLink:'/about', menuIcon:'fa-info-circle', menuIconColor:'text-amber-500' ,menuIsLink: true},
      {_id:'3-4',menuLabel:t('leads'), menuLink:'/leads', menuIcon:'fa-list', menuIconColor:'text-amber-500',menuIsLink: true },
      // {_id:'3-3',menuLabel:'Benefits', menuLink:'/benefits', menuIcon:'fa-list', menuIconColor:'text-amber-500' },
      {_id:'3-5',menuLabel:t('leadership'), menuLink:'/teams', menuIcon:'fa-people-group', menuIconColor:'text-violet-500' ,menuIsLink: true},
      {_id:'3-6',menuLabel:t('testimonials'), menuLink:'/testimonials', menuIcon:'fa-quote-left', menuIconColor:'text-pink-500',menuIsLink: true},
      {_id:'3-7',menuLabel:t('customer_contacts'), menuLink:'/enquiries', menuIcon:'fa-question-circle', menuIconColor:'text-orange-500',menuIsLink: true },
      {_id:'3-8',menuLabel:t('newsletter'), menuLink:'/newsletters', menuIcon:'fa-paper-plane', menuIconColor:'text-yellow-500',menuIsLink: true },
      {_id:'3-9',menuLabel:t('terms_and_conditions'), menuLink:'/terms', menuIcon:'fa-image', menuIconColor:'text-cyan-500',menuIsLink: true},
      // {_id:'3-8',menuLabel:'Contact', menuLink:'/contact', menuIcon:'fa-file-lines', menuIconColor:'text-pink-500'},
      {_id:'3-10',menuLabel:t('access_demo'), menuLink:'/access-demo', menuIcon:'fa-list', menuIconColor:'text-cyan-500',menuIsLink: true},
      {_id:'3-11',menuLabel:t('articles'), menuLink:'/articles', menuIcon:'fa-image', menuIconColor:'text-cyan-500',menuIsLink: true},
      // {_id:'3-12',menuLabel:t('account_support'), menuLink:'/helps', menuIcon:'fa-question-circle', menuIconColor:'text-emerald-500',menuIsLink: true},
      {_id:'3-13',menuLabel:t('clients'), menuLink:'/clients', menuIcon:'fa-user', menuIconColor:'text-orange-500',menuIsLink: true},      
    ]}
  ]
 
}else if( role && role === 'basic-administrator'){
  sidebarMenu = [
    {_id:'0', menuTitle:'', menuData:[
      {_id:'0-1',menuLabel:t('dashboard'), menuLink:'/dashboard-statistics', menuIcon:'fa-grid-2', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true, }
    ]}
  ]
}else if( role && (role === 'sales-admin' || role === 'sales-staff' || role === 'consultant')){ 
    sidebarMenu = [
      {_id:'0', menuTitle:'', menuData:[
        {_id:'0-1',menuLabel:t('dashboard'), menuLink:'/dashboard-statistics', menuIcon:'fa-grid-2', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true }
      ]},
      // { ...((auth_user?.permission?.filter((item) => (item === 'pricing_list' || item === 'subscription_list' || item === 'new_account_setup' || item === 'payment_list')).length>0) && 
      { _id:'2', menuTitle:t('plan_managment'), menuData:[
        {
          ...((auth_user?.permission?.filter((item) => item === 'pricing_list').length>0) && 
          {_id:'2-1',menuLabel:t('plans_and_pricing'), menuLink:'/pricing', menuIcon:'fa-hand-holding-dollar', menuIconColor:'text-lime-500',menuIsLink: true,isCollapse : false})
        },
        {
          ...(auth_user?.permission?.filter((item) => item === 'subscription_list').length>0) && 
          {_id:'2-2',menuLabel:t('subscriptions'), menuLink:'/subscriptions', menuIcon:'fa-money-simple-from-bracket', menuIconColor:'text-emerald-500',menuIsLink: true,isCollapse : false, }
        },
        {
          ...(auth_user?.permission?.filter((item) => item === 'new_account_setup').length>0) && 
          {_id:'2-3',menuLabel:t('new_account_setup'), menuLink:'/new-account', menuIcon:'fa-arrow-right-to-arc', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : false, }
        },
        {
          ...(auth_user?.permission?.filter((item) => item === 'payment_list').length>0) &&
          {_id:'2-4',menuLabel:t('payment'), menuLink:'/payment', menuIcon:'fa-dollar-sign', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : false, }
        },  
        // {_id:'2-4',menuLabel:'Activation Codes', menuLink:'/activationcodes', menuIcon:'fa-stars', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true, }
      ]},
      //)},
      {_id:'3', menuTitle:t('front_management'), menuData:[      
        {
          ...(auth_user?.permission?.filter((item) => item === 'article_list').length>0) &&
          {_id:'2-5',menuLabel:t('articles'), menuLink:'/articles', menuIcon:'fa-light fa-fw fa-image', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : false, }
        },
        {
          ...(auth_user?.permission?.filter((item) => item === 'testimonial_list').length>0) &&
          {_id:'2-6',menuLabel:t('testimonials'), menuLink:'/testimonials', menuIcon:'fa-light fa-fw fa-quote-left', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : false, }
        }
         
         // {_id:'2-4',menuLabel:'Activation Codes', menuLink:'/activationcodes', menuIcon:'fa-stars', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : true, }
      ]},
    ]
}else if( role && (role === 'content-manager')){ 
  sidebarMenu = [
    {_id:'0', menuTitle:'', menuData:[
      {_id:'0-1',menuLabel:t('dashboard'), menuLink:'/dashboard-statistics', menuIcon:'fa-grid-2', menuIconColor:'text-amber-500',menuIsLink: true,isCollapse : true }
    ]},
    {_id:'3', menuTitle:t('front_management'), menuData:[      
      {
        ...(auth_user?.permission?.filter((item) => item === 'article_list').length>0) &&
        {_id:'2-5',menuLabel:t('articles'), menuLink:'/articles', menuIcon:'fa-light fa-fw fa-image', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : false, }
      },
      {
        ...(auth_user?.permission?.filter((item) => item === 'testimonial_list').length>0) &&
        {_id:'2-6',menuLabel:t('testimonials'), menuLink:'/testimonials', menuIcon:'fa-light fa-fw fa-quote-left', menuIconColor:'text-pink-500',menuIsLink: true,isCollapse : false, }
      }
    ]},
  ]
}else if( role && role === 'customer-admin' || role === 'customer-subadmin'){
  sidebarMenu = [
    {_id:'1', menuTitle:'', menuData:[
      {_id:'1-1',menuLabel:t('dashboard'), menuLink:'/dashboard', menuIcon:'fa-grid-2', menuIconColor:'text-amber-500' , menuIsLink: true},     
    ]},    
    {_id:'3', menuTitle:t('succession_plan'), 
    menuData:[   
      {
        _id:"3-1",
        menuIsLink: false,
        menuLabel:t('steps'), 
        menuIcon:'fa-list-ol', 
        menuIconColor:'text-amber-500',
        submenuData: [
          {_id:'3-1',menuLabel:t('successors'), menuLink:'/profiles', menuIcon:'fa-id-card', menuIconColor:'text-violet-500', isLocked:(isStep && isStep >= 1)?false:true, isStepMenu:true },
          {_id:'3-2',menuLabel:t('concerns'), menuLink:'/concern', menuIcon:'fa-shield-exclamation', menuIconColor:'text-pink-500', isLocked:(isStep && isStep >= 2)?false:true, isStepMenu:true },      
          {_id:'3-3',menuLabel:t('predictors'), menuLink:'/predictors', menuIcon:'fa-user-check', menuIconColor:'text-orange-500', isLocked:(isStep && isStep >= 3)?false:true, isStepMenu:true },
          {_id:'3-4',menuLabel:t('scorecard'), menuLink:'/scorecard', menuIcon:'fa-chart-line', menuIconColor:'text-cyan-500', isLocked:(isStep && isStep >= 4)?false:true, isStepMenu:true },
          // {_id:'3-5',menuLabel:t('summary'), menuLink:'/plan-summary', menuIcon:'fa-list-check', menuIconColor:'text-yellow-500', isLocked:(isStep && isStep >= 5)?false:true, isStepMenu:true },
          {_id:'3-6',menuLabel:t('development_plan'), menuLink:'/development-plan', menuIcon:'fa-presentation-screen', menuIconColor:'text-rose-500', isLocked:(isStep && isStep >= 5)?false:true, isStepMenu:true  },
          {_id:'3-7',menuLabel:t('readiness'), menuLink:'/advancement-readiness', menuIcon:'fa-wand-magic-sparkles', menuIconColor:'text-emerald-500', isLocked:(isStep && isStep >= 6)?false:true, isStepMenu:true },            
          {_id:'3-8',menuLabel:t('conclusion'), menuLink:'/conclusion', menuIcon:'fa-clipboard', menuIconColor:'text-blue-400', isLocked:(isStep && isStep >= 7)?false:true, isStepMenu:true },
        ],
        isCollapse : (path && path === 'dashboard')?false:true,
      } 
      // {_id:'3-9',menuLabel:'Successors Roster', menuLink:'/successors', menuIcon:'fa-user-crown', menuIconColor:'text-sky-500',isLocked:(isStep && isStep >= 9)?false:true, isStepMenu:true },
      // {_id:'3-10',menuLabel:'Instructions', menuLink:'/instructions', menuIcon:'fa-chalkboard', menuIconColor:'text-purple-500', isLocked:false, isStepMenu:false },
    ]},    
    {_id:'2', menuTitle:t('reports'), menuData:[
      {
        ...(isTrial !== 1) &&
        {_id:'2-1',menuLabel:t('policy_statement'), menuLink:'/policy', menuIcon:'fa-file-contract', menuIconColor:'text-amber-500', menuIsLink: true,isCollapse : true,},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'2-2',menuLabel:t('position_competencies'), menuLink:'/competencies-alt', menuIcon:'fa-circle-waveform-lines', menuIconColor:'text-purple-500' , menuIsLink: true,isCollapse : true,},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'2-3',menuLabel:t('profile_report'), menuLink:'/profile-reports', menuIcon:'fa-user-vneck', menuIconColor:'text-green-500' , menuIsLink: true,isCollapse : true,},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'2-4',menuLabel:t('scorecard_report'), menuLink:'/scorecard-reports', menuIcon:'fa-file-chart-column', menuIconColor:'text-cyan-500' , menuIsLink: true,isCollapse : true,},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'2-5',menuLabel:t('development_report'), menuLink:'/development-reports', menuIcon:'fa-presentation-screen', menuIconColor:'text-rose-500' , menuIsLink: true,isCollapse : true,},
      },      
      {
        ...(isTrial !== 1) &&
        {_id:'2-6',menuLabel:t('advance_readiness_report'), menuLink:'/readiness-reports', menuIcon:'fa-wand-magic-sparkles', menuIconColor:'text-emerald-500' , menuIsLink: true,isCollapse : true,},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'2-7',menuLabel:t('conclusions_report'), menuLink:'/conclusion-reports', menuIcon:'fa-clipboard', menuIconColor:'text-blue-400' , menuIsLink: true,isCollapse : true,},
      }    
    ], isHidden:(isTrial !== 1)?false:true},

    {_id:'7', menuTitle:t('company_overview'), menuData:[
      {
        ...(isTrial !== 1) &&
        {_id:'7-1',menuLabel:t('company_report'), menuLink:'/retirement-reports', menuIcon:'fa-building', menuIconColor:'text-pink-400' , menuIsLink: true,isCollapse : true,},
      }     
    ], isHidden:(isTrial !== 1 && role !== 'customer-subadmin')?false:true},

    {_id:'6', menuTitle:t('boardroom'), menuData:[  
      {
        ...(isTrial !== 1) &&
        {_id:'6-1',menuLabel:t('board_member'), menuLink:'/board-members', menuIcon:'fa-user-secret', menuIconColor:'text-emerald-500' , menuIsLink: true,isCollapse : true},
      },      
      // {
      //   ...((auth_user?.position_details && auth_user?.position_details?.short_name === 'CEO') && (isTrial !== 1)) &&
      //   {_id:'6-2',menuLabel:t('board_member_administrator'), menuLink:'/boardmember-administrator', menuIcon:'fa-user-secret', menuIconColor:'text-rose-500' , menuIsLink: true,isCollapse : true}
      // },
      // {
      //   ...((auth_user?.position_details && auth_user?.position_details?.short_name === 'CEO') && (isTrial !== 1)) &&
      //   {_id:'6-3',menuLabel:t('board_member_planning'), menuLink:'/board-member/planning', menuIcon:'fa-clipboard', menuIconColor:'text-blue-500' , menuIsLink: true,isCollapse : true}
      // },
      // {
      //   ...(isTrial !== 1) &&
      //   {_id:'6-4',menuLabel:t('board_member_readiness'), menuLink:'/board-member/readiness', menuIcon:'fa-wand-magic-sparkles', menuIconColor:'text-pink-500' , menuIsLink: true,isCollapse : true}
      // },
      {
        ...(isTrial !== 1) &&
        {_id:'6-5',menuLabel:t('board_strength'), menuLink:'/boardstrength', menuIcon:'fa-clipboard', menuIconColor:'text-sky-500' , menuIsLink: true,isCollapse : true}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-9',menuLabel:t('emergency_replacement_plan'), menuLink:'/emergency-replacement-plan', menuIcon:'fa-check-circle', menuIconColor:'text-emerald-500' ,menuIsLink: true,isCollapse : true,},
      }

    ],isHidden:((isTrial !== 1))?false:true},

    {_id:'8', menuTitle:t('executive_360'), menuData:[  
      {
        ...(isTrial !== 1) &&
        {_id:'6-1',menuLabel:t('executives'), menuLink:'/executives', menuIcon:'fa-user-secret', menuIconColor:'text-yellow-500' , menuIsLink: true,isCollapse : true},
      },
      {
        ...(isTrial !== 1) &&
        {_id:'6-5',menuLabel:t('executive_strength'), menuLink:'/executivestrength', menuIcon:'fa-clipboard', menuIconColor:'text-pink-500' , menuIsLink: true,isCollapse : true}
      }
    ],isHidden:((isTrial !== 1))?false:true},

    {_id:'4', menuTitle:t('company_settings'), menuData:[   
      {
        ...(isTrial !== 1) &&
        {_id:'4-2',menuLabel:t('company_users'), menuLink:'/users', menuIcon:'fa-users', menuIconColor:'text-sky-500' , menuIsLink: true,isCollapse : true,}
      },  
      {
        ...(isTrial !== 1) &&
        {_id:'4-10',menuLabel:t('company_employees'), menuLink:'/company-employees', menuIcon:'fa-users', menuIconColor:'text-emerald-500' ,menuIsLink: true,isCollapse : true}
      },  
      {
        ...(isTrial !== 1) &&
        {_id:'4-3',menuLabel:t('concern_settings'), menuLink:'/concern-settings', menuIcon:'fa-shield-exclamation', menuIconColor:'text-pink-500' , menuIsLink: true,isCollapse : true}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-4',menuLabel:t('predictors_settings'), menuLink:'/predictors-heading-alt', menuIcon:'fa-user-check', menuIconColor:'text-orange-500' , menuIsLink: true,isCollapse : true}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-5',menuLabel:t('scorecard_settings'), menuLink:'/scorecard-alt', menuIcon:'fa-chart-line', menuIconColor:'text-lime-500' , menuIsLink: true,isCollapse : true}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-6',menuLabel:t('performance_settings'), menuLink:'/performance-alt', menuIcon:'fa-ribbon', menuIconColor:'text-pink-500' , menuIsLink: true,isCollapse : true}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-7',menuLabel:t('positions'), menuLink:'/position-alt', menuIcon:'fa-user-crown', menuIconColor:'text-yellow-500' , menuIsLink: true,isCollapse : true,}
      },
      {
        ...(isTrial !== 1) &&
        {_id:'4-8',menuLabel:t('non_rated_employees'), menuLink:'/non-rated-employees', menuIcon:'fa-plus', menuIconColor:'text-sky-500' , menuIsLink: true,isCollapse : true,}
      }
    ],isHidden:(isTrial !== 1)?false:true},
    // {_id:'7', menuTitle:t('payment'), menuData:[
    //   {_id:'7-1',menuLabel:t('invoices'), menuLink:'/invoices', menuIcon:'fa-file-invoice', menuIconColor:'text-violet-500' , menuIsLink: true,isCollapse : true,}
    // ]},
    {_id:'5', menuTitle:'', menuData:[
      {
        ...(isTrial !== 1) &&
        {_id:'5-1',menuLabel:t('history'), menuLink:'/planhistory', menuIcon:'fa-rectangle-history', menuIconColor:'text-sky-500' , menuIsLink: true,isCollapse : true,}
      },      
      {_id:'5-2',menuLabel:t('faq'), menuLink:'/faq', menuIcon:'fa-question-circle', menuIconColor:'text-emerald-500',menuIsLink: true},
      {_id:'5-3',menuLabel:t('user_guide'), menuLink:'/manual', menuIcon:'fa-compass', menuIconColor:'text-violet-400', menuIsLink: true,isCollapse : true,},
      // {_id:'5-4',menuLabel:t('account_support'), menuLink:'/helps', menuIcon:'fa-question-circle', menuIconColor:'text-lime-500',menuIsLink: true},
    ]},
    
  ]
}else if( role && role === 'customer-user'){
  sidebarMenu = [     
    {_id:'1', menuTitle:t('succession_plan'), menuData:[    
      {_id:'1-1',menuLabel:t('successors'), menuLink:'/profiles', menuIcon:'fa-id-card', menuIconColor:'text-violet-400', isLocked:(isStep && isStep >= 1)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },
      {_id:'1-2',menuLabel:t('concerns'), menuLink:'/concern', menuIcon:'fa-shield-exclamation', menuIconColor:'text-pink-500', isLocked:(isStep && isStep >= 2)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },      
      {_id:'1-3',menuLabel:t('predictors'), menuLink:'/predictors', menuIcon:'fa-user-check', menuIconColor:'text-orange-500', isLocked:(isStep && isStep >= 3)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },
      {_id:'1-4',menuLabel:t('scorecard'), menuLink:'/scorecard', menuIcon:'fa-chart-line', menuIconColor:'text-cyan-500', isLocked:(isStep && isStep >= 4)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },
      // {_id:'1-5',menuLabel:t('summary'), menuLink:'/plan-summary', menuIcon:'fa-list-check', menuIconColor:'text-yellow-500', isLocked:(isStep && isStep >= 5)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },
      {_id:'1-6',menuLabel:t('development_plan'), menuLink:'/development-plan', menuIcon:'fa-presentation-screen', menuIconColor:'text-rose-500', isLocked:(isStep && isStep >= 5)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true,  },
      {_id:'1-7',menuLabel:t('readiness'), menuLink:'/advancement-readiness', menuIcon:'fa-wand-magic-sparkles', menuIconColor:'text-emerald-500', isLocked:(isStep && isStep >= 6)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },            
      {_id:'1-8',menuLabel:t('conclusion'), menuLink:'/conclusion', menuIcon:'fa-clipboard', menuIconColor:'text-blue-400', isLocked:(isStep && isStep >= 7)?false:true, isStepMenu:true,menuIsLink: true,isCollapse : true, },  
    ]}
  ]
}else if(role && role === 'customer-boardmember'){
  sidebarMenu = [
    {_id:'1', menuTitle:'', menuData:[
      {_id:'1-1',menuLabel:t('boardroom'), menuLink:'/boardroom', menuIcon:'fa-page', menuIconColor:'text-emerald-500' , menuIsLink: true}
    ]},
  ]
}else if(role && role === 'customer-executive'){
  sidebarMenu = [
    {_id:'1', menuTitle:'', menuData:[
      {_id:'1-1',menuLabel:t('manage_info'), menuLink:'/add-executive', menuIcon:'fa-page', menuIconColor:'text-emerald-500' , menuIsLink: true}
    ]},
  ]
}


//console.log(sidebarMenu,'sidebarMenu')

  return (
    <>
      <div className="relative w-full bg-slate-900 divide-y divide-slate-800">
        <div className={classNames(
          "relative flex items-center gap-4 h-16 transition-all duration-100", 
          width > 1024 ? (isMenuOpen ? "px-3" : "px-4"):"px-3"
        )}>
          <div className="w-full h-10 min-w-[40px]">
            <img className="w-full h-full object-contain" src={(isMenuOpen)?'/logo-succession-now-small.svg':'/logo-succession-now.svg'} alt={'Logo'} id={'sidebarLogo'}/>
          </div>
          {/* {!isMenuOpen && 
          <div className="w-full">
            <div className="text-lg text-white font-bold leading-tight">{'Succession Now'}</div>
          </div>
          } */}
        </div>
        <div className={classNames(
          "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100vh-4rem)] transition-all duration-100", 
          width > 1024 ? isMenuOpen ? "divide-y divide-slate-800" : "" :"divide-y divide-slate-800"
        )}>
          {sidebarMenu.map((item) => (
            <SidebarMenuBlocks 
            key={item._id} 
            menuTitle={item.menuTitle} 
            menuData={item.menuData.filter(element => {
              if (Object.keys(element).length !== 0) {
                return true;
              }
              return false;
            })} 
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isHidden={(item.isHidden)?item.isHidden:false}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DefaultSidebar;