import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import Textarea from "../../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";

import { allowedImageExtensions } from "../../helpers";
import { API_URL } from "../../config/host";
import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const ManageBenefit = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Benefits");
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [showLogo, setShowLogo] = useState("");
  //const [benefits,setBenefits] = useState('');
  const [subTitle, setSubTitle] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [id, setId] = useState("");
  const [benefit, setBenefits] = useState([''])

  useEffect(() => {
    // Banner details
    const loadDetails = async () => {
      try {
        const result = await postData("benefit/details", {});
        if (result.data) {
          setDetails(result.data);
          setBenefits(result.data.benefit || [''])
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  useEffect(() => {
    setName(details?.name);
    setSubTitle(details?.sub_title);
    setShowLogo(API_URL+details?.image);
    //setBenefits(details?.description);
    setId(details?._id);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setSubTitle(details?.sub_title);
      //setDescription(details?.description);
    } else {
      setName("");
      //setDescription('');
      setSubTitle("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("sub_title", subTitle);
    formData.append("benefit", benefit);
    formData.append("image", image);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "benefit/update";
        formData.append("id", id);
        payload = formData
      } else {
        path = "benefit/create";
        payload = formData
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/benefits");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };
  
  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0])
      }
    }
  }; 

  const addRow = async () => {
    setBenefits(benefit => [ ...benefit, '' ])
  }
  const removeRow = async (index) => {
    setBenefits(benifits => benifits.filter((item, i) => i !== index));
  }

  const handelUpdate = async (val,index) => {
    setBenefits(benifits => benifits.map((item, i) => i !== index ? item : val));
  }

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {id ? t('edit_benefit') : t('add_benefit')} 
        </div>
      </div>
      <form>
        <div className="relative grid grid-cols-6 gap-4">
          <div className="col-span-2 space-y-4">
            <div className="relative bg-white rounded shadow">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="w-full h-52 overflow-hidden rounded-md">
                  <Image
                    src={showLogo}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                    id={"image1"}
                  />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-image"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('upload_image')}
                  buttonClasses={
                    "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"
                  }
                  functions={onImgChange}
                  accepts={"image/*"}
                />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-8 px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Input
                        label={t('name')}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t('enter_name')}
                        inputValue={name}
                        setInput={setName}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t('sub_title')}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t('enter_sub_title')}
                        inputValue={subTitle}
                        setInput={setSubTitle}
                      />
                    </div>
                  </div>
                  { 
                    benefit.map((val, key) => { 
                      
                      return(
                  <div className="flex items-end gap-4" key={key}>
                    <div className="relative w-full">
                      <Input
                        label={(key===0)?t('benefit'):""}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t('enter_benefit')}
                        inputValue={val}
                        setInput={(val) => handelUpdate(val,key)}
                      />
                    </div>
                    {
                    (key===0)?
                    <Button
                      buttonIcon={"fa-light fa-plus"}
                      buttonIconPosition={"left"}
                      buttonClasses={"!h-10 !w-10 !min-w-[24px] !text-sm !p-0"}
                      buttonType={"button"}
                      functions={(e) => addRow()}
                      buttonHasLink={false}
                    />
                    :<Button
                      buttonIcon={"fa-light fa-minus"}
                      buttonIconPosition={"left"}
                      buttonClasses={"!h-10 !w-10 !min-w-[24px] !text-sm !p-0 !bg-rose-500"}
                      buttonType={"button"}
                      functions={(e) => removeRow(key)}
                      buttonHasLink={false}
                    />
                    }
                  </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={
                      isCancel
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-times"
                    }
                    buttonIconPosition={"left"}
                    buttonLabel={t('cancel')}
                    buttonClasses={
                      "!bg-slate-200 !text-slate-600 border border-slate-300"
                    }
                    functions={onCancel}
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                  />
                  <Button
                    buttonClasses=""
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={
                      isManage
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-check"
                    }
                    buttonIconPosition={"left"}
                    buttonLabel={t('save')}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isManage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ManageBenefit;
