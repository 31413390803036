import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import FrontFooter from "../shared/FrontFooter";
import FrontHeader from "../shared/FrontHeader";

import { postData } from '../services/api';

const FrontLayout = () => {
  const [settings, setSettings] = useState({});
    useEffect(() => {
      async function settingsData() {
        try {
          const result = await postData("setting/details", {});
          if (result.data) {
            setSettings(result.data);
          } else {
            console.log("settings list message", result.message);
          }
        } catch (error) {
          console.log("Error settings list catch", error.message);
        }
      }
      settingsData();
    }, []);

  return (
    <>
      <FrontHeader settings={settings}/>
      <Outlet />
      <FrontFooter settings={settings}/>
    </>
  );
};

export default FrontLayout;