import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Search from "../components/form/Search";
import Select from "../components/form/Select";
import Textarea from "../components/form/Textarea";
import Button from "../components/form/Button";
import {  usePDF, PDFDownloadLink } from '@react-pdf/renderer';
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import ReactPaginate from "react-paginate";
import toastr from "toastr";
import { NextStepMessage } from "../helpers";
import { useSelector } from "react-redux";
import Image from "../components/elements/Image";
import { API_URL } from '../config/host';
import { postData } from "../services/api";
import AdvanceReadinessReport from "./pdf/AdvanceReadinessReport";
import AlertPopup from "../components/popup/AlertPopup";
import StepAlert from "../components/elements/alert/StepAlert";

import { useTranslation } from "react-i18next";

const AdvancementReadiness = ({
  getIsChange = () => {},
}) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Advancement Readiness");
  const { i18n,t } = useTranslation();
  const role =  localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [timeFrame, setTimeFrame] = useState([]);
  const [competencyfits, setCompetencyfits] = useState([]);
  const [positionDetails,setPositionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10000);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isNextStep,setIsNextStep] = useState(false);
  const [isChange,setIsChage] = useState(false);

  const [predictorWeight,setPredictorWeight] =  useState(0);
  const [experienceWeight,setExperienceWeight] =  useState(0);
  const [concernsWeight,setConcernWeight] =  useState(0);
  const [performanceWeight,setPerformanceWeight] =  useState(0);
  const [educationWeight,setEducationWeight] =  useState(0);
  const [totalWeight,setTotalWeight] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [message,setMessage] = useState('');
  const [fileldData,setFileldData] = useState({});

  const heading = [
    {
      name: t('readiness_timeframe'),
      option: "",
    },
    {
      name: t('competency_fit'),
      option: "",
    },
    {
      name: t('cultural_fit'),
      option: "",
    },
    {
      name: t('additional_comments'),
      option: "",
    },
    {
      name : t('eliminated_candidate_due_to'),
      option: ''
    }
  ];

  const document = <AdvanceReadinessReport user={user} successors={successors} heading={heading} />;
  const [instance, updateInstance] = usePDF({ document });

  useEffect(() => {
    async function timeFrameData() {
      try {
        const result = await postData("time-frame/list", {
          sortQuery: { created_at: 1 },
          type: 1,
          status: 1,
          type : 1
        });
        if (result.data) {
          setTimeFrame(result.data);
        } else {
          console.log("Time Frame list message", result.message);
        }
      } catch (error) {
        console.log("Error time frame list catch", error.message);
      }
    }
    timeFrameData();

    async function competencyFitData() {
      try {
        const result = await postData("competency-fit/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
        });
        if (result.data) {
          setCompetencyfits(result.data);
        } else {
          console.log("Competency fit list message", result.message);
        }
      } catch (error) {
        console.log("Error competency fit list catch", error.message);
      }
    }
    competencyFitData();

    async function positionDetails() {
      try {
        const result = await postData("competency/details", {
          id: user.designation,
        });
        if (result.data) {
          setPositionDetails(result.data);
        } else {
          console.log("Competency details message", result.message);
        }
      } catch (error) {
        console.log("Error competency details catch", error.message);
      }
    }
    positionDetails();

    const loaWeights = async () => {
      setIsUpdate(false);
      try {
        const result = await postData("scorecard/list",{
          status:1,
        });
        if(result.data) {
          let total = 0;
          result.data.map((value, key) => {
            if(value.alias  === 'potential_predictor_score'){
              setPredictorWeight((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            if(value.alias  === 'executive_experience'){
              setExperienceWeight((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            if(value.alias  === 'concerns'){
              setConcernWeight((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            if(value.alias  === 'recent_performance_review'){
              setPerformanceWeight((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            if(value.alias  === 'highest_level_education'){
              setEducationWeight((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            setTotalWeight(total);
            setIsUpdate(true);
          })
        }
        setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
        setIsUpdate(false);
      }
    };
    loaWeights();
  }, []);

  useEffect(() => {
    // Advancement List
    const loadAdvancement = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery:{created_at:1},
          is_interim:2,
        });
        if (result.data) {
          setSuccessors(
            result.data.map((value, key) => { 
              let predictorCalculation = (predictorWeight*value?.overall_weighted_score) / 100;              
              let experienceCalculation = (experienceWeight*(value.experience_details?value?.experience_details[0]?.point:0)/ 100);     
              let concernsCalculation = (concernsWeight*value?.overall_score) / 100;              
              let performanceCalculation = (performanceWeight*(value.performance_details?value?.performance_details[0]?.point:0) / 100);
              let educationCalculation = (educationWeight*(value.education_details?value?.education_details[0]?.point:0) / 100);
              let desireResult = (value?.desire_details && value?.desire_details.length > 0)?(value?.desire_details[0]?.isPass)?1:2:null;
              let loyaltyResult = (value?.loyalty_details && value?.loyalty_details.length > 0)?(value?.loyalty_details[0]?.isPass)?1:2:null;
              let RetirementResult = (value?.retirement_details && value?.retirement_details.length > 0)?
              (value?.retirement_details[0]?.isPass)?1:2:null;
              return {
                ...value,
                overallWeightScore: (desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2)?0.00: 
                (                   
                  parseFloat(predictorCalculation) +
                  parseFloat(experienceCalculation) +
                  parseFloat(concernsCalculation) +
                  parseFloat(performanceCalculation) +
                  parseFloat(educationCalculation)
                ).toFixed(2),
                desireResult:desireResult,
                loyaltyResult: loyaltyResult,
                RetirementResult : RetirementResult
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadAdvancement();
  }, [keyword, limit, offset, timeFrame, competencyfits,isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const handelUpdate = async (id, val, field, index,oldValue='') => {
    try {
      setIsChage(false);
      getIsChange(false);
      if(field === 'time_frame'){
        const index1 = timeFrame.findIndex((item) => item._id === val);
        const index2 = successors.findIndex((item) => item._id === id);
        if(index1 !== -1){
          if(timeFrame[index1].isConfirm && successors[index2].overallWeightScore < 4){
            setFileldData({
              id:id,
              val : val,
              field : field
            })
            setEnabledAlertPopup(true);
            setMessage(timeFrame[index1].confirmMessage);
            return;
          }
        }
      }
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return { ...it, [field]: val };
          } else {
            return it;
          }
        })
      );
      const result = await postData("successor/update", {
        id: id,
        [field]: val !== "" ? val : null,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        await postData("download-succession-plan/close-approval", {});
        setIsChage(true);
        getIsChange(true);
      } else {
        toastr.error(result.message);
        setIsChage(true);
        getIsChange(true);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsChage(true);
      getIsChange(true);
    }
  };

  const handelSetText = async (id, val, field, index) => {
    setSuccessors(
      successors.map((it, idx) => {
        if (idx === index) {
          return { ...it, [field]: val };
        } else {
          return it;
        }
      })
    );
  }
  const handelUpdateText = async (id, val, field, index) => {
    try {
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return { ...it, [field]: val };
          } else {
            return it;
          }
        })
      );
      const result = await postData("successor/update", {
        id: id,
        [field]: val,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        await postData("download-succession-plan/close-approval", {});
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  useEffect(() => { 
    if(successors && successors.length>0){
      let competency_fit = successors.filter((item) => !item.competency_fit);
      let time_frame = successors.filter((item) => !item.time_frame);    
      if(competency_fit.length === 0 && time_frame.length ===0){
        setIsNextStep(true);
      }else{
        setIsNextStep(false);
      }
    }
    // updateInstance()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChange,successors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateInstance, [document]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      const updateRecord = async () => {
        setIsUpdate(false)
        try {
            const result = await postData("successor/update", {
              id: actionValue?.data?.id,
              [actionValue?.data?.field]: actionValue?.data?.val !== "" ? actionValue?.data?.val : null,
            });
            if (result.status && result.status === 200) {
              toastr.success(result.message);
              setEnabledAlertPopup(false);
              setMessage(''); 
              setFileldData({});
            } else {
              toastr.error(result.message);
              setEnabledAlertPopup(false);
              setMessage(''); 
              setFileldData({})
            }
            setIsUpdate(true)
        } catch (error) {
            toastr.error(error.message);
        }
      };
      updateRecord(); 
    }
  }, [actionValue]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href,'_blank');
    }
  }
  
  return (
    <>      
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('position_competency_for')} {user?.title}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
        {/* <button 
            type={'button'} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
            onClick={(e) => getDownload(e)}
            >
            <i className={"fa-fw fa-light fa-download"}></i>  
            <div className={"text-sm font-medium whitespace-nowrap"}>
            <a
              className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
              href={instance.url} download="readiness.pdf">
                {instance.loading ? 'Loading document...' : 'Download PDF'}
            </a>
            </div>
          </button> */}
          {/* <button 
            type={'button'} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
            onClick={(e) => getDownload(e)}
            >
            <i className={"fa-fw fa-light fa-download"}></i>  
            <div className={"text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<AdvanceReadinessReport user={user} successors={successors} heading={heading} />} fileName="profile_report.pdf">
                {({ blob, url, loading, error }) => (
                  loading ? 'Loading document...' : 'Download PDF')}
              </PDFDownloadLink>
            </div>
          </button> */}
          {
            isNextStep? 
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={true}
              buttonLink={"/conclusion"}
            />:
            <Button
              buttonClasses={'!bg-rose-500'}
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={false}
              functions={() => toastr.error(NextStepMessage)}
            />
          }
        </div>
      </div>
      <StepAlert/>
      {
          (role && (role === 'customer-admin' || role === 'customer-user')) &&  
          <div className="relative flex justify-between items-center">
          <div className="text-sm text-slate-800 whitespace-pre-line" dangerouslySetInnerHTML={{__html:(positionDetails.company_position_competencies)?positionDetails.company_position_competencies:positionDetails.position_competencies}}></div>
          </div>
        }
      <div className="relative flex justify-between items-center mb-4">
        {/* <div className="relative">
          <Select
            dropdownButtonClass={"!bg-white"}
            xPlacement={"left"}
            dropdownData={[
              { _id: 1000000, label: "All Items", value: 1000000 },
              ...[
                { _id: 10, label: "10 Items", value: 10 },
                { _id: 20, label: "20 Items", value: 20 },
                { _id: 30, label: "30 Items", value: 30 },
                { _id: 50, label: "50 Items", value: 50 },
                { _id: 100, label: "100 Items", value: 100 },
              ],
            ]}
            selectedValue={limit}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div> */}
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
          <div className="relative">
            <Search placeholder={"Search"} setInput={setKeyword} />
          </div>
        </div> */}
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md"></th>
              {totalDataCount > 0
                ? successors.map((item, index) => {
                    return (
                      <th className="py-4 px-2 max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">
                        <div className="flex justify-center gap-1">
                          <div className="mx-auto flex justify-center gap-4 items-center">
                            <div className="w-8 h-8 min-w-[32px] min-h-[32px] overflow-hidden rounded-full relative">
                              <Image
                                src={API_URL+item?.image}
                                alt={"Admin"}
                                width={"100%"}
                                height={"100%"}
                                effect={"blur"}
                                classes={"object-cover cursor-pointer"}
                              />
                            </div>
                            <div className="font-semibold text-sm leading-none text-center">
                              {item.name}
                              <span className="block text-[11px] font-medium mt-1">
                                {item?.position_details
                                  ? item?.position_details[0]?.short_name
                                  : "N/A"}
                              </span>
                              <span className="block text-[11px] font-medium mt-1">
                                {item?.overallWeightScore
                                  ? '('+item?.overallWeightScore+')'
                                  : "(0)"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </th>
                    );
                  })
                : ""}
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {heading.map((items, index) => (
              <tr className="border-b border-slate-200" key={index}>
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                  {items.name}
                  <span className="block text-xs font-normal leading-tight text-slate-500 my-1">
                    {items.option}
                  </span>
                </td>
                {totalDataCount > 0 ? (
                  successors.map((item, index1) => {
                    if (index === 0) {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          <Select
                            xPlacement={"left"}
                            selectedValue={item?.time_frame}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: "", label: t('select'), value: "" },
                              ...timeFrame.map((frame_item) => ({
                                _id: frame_item?._id,
                                label: frame_item?.name,
                                value: frame_item?._id,
                              })),
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) =>
                              handelUpdate(
                                item._id,
                                e._id,
                                "time_frame",
                                index1,
                                item?.time_frame
                              )
                            }
                          />
                        </td>
                      );
                    } else if (index === 1) {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          <Select
                            xPlacement={"left"}
                            selectedValue={item?.competency_fit}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t('select'), value: "" },
                              ...competencyfits.map((fit_item) => ({
                                _id: fit_item?._id,
                                label: fit_item?.name,
                                value: fit_item?._id,
                              })),
                            ]}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) =>
                              handelUpdate(
                                item._id,
                                e._id,
                                "competency_fit",
                                index1
                              )
                            }
                          />
                        </td>
                      );
                    } else if (index === 2) {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          <Select
                            xPlacement={"left"}
                            selectedValue={item?.cultural_fit}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t('select'), value: "" },
                              ...competencyfits.map((fit_item) => ({
                                _id: fit_item?._id,
                                label: fit_item?.name,
                                value: fit_item?._id,
                              })),
                            ]}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) =>
                              handelUpdate(
                                item._id,
                                e._id,
                                "cultural_fit",
                                index1
                              )
                            }
                          />
                        </td>
                      );
                    } else if (index === 3) {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          <Textarea
                            inputClasses={"!h-32"}
                            inputValue={item?.advancement_additional_comments}
                            setTextarea={(val) =>
                              handelSetText(
                                item._id,
                                val,
                                "advancement_additional_comments",
                                index1
                              )
                            }
                            onBlur={(val) =>
                              handelUpdateText(
                                item._id,
                                val,
                                "advancement_additional_comments",
                                index1
                              )
                            }
                          />
                        </td>
                      );
                    } else if(index === 4) {
                      return(
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          {                          
                            (item.desireResult && item.desireResult === 2) &&
                              <div className="block text-xs font-semibold leading-tight text-rose-500 my-1">{item?.desire_details[0]?.name}</div>
                          }
                          {
                            (item.loyaltyResult && item.loyaltyResult === 2) &&
                              <div className="block text-xs font-semibold leading-tight text-rose-500 my-1">{item?.loyalty_details[0]?.name}</div>
                          }
                          {
                            (item.RetirementResult && item.RetirementResult === 2) &&
                              <div className="block text-xs font-semibold leading-tight text-rose-500 my-1">{item?.retirement_details[0]?.name}</div>
                          }
                        </td>
                      )
                    } else {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded"></div>
                        </td>
                      );
                    }
                  })
                ) : ''}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="relative flex justify-between items-center">
        <div className="ml-auto flex justify-end items-center space-x-2">
          {
            isNextStep && 
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t('next_step')}
              buttonHasLink={true}
              buttonLink={"/conclusion"}
            />
          }     
        </div>
      </div>
      {/* {noOfPage > 1 && (
        <div className="flex justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )} */}
      {enabledAlertPopup && (
        <AlertPopup
          isOpen={enabledAlertPopup}
          setIsOpen={(val) => setEnabledAlertPopup(val)}
          data={fileldData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          message={message}
        ></AlertPopup>
      )}
    </>
  );
};

export default AdvancementReadiness;
