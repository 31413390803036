import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const StepAlert = () => {
  const user = useSelector((state) => state.auth?.user);
  const { i18n,t } = useTranslation();
  return (
    <div className="bg-teal-100 border-l-4 border-teal-500 text-teal-700 p-2 mb-1" role="alert">
      <strong className="font-bold">{user?.name}</strong>&nbsp;
      <strong className="font-bold">{(user?.title)?'- '+user?.title+' -':''}</strong>&nbsp;
      <span className="block sm:inline">{t('retirement_window')}</span>&nbsp;
      <strong className="font-bold">{(user?.retirement)?user?.retirement.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</strong>
    </div>
  );
};

export default StepAlert;