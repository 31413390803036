import React,{useState,useEffect} from "react";
import {setTitle} from '../helpers/MetaTag';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,ContentState,convertFromHTML,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../components/form/Button";
import toastr from "toastr";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const ManageInstruction = () => {
    setTitle('Succession Now | Instructions');
    const { i18n,t } = useTranslation();   
    const [ instructionData,setInstructionData ] = useState({});
    const [isUpdate,setIsUpdate] = useState(false);    
    const [id,setId] = useState('');
    const [description,setDescription] = useState(EditorState.createEmpty());  

    const onEditorStateChange = (editorState) => {
        setDescription(editorState)
    }

    useEffect(() => {
        async function loadInstructionData() {
            try {
                const result = await postData("instruction/details", {
                    alias:'instruction'
                });
                if (result.data) {
                  setInstructionData(result.data);
                } else {
                  console.log('Instruction Details message',result.message)
                }
            } catch (error) {
            console.log('Error instruction details catch',error.message)
            }
        }
        loadInstructionData();
    }, []); 

    useEffect(() => { 
        if(instructionData.description){
            setDescription(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(instructionData?.description)
                )
              ));
        }
        setId(instructionData?._id)       
    }, [instructionData]); 

    const onUpdate = async (e) => {
        setIsUpdate(true);
        const rawContentState = convertToRaw(description.getCurrentContent());
        const html = draftToHtml(rawContentState);
        try {
            let path ='';
            let payload={};
            if(id){
                path = 'instruction/update';
                payload = {
                    id: id,                    
                    description : html
                }
            }else{
                path = 'instruction/create';
                payload = {
                    name : 'Instruction',
                    alias : 'instruction',
                    description : html
                }
            }
            const result = await postData(path, payload);
            if (result.status && result.status === 200) {        
                toastr.success(result.message);
                setIsUpdate(false);
            } else {
                toastr.error(result.message);
                setIsUpdate(false);
            }
        } catch (error) {
            toastr.error(error.message);
            setIsUpdate(false);
        }
    }

    

    return (
        <>
        <div className="relative flex justify-between items-center mb-4">
            <div className="text-3xl text-slate-800 font-bold">{t('instructions')}</div>
        </div>
        <div className="relative bg-white shadow rounded">
            <div className="py-8 px-4">                
                <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}                
                />
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                buttonClasses=''
                buttonLabelClasses='' 
                buttonType={'button'} 
                buttonIcon={(isUpdate)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                buttonIconPosition={'left'} 
                buttonLabel={t('save')}
                functions={onUpdate}
                buttonHasLink={false}
                buttonDisabled={isUpdate}
                />
              </div>
            </div>
        </div>
        </>
    )
}
export default ManageInstruction;