import React from 'react';

const BannerSkeleton = () => {
  return (
    <>
      <div className="w-full h-screen overflow-hidden flex items-center pt-20 z-0">
        <div className="w-full h-full overflow-hidden absolute top-0 left-0 -z-[1] bg-black">
          <div className="w-full h-full opacity-50"></div>
        </div>
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative z-10 w-full max-w-[720px]">
            <h4 className="sub-title text-3xl text-white font-extralight animated m-0"></h4>
            <h1 className="title block text-3xl xl:text-7xl text-white font-extrabold animated border-y-2 border-teal-300 py-4 my-4"></h1>
            <div className="text text-2xl text-white font-thin mt-6 max-w-xl animated"></div>
            <div className="flex mt-10"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSkeleton;