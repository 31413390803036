import React,{useState,useEffect} from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from '../../components/form/Search';
import Table from '../../components/elements/table/Table';
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder,currencyFormat } from "../../helpers";
import toastr from 'toastr';
import CompanyDetailsPopup from "../../components/popup/CompanyDetailsPopup";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const Coupons = ({}) => {
    setTitle("Succession Planning Software for your Business with Succession Now | Promo codes");
    const { i18n,t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [keyword,setKeyword] = useState('');
    const [limit,setLimit] =  useState(10);
    const [offset, setOffset] = useState(0);
    const [sortKey,setSortKey]= useState('created_at');
    const [sortValue,setSortValue] = useState(-1);
    const [sortQuery,setSortQuery] = useState('');
    const [status,setStatus] = useState('');
    const [noOfPage, setNoOfPage] = useState(0);
    const [coupons,setCoupons] = useState([]);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [actionValue,setActionValue] = useState({});
    const [isUpdate,setIsUpdate] = useState(false);
    const [tempData,setTempData] = useState([]);

    const othersAction = {
      type : 'button',
      link : '/',
      label : t('company_info'),
      icon : 'fa-building'
    }
    const [enabledCompanyDetailsPopup,setEnabledCompanyDetailsPopup] = useState(false);
    const [companyData, setCompanyData] = useState({});

    useEffect(() => {
        // Coupon List
        const loadPlans = async () => {
          setLoading(true);
          try {
            const result = await postData("coupon/list",{
              keyword: keyword,
              limit: limit,
              offset: offset,
              sortQuery:sortQuery,
              status:status
            });
            if(result.data) {
                setTempData(result.data);
                  setCoupons(
                    result.data.map((value, key) => {
                      return {
                        _id: value?._id,
                        rowData:[
                            { _id:1, width:40, type:'text', data:value?.name },
                            { _id:2, width:15, type:'text', data:currencyFormat(value?.price) },
                            { _id:3, width:15, type:'status', statusLabel:(value?.isPaid === 1)?t('paid'):t('not_paid'),statusType:(value?.isPaid && value?.isPaid === 1)?'success':'danger' },
                            { _id:4, width:20, type:'text', data:(value && value?.created_at)?moment(new Date(value?.created_at)).format('Do MMM YYYY'):'N/A'},
                            { _id:5, width:15, type:'status', statusLabel:(value?.status && value?.status === 1)?t('active'):t('deactivated'), statusType:(value?.status && value?.status === 1)?'success':'warning'},
                            { _id:6, width:10, type:'action',statusLabel:(value?.status && value?.status === 1)?t('active'):t('deactivated'), statusType:(value?.status && value?.status === 1)?'success':'warning',data:(value?.company_details && value?.company_details.length>0)?1:2 }

                        ]
                      }
                    })
                  )
            }
            setTotalDataCount(result.count);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            console.log(err.message);
          }
        };
        loadPlans();
    }, [keyword, limit, offset,sortQuery,status,isUpdate]);

    useEffect(() => {
        setNoOfPage(Math.ceil(totalDataCount / limit));
    }, [limit, totalDataCount]);
    
    const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    };

    const tableHeadData = [
        {_id:1, width:40, name: t('name'), value:'name', align:'left', isSort:true, isFilter:false},
        {_id:2, width:15, name: t('price'), value:'price', align:'left', isSort:true, isFilter:false},
        {_id:2, width:15, name: t('paid')+'/'+t('unpaid'), value:'isPaid', align:'left', isSort:true, isFilter:false},
        {_id:3, width:20, name: t('created_date'),value:'created_at', align:'left', isSort:false, isFilter:false},
        {_id:4, width:15, name: t('status'), value:'status', align:'left', isSort:false, isFilter:false},
        {_id:5, width:10, name: t('action'),value:'', align:'right', isSort:false, isFilter:false},
    ]

    const handleOnDragEnd = async (result) => {
      const items = reorder(
        coupons,
        result.source.index,
        result.destination.index
      );  
      setCoupons(items);
      
      const res = await postData("position/ordering", { 
        items : items,  
      });
      if (res.status && res.status === 200) {        
        toastr.success(res.message);
      } else {
        toastr.error(res.message);
      }
    }

    useEffect(() => {
      if(actionValue && actionValue.button_type === 'status'){
        const loadStatusChange = async () => {
          setIsUpdate(false)
          try {
            const statusData = await postData("coupon/status-change", {
              id:actionValue?.row_id,
              status:actionValue?.current_status,
            });
            if (statusData.data){
              setCoupons(
                coupons.map((it) => { 
                  if(it?._id === statusData.data?._id){
                    return {
                      ...it, 
                      status:statusData.data?.status,
                    }
                  } else {
                    return it;
                  }
                })
              )
              setIsUpdate(true)
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
              toastr.error(error.message);
          }
        };
        loadStatusChange();
      }else if(actionValue && actionValue.button_type === 'delete'){
        const loadDeleteRecord = async () => {
          setIsUpdate(false)
          try {
            const statusData = await postData("coupon/delete", {
              id:actionValue?.row_id,
            });
            if (statusData.data){
              const index = coupons.findIndex((item) => item._id === actionValue?.row_id);
              if(index !== -1){
                coupons.splice(index, 1);
                setCoupons(
                  coupons.map((it) => { 
                    return it;
                  })
                )
              }
              setIsUpdate(true)
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
              toastr.error(error.message);
          }
        };
        loadDeleteRecord();
      }else if(actionValue && actionValue.button_type === 'others'){
        setEnabledCompanyDetailsPopup(true);
        const index = tempData.findIndex((item) => item._id === actionValue?.row_id);
        if(index !== -1){
          setCompanyData(tempData[index]?.company_details)
        }
      }
     
  }, [actionValue]);  

    return(
        <>
        <div className="relative flex justify-between items-center mb-4">
            <div className="text-3xl text-slate-800 font-bold">{t('activation_codes')}</div>   
            <div className="">
              <Button 
              buttonType={'button'} 
              buttonIcon={'fa-light fa-plus'} 
              buttonIconPosition={'left'} 
              buttonLabel={t('add_activation_code')} 
              buttonHasLink={true}
              buttonLink={'/add-coupon'}
              />
          </div>  
        </div>
        <div className="relative flex justify-between items-center mb-4">
        <div className="relative">
            <Select 
            xPlacement={'left'} 
            dropdownButtonClass={'!bg-white'} 
            selectedValue={limit} 
            dropdownData={[
                { _id: 1000000, label: t('all_items'),value: 1000000 }, 
                ...[{ _id: 10, label: '10 '+t('items'), value: 10 },
                    { _id: 20, label: '20 '+t('items'), value: 20 },
                    { _id: 30, label: '30 '+t('items'), value: 30 },
                    { _id: 50, label: '50 '+t('items'), value: 50 },
                    { _id: 100, label: '100 '+t('items'), value: 100 }]
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
            />
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
            <div className="relative">
            <Search 
            placeholder={t('search')} 
            setInput={ setKeyword } 
            />
            </div>
            <div className="relative">
            <Select 
            xPlacement={'right'} 
            dropdownButtonClass={'!bg-white'} 
            transitionClass={'!w-auto'}
            selectedValue={status} 
            dropdownData={[
              { _id: '', label: t('all_status') },
              { _id: 1, label: t('active'), value: 1 },
              { _id: 2, label: t('deactivated'), value: 2 },
            ]}
            getSelectedValue={(e) => setStatus(e.value)}
            />
            </div>
        </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
            <Table 
            tableData={coupons} 
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={'/edit-coupon'}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            isDraggable={false}
            getActionValue={(obj)=> setActionValue(obj)}
            othersAction = {othersAction}
            />
        </div>
        </DragDropContext>
        {
          noOfPage > 1 && (
            <div className="flex justify-center sm:justify-end my-4">
            <ReactPaginate 
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" 
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick} 
            pageRangeDisplayed={3} 
            pageCount={noOfPage} 
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
            />
            </div>
          )
        }
        {enabledCompanyDetailsPopup && (
          <CompanyDetailsPopup
            isOpen={enabledCompanyDetailsPopup}
            setIsOpen={(val) => setEnabledCompanyDetailsPopup(val)}
            data={companyData}
            getActionValue={(obj) => {
              setActionValue(obj);
            }}
            type={'predictors'}
          ></CompanyDetailsPopup>
        )}
        </>
    )
}
export default Coupons;