import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import Button from "../components/form/Button";
import toastr from 'toastr';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { postData } from "../services/api";
import { useSelector } from "react-redux";
import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import { useTranslation } from "react-i18next";

const ManageBoardMamberAdministrator = () => {
    const { id } = useParams();
    if(id){
      setTitle("Succession Planning Software for your Business with Succession Now | Edit Board Member Administrator")
    }else{
      setTitle("Succession Planning Software for your Business with Succession Now | Add Board Member Administrator")
    }     
    const { i18n,t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth?.user);
    const [position,setPosition] = useState([]);
    const [details,setDetails] = useState({});
    const [isAdd,setIsAdd] = useState(false);
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [designation,setDesignation] = useState('');
    const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] = useState(false);
    const [actionValue, setActionValue] = useState({}); 

    useEffect(() => {
      // Position details
      const loadDetails = async () => {
        try {
          const result = await postData("assign/details",{
            id: id
          });
          if(result.data) {
              setDetails(result.data)
          }
        } catch (err) {
          console.log(err.message);
        }
      };
      loadDetails();
  }, [id]);

  useEffect(() => {      
      setName(details?.name);
      setEmail(details?.email); 
      if(details.designation){
        setDesignation(details.designation);        
      }else{
        setDesignation(user?.designation);
      }
  }, [details]);

    useEffect(() => {
      async function positionData() {
          try {
          const result = await postData("position/list", {
              sortQuery:{'created_at':1},
              status:1
          });
          if (result.data) {
              setPosition(result.data);
          } else {
              console.log('Position list message',result.message)
          }
          } catch (error) {
          console.log('Error position list catch',error.message)
          }
      }
      positionData();

      // async function retirementWindowData() {
      //   try {
      //     const result = await postData("experience/list", {
      //       sortQuery:{'created_at':1},
      //       type:3,
      //       status:1,
      //       is_visabled:1,
      //     });
      //     if (result.data) {
      //       setRetirementWindow(result.data);
      //     } else {
      //       console.log('Retirement window list message',result.message)
      //     }
      //   } catch (error) {
      //     console.log('Error retirement window list catch',error.message)
      //   }
      // }
      // retirementWindowData();
    }, []); 

    const onSubmit = async (e) => {
        setIsAdd(true);
        try {
          let payload;
          let path;
          if(id){
            path = 'assign/update';
            payload = {
              id: id,
              name : name,
              email:email,
              designation:designation,
              isInvitedBoardMember:1
            }
          }else{
            path = 'assign/create';
            payload = {
              name : name,
              email:email,
              designation:designation,
              isInvitedBoardMember:1
            }
          }
            const result = await postData(path,payload );
            if (result.status && result.status === 200) {        
                toastr.success(result.message);
                setIsAdd(false);
                setName('')
                setEmail('')
                setDesignation('')
                return navigate("/boardmember-administrator");
            } else {
                toastr.error(result.message);
                setIsAdd(false);
            }
        } catch (error) {
            toastr.error(error.message);
            setIsAdd(false);
        }
    }

    useEffect(()=>{
      if(actionValue){
        setPosition([...position,actionValue])
        setDesignation(actionValue?._id)
      }
    },[actionValue])

    return(
        <>
        <div className="relative d-flex justify-between items-center mb-4">
          <div className="text-3xl text-slate-800 font-bold">{ (id)?t('edit_board_member_administrator'):t('add_board_member_administrator')} </div>
        </div>
        <div className="relative grid grid-cols-2 gap-4">           
          <div className="col-span-2">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-8 px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Input 
                      label={t('full_name')} 
                      labelClasses={'!text-xs'} 
                      inputType={'text'}                   
                      inputPlaceholder={t('enter_full_name')}
                      inputValue={name}  
                      setInput={setName} 
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                      label={t('work_email')}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={t('work_email_address')}
                      inputValue={email}
                      setInput={setEmail}
                      isDisabled={(id)?true:false}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select 
                        label={t('position')}
                        labelClasses={'!text-xs'}
                        xPlacement={'left'} 
                        selectedValue={designation} 
                        dropdownClass={'!w-60'} 
                        dropdownData={
                            [
                              { _id: ' ', label: t('select_position'),value:''} ,
                              ...position.map((item) => (
                                  { _id: item?._id, label: item?.name, value: item?._id }
                              )),
                              { _id: "others", label: t('other'), value: "others" },
                            ].filter((itm) => itm)                             
                        }
                        getSelectedValue={(e) => {
                          if(e._id === 'others'){
                            setEnabledOtherPositionPopup(true);
                          }
                          setDesignation(e._id)
                        }}
                      />
                    </div>
                  </div>                             
                </div>
              </div>
              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">                   
                  <Button
                  buttonClasses=''
                  buttonLabelClasses='' 
                  buttonType={'button'} 
                  buttonIcon={(isAdd)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                  buttonIconPosition={'left'} 
                  buttonLabel={(id)?t('update'):t('assign')}
                  functions={onSubmit}
                  buttonHasLink={false}
                  buttonDisabled={isAdd}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {enabledOtherPositionPopup && (
          <OtherPositionPopup
            isOpen={enabledOtherPositionPopup}
            setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
            getActionValue={(obj) => {
              setActionValue(obj);
            }}
          ></OtherPositionPopup>
        )}        
        </>
    )
}
export default ManageBoardMamberAdministrator;