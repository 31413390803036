import React,{useEffect,useState} from 'react';
import HomeBanner from '../components/section/Home/v2/HomeBanner';
import SuccessPlanSection from "../components/section/Home/v2/SuccessPlanSection";
import LeadershipSection from "../components/section/Home/v2/LeadershipSection";
import BuildSuccession from "../components/section/Home/v2/BuildSuccession";
import ClientTestimonials from "../components/section/Home/v2/ClientTestimonials";
import PricingPlanSection from "../components/section/Home/v2/PricingPlanSection";
import BusinessFutureSection from "../components/section/Home/v2/BusinessFutureSection";
import FaqSection from "../components/section/Home/v2/FaqSection";
import SecureBusinessSection from "../components/section/Home/v2/SecureBusinessSection";
import SuccessionTabSection from "../components/section/Home/v2/SuccessionTabSection";
import CompanySection from '../components/section/Home/v2/CompanySection';

import { postData } from '../services/api';
import { useTranslation } from "react-i18next";
import IntegratedToolSection from "../components/section/Home/v2/IntegratedToolSection";
import SuccessionWebinars from "../components/section/Home/v2/SuccessionWebinars";
import FeaturedSection from "../components/section/Home/v2/FeaturedSection";

const HomeV2 = () => {
  const [settings, setSettings] = useState({});
  const { i18n,t } = useTranslation();

  useEffect(() => {
    async function settingsData() {
      try {
        const result = await postData("setting/details", {});
        if (result.data) {
          setSettings(result.data);
        } else {
          console.log("settings list message", result.message);
        }
      } catch (error) {
        console.log("Error settings list catch", error.message);
      }
    }
    settingsData();
  }, []);

  return (
    <>
        <HomeBanner/>
        <CompanySection/>
        {/* <SuccessPlanSection/> */}
        <LeadershipSection
          title={settings?.leadership_title?.[i18n?.language]}
          description={settings?.leadership_description?.[i18n?.language]}
        />
        <SuccessionTabSection/>
        <IntegratedToolSection/>
        <SuccessionWebinars/>
        <ClientTestimonials/>        
        {/* <BuildSuccession/> */}
        <SecureBusinessSection description={settings?.future_business_description?.[i18n?.language]}/>
        
        <PricingPlanSection/>
        <FeaturedSection/>
        <BusinessFutureSection 
          title={settings?.second_thoughts_title?.[i18n?.language]}
          description={settings?.second_thoughts_description?.[i18n?.language]}
        />
        
        <FaqSection/>
    </>
  );
};

export default HomeV2;