import React, { useEffect } from 'react';
import Tabs from "../../../elements/Tab";
import Generate from "../../../TabContent/Generate";
import Measure from "../../../TabContent/Measure";
import Plan from "../../../TabContent/Plan";

const SuccessionTabSection= () => {
  
  const SuccessionTabData = {
    title:"How to Build Your Succession Plan",
    subtitle:"Second thoughts? Don’t worry!",
    description:"<p>Follow a Simple 3-Step Process to Create Your Succession Plan</p>",
   
  }

  const tabData = [
    { _id: 1, name: "Generate", component: <Generate/>},
    { _id: 2, name: "Measure", component: <Measure/>},
    { _id: 3, name: "Plan", component: <Plan/>},
  ]
  return (
    <section className="py-10 lg:py-14 xl:py-16 2xl:py-20 relative  w-full" id="successionbuild-section">
        <div className="w-full max-w-[90%] xs:max-w-lg lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto bg-black rounded-xl px-6 lg:px-12 py-5 lg:py-10">
            <div className=" w-full mx-auto text-center mb-5 lg:mb-10 space-y-5 lg:space-y-10">
                <h2 className="capitalize text-white  !leading-tight text-2xl xs:text-3xl lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:SuccessionTabData.title}}></h2>
                <div className="text-sm xs:text-base 2xl:text-lg text-[#E4E4E4]" dangerouslySetInnerHTML={{__html:SuccessionTabData.description}}></div>
            </div>
            <div className="relative">
                <Tabs 
                    tabs={tabData} 
                    navTabClass={"justify-center"}
                />
            </div>
             
        </div>
    </section>
  );
};

export default SuccessionTabSection