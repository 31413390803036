import React,{useEffect,useState,useRef} from 'react';
import { useLocation,Link } from 'react-router-dom';


const CmsTab = ({ xPlacement, ...props }) => {    
    const location = useLocation();
    const pathname = location.pathname;
    const segment = pathname.substring(pathname.lastIndexOf("/") + 1);

    const dropdownData = [       
        {_id:1, label: 'Assign Persons', icon:'fa-employee', link:'/users', type:'link', current:(segment && segment ==='users')?true:false},
        // {_id:2, label: 'Consultants', icon:'fa-user-tie', link:'/consultants', type:'link', current:(segment && segment ==='consultants')?true:false},
        // {_id:3, label: 'Consultant Admin', icon:'fa-user-tie', link:'/consultant-admin', type:'link', current:(segment && segment ==='consultant-admin')?true:false},
        // {_id:4, label: 'Sales Admin', icon:'fa-user-tie', link:'/sales-admin', type:'link', current:(segment && segment ==='sales-admin')?true:false}
    ]

    return (
        <>
        <ul className="w-full flex flex-row flex-wrap list-none border-b-2 pl-0 mb-4 border-slate-200 space-x-6 overflow-x-auto overflow-y-hidden scroll-smooth scrollbar" id="tabs-tab"
        role="tablist">
        {
            dropdownData.map((item,index) => (
            <li className="-mb-[2px]" role="presentation" key={index}>
                <Link 
                to={item.link} 
                className={"nav-link flex items-center justify-center font-semibold text-xs leading-tight uppercase h-10 text-slate-400 border-b-2 border-transparent " + (item.current?"!border-teal-500 !text-teal-500":"")}
                id="tabs-home-tab" 
                data-bs-toggle="pill" 
                data-bs-target="#tabs-home" 
                role="tab" 
                aria-controls="tabs-home"
                aria-selected="true"
                >{item?.label}</Link>
            </li> 
            ))
        }       
        </ul>
</>
    )
}
export default CmsTab;