import React from 'react';
import PriceingPlanItem from "../Items/PriceingPlanItem";

const AnnualPlan = ({plans}) => {
  // const pricingPlanData = {
  //   pricingPlans: [
  //     {
  //       title: "Essentials",
  //       price: "275",
  //       recurring: "mo*",
  //       btnname: "Get Started",
  //       buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
  //       link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
  //       features: [
  //         { _id: "1", name: "10 SUCCESSION PLANS" },
  //         { _id: "2", name: "PERSONAL CONSULTATION SETUP FOR 1 PLAN" },
  //         { _id: "3", name: "1 ADMIN AND USER TRAINING SESSION" }
  //       ]
  //     },
  //     {
  //       title: "Professionals",
  //       price: "525",
  //       recurring: "mo*",
  //       btnname: "Get Started",
  //       buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
  //       link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
  //       features: [
  //         { _id: "1", name: "25 SUCCESSION PLANS" },
  //         { _id: "2", name: "PERSONAL CONSULTATION SETUP FOR 2 PLANS" },
  //         { _id: "3", name: "2 ADMIN AND USER TRAINING SESSION" }
  //       ]
  //     },
  //     {
  //       title: "Enterprise",
  //       price: "850",
  //       recurring: "mo*",
  //       btnname: "Get Started",
  //       buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
  //       link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
  //       features: [
  //         { _id: "1", name: "UNLIMITED SUCCESSION PLANS" },
  //         { _id: "2", name: "PERSONAL CONSULTATION SETUP FOR 3 PLANS" },
  //         { _id: "3", name: "3 ADMIN AND USER TRAINING SESSION" }
  //       ]
  //     }
  //   ]
  // };
  return (
    <>
      {plans.map((item, index) => {
        let price = 0;
        let features = [];
        if(item.pricing?.rates.length>0){
          price = item.pricing?.rates.filter((it) => it?.interval === 'year')[0]?.amount;
        }
        if(item?.features?.length>0){
          features = item.features.filter((_it) => _it?.headingType === 'number-of-plans');
        }
        return (
          <PriceingPlanItem 
          key={index} 
          data={{
            title : item?.title,
            price: price,
            recurring: "yr*",
            btnname: "Get Started",
            buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
            link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
            features: features.length>0 && features.map((row,key) => {
              return {
                _id: Number(key+1), name: ((row?.inputValue>1000)?'Unlimited':row?.inputValue)+' '+row?.name
              }
            })
          }}
          />
        )        
      })}
    </>
    
  );
};

export default AnnualPlan;
