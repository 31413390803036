import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const BoardMemberDetailsReport = ({ details,reatingOptions, user }) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });  

  let checkRatedCount = reatingOptions.filter((it) => it?.question?.fieldType !== 'checkbox');
  const totalQuestion = Number(checkRatedCount?.length);
  let totalSum = 0;
  if(details?.ratingOptions && details?.ratingOptions.length>0){
    details?.ratingOptions.forEach(obj => {
      totalSum += (obj?.rating)?obj?.rating:0;
    });
  }
  let avg = Number(totalSum/totalQuestion).toFixed(2);

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>{t('board_member_details')}</Text>
          </View>
        </View>
       
        {details && Object.keys(details).length > 0 &&       
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30}}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap: 20, padding:"10px 10px", backgroundColor:"#f2f2f2" }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 12 }]}>
                {details?.first_name+' '+details?.last_name} ({(details && details?.position_details)?details?.position_details?.name : ""})
              </Text>
            </View>                     
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('years_on_the_board')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                    {(details && details?.board_year_details)?details?.board_year_details?.name : ""} 
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('board_retirement_window')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                    {(details && details?.retirement_window_details)?details?.retirement_window_details?.name : ""}    
                  </Text>
                </View>  
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('current_board_position_held')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                    {(details && details?.position_details)?details?.position_details?.name : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('previous_board_position_held')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.previous_position_details)?details?.previous_position_details?.name : ""}    
                  </Text>
                </View>  
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('previous_board_position_held')+' 2'}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.previous_position_two_details)?details?.previous_position_two_details?.name : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('previous_board_position_held')+' 3'}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                    {(details && details?.previous_position_three_details)?details?.previous_position_three_details?.name : ""}
                  </Text>
                </View>  
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('education')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.education_details)?details?.education_details?.name : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('field_of_study')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.study_details)?details?.study_details?.name : ""}
                  </Text>
                </View>  
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('industry_experience')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.industry_details)?details?.industry_details?.name : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('primary_area_of_expertise')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.primary_expertise_details)? details?.primary_expertise_details?.name : ""}
                  </Text>
                </View>  
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('years_of_primary_experience')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.primary_experience)? (Number(details?.primary_experience)>1)?Number(details?.primary_experience) +' Years':Number(details?.primary_experience) +' Year' : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('desire_for_additional_responsibilities')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.desire_details)?details?.desire_details?.name: ""}
                  </Text>
                </View>  
              </View>
              {/* <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('secondary_area_of_expertise')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.secondary_expertise_details)? details?.secondary_expertise_details?.name : ""}
                  </Text>
                </View>  
              </View> */}
            </View>
            {/* <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('years_of_secondary_experience')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.secondary_experience)? (Number(details?.secondary_experience)>1)?Number(details?.secondary_experience) +' Years':Number(details?.secondary_experience) +' Year' : ""}
                  </Text>
                </View>  
              </View>
              <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('management_level')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.management_level_details)?details?.management_level_details?.name: ""}
                  </Text>
                </View>  
              </View>
            </View> */}
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", }}>
              {/* <View style={{width:"50%", display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <View style={[styles.tableData, {width:"50%" }]}>
                  <Text style={[{whiteSpace:"nowrap", fontWeight: 500, fontSize: 10, lineHeight: 1 }]}>{t('years_of_management_experience')}</Text>
                </View>
                <View style={[styles.tableData, {width:"50%"}]}>
                  <Text style={{ fontSize: 9 }}>
                  {(details && details?.management_experience_details)?details?.management_experience_details?.name: ""}
                  </Text>
                </View>  
              </View> */}
              
            </View>
          </View>         
        }
        <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }}>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", justifyContent:"space-between", columnGap: 20, padding:"10px 10px", backgroundColor:"#f2f2f2" }]}>
            <View style={{display:"flex", flexDirection:"row", width:"70%"}}>
              <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12 }]}>
                {t('board_effectiveness')}
              </Text>
            </View>                  
            <View style={{display:"flex", flexDirection:"row", width:"30%", gap:5}}>
                <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('overall_score')}:</Text>
                <Text style={[styles.tableOtherData, {fontFamily: "RobotoRegular", fontSize: 12, color:"#000" }]}>{avg}</Text>
            </View>
          </View>            
          { reatingOptions.length>0 && reatingOptions.map((items, index) => {
              let ratingRowAnswers = items?.question?.answers;
              const ratedBoardMember = details?.ratingOptions && details?.ratingOptions.filter((row) => row.questionId === items?.question?._id);
              let totalRating = 0;
              let ansLabel = ''; 
              let comment = '';
              let _ans = [];
              if(ratedBoardMember && ratedBoardMember.length>0){
                totalRating =  ratedBoardMember[0]?.rating;
                ansLabel =  ratedBoardMember[0]?.answerLabel;
                comment = ratedBoardMember[0]?.comments;
                _ans = ratedBoardMember[0]?.ids;
              }
              return (
                <View style={{borderTop: "1px solid #e2e8f0", paddingHorizontal:3}}>
                  <View key={index} style={{ display: "flex", flexDirection: "row",  }}>
                      <View style={[styles.tableData, {width:"70%", display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                        <Text style={[{whiteSpace:"nowrap", fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1, marginBottom:3 }]}>{Number(index+1)}.{' '+items?.question?.name}</Text>
                        {
                          ( _ans && _ans.length>0) && 
                          <View style={{position:"relative", paddingLeft:10, marginTop:4}}>
                            {
                              _ans.map((an,i) =>{
                                let rowData = ratingRowAnswers.filter((row) => row._id === an);
                                if(rowData){
                                  return (
                                    <View style={{display:"flex", flexDirection:"row", alignItems:"center", gap:2, marginBottom:5}}>
                                      <View style={{width:4, height:4, borderRadius:"50%", backgroundColor:"rgb(71,85,105,0.5)"}} ></View>
                                      <Text style={{color:"rgb(71,85,105,1)", fontSize:9, lineHeight:1,}} >{rowData[0]?.description}</Text>
                                    </View>
                                  )
                                }
                              })
                            }
                          </View>
                        }
                        {
                          comment && 
                          <Text style={[{whiteSpace:"nowrap", fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1, paddingLeft:10, color:"#64748b" }]}>{comment}</Text>
                        }                    
                      </View>
                      
                      <View key={index} style={[styles.tableData, {width:"30%", display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                        <Text style={{ fontSize: 9 }}>
                        {((totalRating>0)?totalRating:'')+((ansLabel)?' ('+ansLabel+')':'')}                   
                        </Text>
                      </View>
                  </View>                  
                </View>
              )            
            }) 
          }
          </View>
      </Page>
    </Document>
  )
};
export default BoardMemberDetailsReport;