import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BoardMemberReport from "./pdf/BoardMemberReport";
import BoardMemeberAlert from "../components/elements/alert/BoardMemeberAlert";
import CardAction from "../components/Common/CardAction";
import { useNavigate } from "react-router-dom";
import BoardMemberAllReport from "./pdf/BoardMemberAllReport";
import ManageInviteBoardMemberPopup from "../components/popup/ManageInviteBoardMemberPopup";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const BoardMamberNew = () => {
  setTitle("Succession Now | Board Member");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [boardMambers, setBoardMambers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [reatingOptions, setReatingOptions] = useState([]);  
  const [enabledInviteBoardMemberPopup,setEnabledInviteBoardMemberPopup] = useState(false);  
  const [inviteInfo,setInviteInfo] = useState({})

  useEffect(() => {
    if (role && role !== "customer-admin") {
      return navigate("/dashboard");
    }
  }, [role]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit :50,
          type: "boardmember"
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, []);

  useEffect(() => {
    // Board Member List
    const loadBoardMembers = async () => {
      setLoading(true);
      try {
        const result = await postData("board-member/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          outside_candidate: 2,
        });
        if (result.data) {
          setBoardMambers(result.data?.sort((a, b) => a.first_name.localeCompare(b.first_name)));
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadBoardMembers();
  }, [keyword, limit, offset, isUpdate, actionValue]);

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  useEffect(() => {
    if(actionValue && actionValue.button_type === 'send-invitation-boardmember'){
      setEnabledInviteBoardMemberPopup(true);
      setInviteInfo({
        _id: actionValue.row_id,
      })
    }else if (actionValue && actionValue.button_type === "resend-invitation-boardmember") {
      const loadResendInvitation = async () => {
        try {
          const othersData = await postData("board-member/invitation-resend", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            setActionValue({});
            toastr.success("Email resent.Please make sure to check your spam email.");
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadResendInvitation();
    }
  },[actionValue])  

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("board_member")}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {role && (role === "customer-admin" || role === "customer-subadmin" || role === "customer-boardmember") && (
            <div className="">
              <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add_board_member")} buttonHasLink={true} buttonLink={"/boardroom"} />
            </div>
          )}
          {/* <button
          type={'button'}
          className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "}
          onClick={(e) => getDownload(e)}
        >
          <i className={"fa-fw fa-light fa-download"}></i>
          <div className={"text-sm font-medium whitespace-nowrap"}>
            <PDFDownloadLink document={
            <BoardMemberAllReport 
            boardMembers={boardMambers} 
            reatingOptions={reatingOptions}
            user={user} 
            />} fileName="boardmember_report.pdf">
              {({ blob, url, loading, error }) => (
                loading ? t('loading_document') : t('report'))}
            </PDFDownloadLink>
          </div>
        </button> */}
        </div>
      </div>
      <BoardMemeberAlert />
      <div className="relative flex justify-between items-center mb-4">&nbsp;</div>
      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        {totalDataCount > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 sm:gap-3">
            {boardMambers.length > 0 &&
              boardMambers.map((item, index) => {
                let namelatter = getInitials(item.first_name + " " + item.last_name);
                let totalQuestion = reatingOptions?.length;
                let filterAnswer = item?.ratingOptions?.length > 0 && item?.ratingOptions.filter((it) => (it?.answerId !== null || it?.ids?.length>0));
                let totalAnswer = filterAnswer?.length;
                return (
                  <div className="relative w-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden" key={index}>
                    {totalQuestion === totalAnswer ? (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-green-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">{t('completed')}</div>
                      </div>
                    ) : (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-rose-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">{t('in_completed')}</div>
                      </div>
                    )}
                    <div className="flex justify-end px-2">
                      <CardAction
                        xPlacement={"right"}
                        dropdownData={[
                          {
                            _id: 1,
                            label: t("edit"),
                            icon: "fa-pen-to-square",
                            link: "/board-member/edit/" + item?._id,
                            type: "link",
                            standout: false,
                            value: item?._id,
                            buttonType: "",
                            ordering: 1,
                          },
                          {
                            _id: 2,
                            label: t("details"),
                            icon: "fa-eye",
                            link: "/board-member/details/" + item?._id,
                            type: "link",
                            standout: false,
                            value: item?._id,
                            buttonType: "",
                            ordering: 2,
                          },
                          (!item?.parent_id)?
                          {
                            _id: 3,
                            label: t("send_invite"),
                            icon: "fa-envelope",
                            link: "/",
                            type: "button",
                            standout: false,
                            value: item?._id,
                            buttonType: "send-invitation-boardmember",
                            ordering: 3,
                          }:'',
                          (item?.parent_id && item?.email_verified !== 1)?
                          {
                            _id: 3,
                            label: t("resend_invite"),
                            icon: "fa-envelope",
                            link: "/",
                            type: "button",
                            standout: false,
                            value: item?._id,
                            buttonType: "resend-invitation-boardmember",
                            ordering: 3,
                          }:'',
                        ]}
                        getActionValue={(obj)=> setActionValue(obj)}
                      />
                    </div>
                    <div className="flex flex-col items-center pb-10 space-y-2">
                      <div className="w-16 h-16 overflow-hidden bg-slate-100 rounded-full relative flex items-center justify-center border border-white font-bold">{namelatter}</div>
                      <div className="text-center">
                        <h5 className="text-base sm:text-lg font-bold text-gray-900 capitalize">{item?.first_name + " " + item?.last_name}</h5>
                        <div className="text-xs sm:text-sm font-semibold sm:font-normal text-gray-600 capitalize">{item?.position_details ? item?.position_details?.name : ""}</div>
                        <div className="flex justify-center mt-2">
                          {
                            (item?.parent_id)?
                            (item?.email_verified && item?.email_verified === 1)?
                            <div className="text-sm flex items-center space-x-1 text-green-500">
                              <i className="fa-regular fa-fw fa-check-circle"></i>
                              <span className="text-xs font-semibold">{t('invite_accecpted')}</span>
                            </div>:
                            <div className="text-sm flex items-center space-x-1 text-yellow-500">
                              <i className="fa-regular fa-fw fa-clock"></i>
                              <span className="text-xs font-semibold">{t('invited')}</span>
                            </div>:
                            <div className="text-sm flex items-center space-x-1 text-red-500">
                              <i className="fa-regular fa-fw fa-circle-xmark"></i>
                              <span className="text-xs font-semibold">{t('not_invited')}</span>
                            </div>
                          }                          
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="relative bg-white shadow rounded">
            <div className="py-8 px-4 w-full">{loading ? <Searching label={t("searching")} /> : <NoDataFound label={t("no_board_member_found")} />}</div>
          </div>
        )}
      </div>
      {
        enabledInviteBoardMemberPopup && 
        <ManageInviteBoardMemberPopup
          isOpen={enabledInviteBoardMemberPopup}
          setIsOpen={(val) => setEnabledInviteBoardMemberPopup(val)}
          data={ inviteInfo }
          getActionValue={(obj) => {
            setActionValue(obj)  
            setEnabledInviteBoardMemberPopup(false);
          }}
        />
      }
    </>
  );
};

export default BoardMamberNew;
