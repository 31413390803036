import React from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers";
import Banner from "../../assets/image/about-banner.jpg";

const PageBanner = ({title, sectionClass}) => {
  return (
    <>
      <section className={classNames("relative flex items-center w-full h-80 bg-slate-800 overflow-hidden z-0 pt-20", sectionClass)}>
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden -z-[1] after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-slate-800 after:opacity-80">
          <Image 
            src={Banner} 
            alt={''} 
            width={'100%'}
            height={'100%'} 
            effect={'blur'} 
            classes={'object-cover'}
            id={""}
          />
        </div>
        {title &&
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative">
            <h2 className="mb-6">
              <span className="block lg:4xl xl:text-5xl text-3xl font-bold text-white">{title}</span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">Succession Now</span>
            </h2>
          </div>
        </div>
        }
      </section>
    </>
  );
};

export default PageBanner;