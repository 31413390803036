import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from '../helpers'; // Adjust the import path as necessary

import { useDispatch } from "react-redux";
import { authLogout } from '../store/authSlice';

const useTokenExpirationChecker = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();  

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('accessToken'); // or wherever you store the token

      if (isTokenExpired(token)) {
        // Token is expired, perform logout
        dispatch(authLogout());
        localStorage.removeItem('accessToken');
        localStorage.removeItem("loginAs");
        localStorage.removeItem("role");
        localStorage.removeItem('isTrial');

        localStorage.removeItem("reminderId");
        localStorage.removeItem("reminderDate");
        localStorage.removeItem("reminderFiled");
        return navigate("/signin"); 
      }
    };

    // Event listener callbacks
    const handleUserActivity = () => {
      checkTokenExpiration();
    };

    // Add event listeners
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Check token expiration on component mount
    checkTokenExpiration();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [navigate]);
};

export default useTokenExpirationChecker;
