import React, { useState, useRef, useEffect } from "react";

import { allowedImageExtensions } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import ButtonFile from "../../components/form/ButtonFile";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import { postData } from "../../services/api";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import Textarea from "../../components/form/Textarea";

import { Editor } from "@tinymce/tinymce-react";
import { tinymceApiKey } from "../../helpers";
import { setTitle } from "../../helpers/MetaTag";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../helper/useWindowDimensions";

export default function Addblogs() {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Articles");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Articles");
  }
  const { i18n, t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const editorRef = useRef(null);
  const [details, setDetails] = useState({});
  const [title, setTitleMain] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [alias, setAlias] = useState("");
  const navigate = useNavigate();
  const [content, setContent] = useState("");

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("blog/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    setTitleMain(details?.name);
    setDescription(details?.description);
    setShortDescription(details?.short_description);
    setAlias(details?.alias);
    setShowLogo(details?.image);
  }, [details]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", title);
    formData.append("description", description);
    formData.append("short_description", shortDescription);
    formData.append("alias", alias);
    formData.append("image", image);
    // console.log('formData',formData)
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "blog/update";
        formData.append("id", id);
        payload = formData;
        // {
        //   id: id,
        //   name: title,
        //   description: description,
        //   short_description: shortDescription,
        //   image: image,
        //   alias: alias
        // }
      } else {
        path = "blog/create";
        payload = formData;
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        navigate("/articles");
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setIsManage(false);
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold"> {id ? t("edit_article") : t("add_article")}</div>
      </div>
      <form>
        <div className="relative grid sm:grid-cols-12 gap-4">
          <div className="sm:col-span-4 lg:col-span-3 space-y-4">
            <div className="relative bg-white rounded shadow">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="w-full h-32 lg:h-52 overflow-hidden rounded-md">
                  <Image src={showLogo} alt={"Admin"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-contain"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>
          </div>
          <div className="sm:col-span-8 lg:col-span-9">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-4 sm:py-6 px-4 sm:px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input
                        label={t("title")}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputName={"name"}
                        inputPlaceholder={t("give_title")}
                        inputValue={title}
                        setInput={(val) => {
                          setTitleMain(val);
                          //console.log(slugify(val, { remove: /[*+~.()'"!:@#]/g }))
                          setAlias(slugify(val, { remove: /[*+~.()'"!:@#]/g }).toLocaleLowerCase());
                          //this.setState({name: val,alias:slugify(val, { remove: /[*+~.()'"!:@#]/g }).toLocaleLowerCase()})
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("alias")} labelClasses={"!text-xs"} inputType={"text"} inputName={"alias"} inputPlaceholder={t("enter_alias")} inputValue={alias} setInput={setAlias} isDisabled={true} />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Textarea label={t("short_description")} labelClasses={"!text-xs"} inputValue={shortDescription} inputName={"short_description"} inputPlaceholder={t("short_description")} setTextarea={setShortDescription} />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Editor
                        apiKey={tinymceApiKey()}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={description}
                        name={"description"}
                        init={{
                          height: width > 480 ? 500:240,
                          menubar: false,
                          plugins: ["code", "advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                          toolbar: "code | undo redo | formatselect | " + "bold italic backcolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help ",
                          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newText) => setDescription(newText)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  {/* <Button 
                      buttonLabelClasses=''
                      buttonType={'button'} 
                      buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                      buttonIconPosition={'left'} 
                      buttonLabel={'Cancel'} 
                      buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                      functions={onCancel}
                  
                      buttonHasLink={false}
                      buttonDisabled={isCancel}
                      /> */}
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"submit"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
