import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";

import { useTranslation } from "react-i18next";

const ViewComapnyDetailsPopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {} }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [salesPersion, setSalesPersion] = useState("");
  const [consultantPersion, setConsultantPersion] = useState("");
  const [appointmentSetterPersion, setAppointmentSetterPersion] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setName(data?.name);
    setCompanyName(data?.company_name);
    setPhone(data?.phone);
    setEmail(data?.email);
    setEmailVerified(data?.email_verified);
    setNoOfEmployee(data?.number_of_employees);
    setSalesPersion(data?.salesPersion);
    setConsultantPersion(data?.consultantPersion);
    setAppointmentSetterPersion(data?.appointmentSetterPersion);
    setActivationCode(data?.activation_code);
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("company_information")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={"fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={""} buttonClasses={"!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20"} functions={closeModal} buttonHasLink={false} />
                  </div>
                  <div className="relative mt-4 space-y-4 sm:space-y-2">
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("company_name")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{companyName}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("company_administrator")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{name}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("administrator_email")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{email}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("email_verified")} <span className="ml-1">:</span></div>
                      <div className="text-sm">
                        <div className="flex items-center">{emailVerified && emailVerified === 1 ? <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"}>{t("verified")}</div> : <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-rose-100 text-rose-600"}>{t("not_verified")}</div>}</div>
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("administrator_phone")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{phone}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("#_Of_employees")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{noOfEmployee}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("sales_person")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{salesPersion}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("appointment")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{consultantPersion}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("consultant")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{appointmentSetterPersion}</div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-1 sm:gap-4">
                      <div className="text-sm font-semibold flex sm:justify-between">{t("activation_code")} <span className="ml-1">:</span></div>
                      <div className="text-sm">{activationCode}</div>
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4"></div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewComapnyDetailsPopup;
