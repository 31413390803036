import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import toastr from 'toastr';

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const ManageConsultant = () => {
    //const { id } = useParams();
    setTitle("Succession Planning Software for your Business with Succession Now | Assign Succession Plans to Individuals")
    const { i18n,t } = useTranslation();
    const [isAdd,setIsAdd] = useState(false);
    const [name,setName] = useState('');
    const [assignTitle,setAssignTitle] = useState('');
    const [email,setEmail] = useState('');

    const onSubmit = async (e) => {
        setIsAdd(true);
        try {
            const result = await postData('assign/create', {
                name : name,
                title:assignTitle,
                email:email
            });
            if (result.status && result.status === 200) {        
                toastr.success(result.message);
                setIsAdd(false);
            } else {
                toastr.error(result.message);
                setIsAdd(false);
            }
        } catch (error) {
            toastr.error(error.message);
            setIsAdd(false);
        }
    }

    return(
        <>
        <div className="relative d-flex justify-between items-center mb-4">
            <div className="text-3xl text-slate-800 font-bold">{t('assign_succession_plans_to_individuals')}</div>
            <div className="text-sm text-slate-800 font-bold">{t('add_individuals_here_you_would_like_to_create_succession_plans_for')}</div>
        </div>
        <div className="relative grid grid-cols-2 gap-4">           
            <div className="col-span-2">
              <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                <div className="py-8 px-6 flex flex-col">
                  <div className="space-y-4 mt-4">
                    <div className="flex items-start gap-4">
                      <div className="relative w-full">
                        <Input 
                        label={t('full_name')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_full_name')}
                        inputValue={name}  
                        setInput={setName} 
                        />
                      </div>
                      <div className="relative w-full">
                        <Input 
                        label={t('title')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_title')}
                        inputValue={assignTitle}  
                        setInput={setAssignTitle} 
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                        label={t('email')}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={t('email_address')}
                        inputValue={email}
                        setInput={setEmail}
                        />
                      </div>
                    </div>                    
                  </div>
                </div>
                <div className="py-3 px-3 border-t border-slate-200">
                  <div className="flex items-center justify-end gap-4">                   
                    <Button
                    buttonClasses=''
                    buttonLabelClasses='' 
                    buttonType={'button'} 
                    buttonIcon={(isAdd)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={t('assign')}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isAdd}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}
export default ManageConsultant;