import React, { useState, useEffect } from "react";
import HomeAboutUs from "../components/section/Home/HomeAboutUs";
import HomeBanner from "../components/section/Home/HomeBanner";
import AboutService from "../components/section/Aboutus/AboutService";
import Client from "../components/section/Shared/Client";
import HomeHelp from "../components/section/Home/HomeHelp";
import Team from "../components/section/Shared/Team";
import Testimonial from "../components/section/Shared/Testimonial";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";

const Home = () => {
    setTitle("Succession Planning Software for your Business with Succession Now");
    const [settings, setSettings] = useState({});

    useEffect(() => {
      async function settingsData() {
        try {
          const result = await postData("setting/details", {});
          if (result.data) {
            setSettings(result.data);
          } else {
            console.log("settings list message", result.message);
          }
        } catch (error) {
          console.log("Error settings list catch", error.message);
        }
      }
      settingsData();
    }, []);    

    return (
        <>
          <HomeBanner/>
          <AboutService
          headingOne={true} 
          headingTwo={true} 
          headingThree={true}
          />
          {
            (settings && settings.enabled_how_it_work) && 
            <HomeHelp/>
          }  
          <Client /> 
          <Testimonial />       
          <HomeAboutUs/>
          {/*<Team />*/}
        </>
      );
}

export default Home;