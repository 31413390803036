import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";
import Select from "../components/form/Select";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import Table from "../components/elements/table/Table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toastr from "toastr";
import { reorder, arrayToString } from "../helpers";
import ManagePermissionPopup from "../components/popup/ManagePermissionPopup";
import DropdownCheckbox from "../components/form/DropdownCheckbox";

import { useTranslation } from "react-i18next";

const Permission = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | User Permission");
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [roleCode, setRoleCode] = useState([]);
  const [rawUser, setRawUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");

  const [enabledPermissionPopup, setEnabledPermissionPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [permissionInfo, setPermissionInfo] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const extraData = [
    {
      _id: 1,
      label: t("manage_permission"),
      icon: "fa-key",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "manage-permission",
      isVisabled: 1,
      ordering: 1,
    },
  ];

  useEffect(() => {
    async function RoleData() {
      try {
        const result = await postData("role/list", {
          sortQuery: { ordering: 1 },
          status: 1,
          codes: ["sales-admin", "sales-staff", "master-admin", "consultant", "content-manager"],
        });
        if (result.data) {
          setRoles(
            result.data.map((role) => {
              return {
                _id: role?._id,
                label: role?.name,
                value: role?.code,
                isEnabled: false,
              };
            })
          );
        } else {
          console.log("role list message", result.message);
        }
      } catch (error) {
        console.log("Error role list catch", error.message);
      }
    }
    RoleData();
  }, []);

  const handleOnDragEnd = async (result) => {
    const items = reorder(users, result.source.index, result.destination.index);
    setUsers(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const tableHeadData = [
    { _id: 1, width: 15, name: t("name"), value: "name", align: "left", isSort: true, isFilter: false },
    { _id: 2, width: 40, name: t("position"), value: "designation", align: "left", isSort: true, isFilter: false },
    { _id: 3, width: 15, name: t("email"), value: "name", align: "left", isSort: false, isFilter: false },
    { _id: 4, width: 15, name: t("permission_set"), value: "email", align: "left", isSort: false, isFilter: false },
    { _id: 5, width: 10, name: t("action"), value: "", align: "right", isSort: false, isFilter: false },
  ];

  useEffect(() => {
    // Email template List
    const loadUsers = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/list", {
          code: roleCode,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
        });
        if (result.data) {
          setRawUser(result.data);
          setUsers(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  { _id: 1, width: 15, type: "text", data: value?.first_name ? value?.first_name + " " + value?.last_name : value?.name },
                  { _id: 2, width: 40, type: "text", data: value?.position },
                  { _id: 3, width: 15, type: "text", data: value?.email },
                  { _id: 4, width: 15, type: "text", data: value?.permission ? arrayToString(value?.permission) : " " },
                  { _id: 5, width: 10, type: "action", data: [{ "manage-permission": 1 }], isColor: "" },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadUsers();
  }, [limit, offset, sortQuery, roleCode, actionValue, isUpdate]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "manage-permission") {
      setEnabledPermissionPopup(true);
      const index = rawUser.findIndex((item) => item._id === actionValue?.row_id);
      setPermissionInfo({
        _id: actionValue.row_id,
        permission: rawUser[index]?.permission ? rawUser[index]?.permission : [],
      });
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      setIsUpdate(false);
      // delete permission
      const deletePermission = async () => {
        const statusData = await postData("usertype/delete-permission", {
          id: actionValue?.row_id,
        });
        if (statusData.status === 200) {
          //setAdmins(admins => admins.filter((item) => item._id !== actionValue.row_id))
          setIsUpdate(true);
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deletePermission();
    }
  }, [actionValue]);

  const roleSelect = async (id, checked) => {
    if (id && id === "all") {
      setRoles(
        roles.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
      setRoleCode(
        roles.map((pos) => {
          if (checked) {
            return pos?.value;
          } else {
            return "";
          }
        })
      );
    } else {
      setRoles(
        roles.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  useEffect(() => {
    let allRecord = roles.filter((item) => item.isEnabled);
    setRoleCode(
      allRecord.map((itm) => {
        return itm?.value;
      })
    );
  }, [roles]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("user_permission")}</div>
      </div>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="relative z-20">
          <DropdownCheckbox data={roles} functions={roleSelect} />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow ">
          <Table
            tableData={users}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            //detailsUrl={'/transactions'}
            isDeleteEnabled={true}
            getActionValue={(obj) => setActionValue(obj)}
            // othersAction = {othersAction}
            isDraggable={false}
            extraData={extraData}
          />
        </div>
      </DragDropContext>
      {enabledPermissionPopup && (
        <ManagePermissionPopup
          isOpen={enabledPermissionPopup}
          setIsOpen={(val) => setEnabledPermissionPopup(val)}
          data={permissionInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledPermissionPopup(false);
          }}
        />
      )}
    </>
  );
};

export default Permission;
