import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const BoardMemberReadinessReport = ({ boardMembers, user }) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>{t('board_member_readiness')}</Text>
          </View>
        </View>
        {boardMembers && boardMembers.length > 0 &&
        <>  
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              <View style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>&nbsp;</Text>
              </View>
              <View style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{t('readiness_timeframe')}</Text>
              </View>
              <View style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{t('needed_development')}</Text>
              </View>
              <View style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{t('potential_committee_assignments')}</Text>
              </View>
              <View style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{t('additional_comments')}</Text>
              </View>
            </View> 
            {
              boardMembers.map((item,index) => {
                return (
                  <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                    <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                      <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{item.first_name+' '+item.last_name}</Text>
                    </View>
                    <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                      <Text style={{ fontSize: 9 }}>
                        {(item?.timeframe_details)?item?.timeframe_details?.name:"N/A"}                      
                      </Text>
                    </View>
                    <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                      <Text style={{ fontSize: 9 }}>
                        {(item?.readiness_needed_development_details)?item?.readiness_needed_development_details?.name:"N/A"}                      
                      </Text>
                    </View>
                    <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                      <Text style={{ fontSize: 9 }}>
                        {(item?.committeefit_details)?item?.committeefit_details?.name:"N/A"}                      
                      </Text>
                    </View>
                    <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                      <Text style={{ fontSize: 9 }}>
                          {(item?.advancement_additional_comments)? item?.advancement_additional_comments : "N/A"}
                      </Text>
                    </View>
                  </View>
                )
              })
            }
          </View>        
        </>
        }
      </Page>
    </Document>
  );

};

export default BoardMemberReadinessReport;