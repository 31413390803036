import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../../components/form/Button";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const ManageTerms = () => {
  setTitle("Succession Now | Terms & Conditions");
  const { i18n, t } = useTranslation();
  const [pageData, setPageData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  useEffect(() => {
    async function loadPageData() {
      try {
        const result = await postData("page/view", {
          alias: "terms-and-conditions",
        });
        if (result.data) {
          setPageData(result.data);
        } else {
          console.log("Page Details message", result.message);
        }
      } catch (error) {
        console.log("Error page details catch", error.message);
      }
    }
    loadPageData();
  }, []);

  useEffect(() => {
    if (pageData.description) {
      setDescription(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(pageData?.description))));
    }
    setId(pageData?._id);
  }, [pageData]);

  const onUpdate = async (e) => {
    setIsUpdate(true);
    const rawContentState = convertToRaw(description.getCurrentContent());
    const html = draftToHtml(rawContentState);
    try {
      let path = "";
      let payload = {};
      if (id) {
        path = "page/manage";
        payload = {
          id: id,
          description: html,
        };
      } else {
        path = "page/manage";
        payload = {
          alias: "terms-and-conditions",
          description: html,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("terms_and_conditions")}</div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="py-4 px-4">
          <Editor editorState={description} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" onEditorStateChange={onEditorStateChange} />
        </div>
        <div className="py-3 px-3 border-t border-slate-200">
          <div className="flex items-center justify-end gap-4">
            <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onUpdate} buttonHasLink={false} buttonDisabled={isUpdate} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageTerms;
