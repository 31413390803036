import React,{useEffect,useState} from 'react';
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/section/PageBanner";
import { postData } from '../services/api';
import AboutService from '../components/section/Aboutus/AboutService';
import { useTranslation } from "react-i18next";
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import { Link } from 'react-router-dom';

const OurClients = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Our Clients");
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});

  useEffect(() => {
    const loadClientDetails = async () => {
      setLoading(true);
      try {
        const result = await postData("/client/list", {
          status :1,
          sortQuery:{ordering:1}
        });
        if (result.data) {
          setClientData(result.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };
    loadClientDetails();   
  }, []);


  return (
    <>
      <PageBanner title={t('clients')}/>
      <section className="relative w-full py-10 lg:py-14 xl:py-16 2xl:py-20 bg-white">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
          <div className="max-w-3xl w-full mx-auto text-center mb-5 xs:mb-10 xl:mb-20">
            <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans">{t('clients')}</h2>
          </div>
          <div className="w-full scroll-smooth scrollbar mb-4">
            {
              (clientData.length > 0)? (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                  {
                    clientData.map((item,index) => {
                      return (
                        <a href={item?.link} target='_blank' rel="noopener noreferrer" key={index}>
                          <div className="relative w-full h-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden">
                            <div className="flex flex-col items-center py-5 px-4 space-y-4">
                              <div className="w-16 h-16 overflow-hidden bg-slate-100 rounded-full relative flex items-center justify-center border border-white font-bold">
                                <img className="rounded-t-lg" src={item?.image} alt="" />
                              </div>
                              <div className="text-center">
                                <h5 className="text-sm md:text-base lg:text-lg font-bold text-gray-900 capitalize !leading-none">{item?.name}</h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })
                  }
                </div>
              ):(
                <div className="relative bg-white shadow rounded">
                  <div className="py-8 px-4 w-full">{loading ? <Searching label={t("searching")} /> : <NoDataFound label={t("no_board_member_found")} />}</div>
                </div>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default OurClients;