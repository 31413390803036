import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Textarea from "../components/form/Textarea";
import Input from "../components/form/Input";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";

import { languages } from "../helpers";
import { useTranslation } from "react-i18next";

const ManageSuccessionPlan = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Succession Plan");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Succession Plan");
  }
  const allLang = languages();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [details, setDetails] = useState({});
  const [name, setName] = useState({});
  const [description, setDescription] = useState({});
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("successionplan/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setDescription(details?.description);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setDescription(details.description);
    } else {
      setName("");
      setDescription("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "successionplan/update";
        payload = {
          id: id,
          name: name,
          description: description,
        };
      } else {
        path = "successionplan/create";
        payload = {
          name: name,
          description: description,
        };
      }

      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/succession-plans");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_successionplan") : t("add_successionplan")}</div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200 px-4 py-4">
            <ul className="flex flex-wrap text-sm font-medium text-center border-b-4 border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
              {allLang &&
                allLang.length > 0 &&
                allLang.map((item, index) => (
                  <li className="" role="presentation" key={index}>
                    <button className={"inline-block py-2 border-b-4 rounded-t-lg transition-all duration-200 -mb-1 " + (index === activeTab ? "text-teal-500 hover:text-teal-500 border-teal-500" : "text-slate-400 border-slate-200 hover:text-slate-500 hover:border-slate-300")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                      {item?.label}
                    </button>
                  </li>
                ))}
            </ul>
            <div id="default-tab-content">
              {allLang &&
                allLang.length > 0 &&
                allLang.map((item, index) => (
                  <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                    <div className=" py-4">
                      <div className="space-y-4">
                        <div className="flex items-start gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("name")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_name")}
                              inputValue={name?.[item?.value]}
                              setInput={(val) =>
                                setName({
                                  ...name,
                                  [item?.value]: val,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="relative w-full">
                            <Textarea
                              label={t("description")}
                              labelClasses={"!text-xs"}
                              inputValue={description?.[item?.value]}
                              inputPlaceholder={t("enter_description")}
                              setTextarea={(val) =>
                                setDescription({
                                  ...description,
                                  [item?.value]: val,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="py-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSuccessionPlan;
