import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const AdvancementReadinessIndividualReport = ({ user, successors, heading }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 10,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 10,
      width: "25%",
      padding: "8px 4px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    subText: {
      fontSize: 8,
      marginTop: 2,
    },
    tableData: {
      fontSize: 10,
      width: "25%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableText: {
      fontSize: 9,
    },
    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4" orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
              // src='https://successionnow.iosx.in/logo-succession-now.png'
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16, textTransform: "capitalize" }]}>
              Succession Plan for {user?.title}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name} ({user?.title})</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                <View style={[{ marginBottom: 2, marginTop: 2 }]}></View>
                {row.successors_details && (
                  <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap: 20, marginTop: 5, marginBottom: 5 }]} key={key}>
                    <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12, marginRight: 2 }]}>
                      {row.position} : {row.name}
                    </Text>
                    <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>Retirement Window : {(row?.retirement)?row?.retirement?.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</Text>
                  </View>
                )}
                {row.successors_details && row.successors_details.length > 0 &&
                  <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }} key={key}>
                    <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
                      <Text style={[styles.tableHeading, { fontFamily: 'RobotoMedium', fontSize: 10 }]}>&nbsp;</Text>
                      {row.successors_details.map((item,index) => (
                        <View key={index} style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                          <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{item.name}</Text>
                          <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details? item?.position_details[0]?.short_name: "N/A"}</Text>
                        </View>
                      ))}
                    </View>          
                    { heading.map((items, index) => (
                      <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                        <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                          <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                        </View>

                        {row.successors_details.map((item,index2) => (
                          <View key={index+'_'+index2} style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                            {index === 0 &&
                              <Text style={{ fontSize: 9 }}>
                                {item?.timeframe_details && item?.timeframe_details.length > 0 && item?.timeframe_details[0]?.name}                      
                              </Text>
                            }
                            {index === 1 &&
                              <Text style={{ fontSize: 9 }}>
                                {item?.competencyfits_details && item?.competencyfits_details.length > 0 && item?.competencyfits_details[0]?.name}
                              </Text>
                            }
                            {index === 2 &&
                              <Text style={{ fontSize: 9 }}>
                                {item?.cultural_details && item?.cultural_details.length > 0 && item?.cultural_details[0]?.name}
                              </Text>
                            }
                            {index === 3 &&
                              <Text style={{ fontSize: 9 }}>
                              {item?.advancement_additional_comments}
                              </Text>
                            }
                          </View>
                        ))}

                      </View>
                    )) }

                  </View>
                }
              </>
            );
          })}
      </Page>
    </Document>
  );
};

export default AdvancementReadinessIndividualReport;
