import React, { useState, useEffect, Fragment,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import { allowedImageExtensions,parseDate,formatDate } from "../../helpers";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

import { postData } from "../../services/api";
import moment from 'moment';
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const ManageEmployeePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n,t } = useTranslation();
  const flatPicker = useRef(null);
  //const timezone =  Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Denver' });
  const role = localStorage.getItem("role");  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [designation,setDesignation] = useState('');
  const [loading, setLoading] = useState(false);
  const [position,setPosition] = useState([]);    

  const closeModal = () => {
    setIsOpen(false);
  };  

  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await postData("employee/add", {
        firstname: firstName,
        lastname : lastName,
        email: email,
        designation: designation !== "" ? designation : null,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "manage",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setPosition(result.data);
        } else {
            console.log('Position list message',result.message)
        }
      } catch (error) {
      console.log('Error position list catch',error.message)
      }
    }
    positionData();    
  }, []);

  useEffect(() => {
    setFirstName(data?.first_name);
    setLastName(data?.last_name)
    setEmail(data?.email);
    setDesignation(data?.position);
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t('manage_information')}
                  </Dialog.Title>
                  <div className="grid grid-cols-3 gap-4  mt-4">
                    <div className="relative col-span-3">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Input
                            label={t('first_name')}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t('enter_first_name')}
                            inputValue={firstName}
                            setInput={setFirstName}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Input
                            label={t('last_name')}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t('enter_last_name')}
                            inputValue={lastName}
                            setInput={setLastName}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Input
                            label={t('email')}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t('enter_email')}
                            inputValue={email}
                            setInput={setEmail}
                            //isDisabled={true}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Select 
                            label={t('position')}
                            labelClasses={'!text-xs'}
                            xPlacement={'left'} 
                            selectedValue={designation} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                              [
                                { _id: "", label: t('select_position'),value:''} ,
                                ...position.map((item) => (
                                    { _id: item?._id, label: item?.name, value: item?._id }
                                )),
                                // { _id: "others", label: "Other", value: "others" },
                              ]
                            }
                            getSelectedValue={(e) => {
                              // if(e._id === 'others'){
                              //   setEnabledOtherPositionPopup(true);
                              // }
                              setDesignation(e._id)
                            }}
                          />
                        </div> 
                      </div>                      
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t('confirm')}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t('cancel')}
                      buttonClasses={
                        "!bg-slate-200 !text-slate-600 border border-slate-300"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageEmployeePopup;
