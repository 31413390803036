import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import ButtonFile from "../components/form/ButtonFile";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import Switch from "../components/form/Switch";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { useDispatch } from 'react-redux';

import { postData } from "../services/api";
import { convertToBase64 } from "../helpers/base64FileHelper";
import { allowedImageExtensions } from "../helpers";
import { API_URL } from "../config/host";
import { authSettingsSetup } from "../store/authSlice";

import { useTranslation } from "react-i18next";

const Settings = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Settings");
  const { i18n,t } = useTranslation();
  const dispatch = useDispatch();
  // const socialData = [
  //   { _id: 1, name: "Facebook", value: "https://www.facebook.com/" },
  //   { _id: 2, name: "Twitter", value: "https://twitter.com/login" },
  //   { _id: 3, name: "Linkedin", value: "https://www.linkedin.com/" },
  //   { _id: 4, name: "Instagram", value: "https://www.instagram.com/" },
  // ];

  const flatPicker = useRef(null);

  const [siteSettingData, setSiteSettingData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [licenseGetData, setLicenseGetData] = useState({});
  // const [roles, setRoles] = useState([]);
  // const [roleList, setRoleList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);

  const [id, setId] = useState("");
  const [siteTitle, setSiteTitle] = useState({});
  const [email, setEmail] = useState({});
  const [phone, setPhone] = useState({});
  const [address, setAddress] = useState({});
  const [image, setImage] = useState("");
  const [favicon, setFavicon] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [showFavicon, setShowFavicon] = useState("");
  // const [companyName, setCompanyName] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [selectedRole, setSelectedRole] = useState("");
  const [licenseId,setLicenseId] = useState('');
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpiryDate, setLicenseExpiryDate] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const [isLicenseUpdate, setIslicenseUpdate] = useState(false);
  const [isLicenseCancel, setIsLicenseCancel] = useState(false);
  const [isLicense,setIsLicense] = useState(false);
  const [isEdit,setIsEdit] = useState(false);

  const [isLogoImage,setIsLogoImage] = useState(false);
  const [isFaviconImage,setIsFaviconImage] = useState(false);

  useEffect(() => {
    async function siteSettingDataFun() {
      try {
        const result = await postData("site-setting/details", {});
        if (result.data) {
          setSiteSettingData(result.data);
        } else {
          console.log("Site setting details message", result.message);
        }
      } catch (error) {
        console.log("Error site setting details catch", error.message);
      }
    }
    siteSettingDataFun();

    async function profileData() {
      try {
        const result = await postData("profile/details", {});
        if (result.data) {
          setProfileData(result.data);
        } else {
          console.log("Profile Details message", result.message);
        }
      } catch (error) {
        console.log("Error profile details catch", error.message);
      }
    }
    profileData();

    // async function roleList() {
    //   try {
    //     const result = await postData("role/list", {});
    //     if (result.data) {
    //       setRoles(result.data.list);
    //     } else {
    //       console.log("Role message", result.message);
    //     }
    //   } catch (error) {
    //     console.log("Error role catch", error.message);
    //   }
    // }
    // roleList();

    async function licenseData() {
      try {
        const result = await postData("license/details", {});
        if (result.data) {
          setLicenseGetData(result.data);
        } else {
          console.log("License Details message", result.message);
        }
      } catch (error) {
        console.log("Error license details catch", error.message);
      }
    }
    licenseData();

    async function licenseList() {
      try {
        const result = await postData("license/list", {});
        if (result.data) {
          setLicenseList(result.data);
        } else {
          console.log("License message", result.message);
        }
      } catch (error) {
        console.log("Error license catch", error.message);
      }
    }
    if(isLicense){
      licenseList();
    }else{
      licenseList();
    }
  }, [isLicense || '']);

  useEffect(() => {
    setId(siteSettingData?._id ? siteSettingData._id : "");
    setSiteTitle(siteSettingData?.site_title ? siteSettingData.site_title : "");
    setEmail(siteSettingData?.email ? siteSettingData.email : "");
    setPhone(siteSettingData?.phone ? siteSettingData.phone : "");
    setAddress(siteSettingData?.address ? siteSettingData.address : "");
    // setCompanyName(profileData?.company_name ? profileData.company_name : "");
    // setDesignation(profileData?.designation ? profileData.designation : "");
    // setSelectedRole(profileData?.role ? profileData.role : "");

    // setLicenseNumber(licenseGetData?.license_number ? licenseGetData.license_number : "");
    // setLicenseExpiryDate(licenseGetData?.license_expiry_date? licenseGetData.license_expiry_date: "");
    setShowLogo(API_URL+siteSettingData?.logo);
    setShowFavicon(API_URL+siteSettingData?.favicon);    
  }, [siteSettingData, profileData, licenseGetData]);

  // useEffect(() => {
  //   let temp = [];
  //   roles?.forEach((element) => {
  //     setRoleList([
  //       ...temp,
  //       { _id: element._id, label: element.name, value: element._id },
  //     ]);
  //     temp.push({ _id: element._id, label: element.name, value: element._id });
  //   });
  // }, [roles]);

  const onImgChange = (e) => {
    setIsLogoImage(true);
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
        setIsLogoImage(false);
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const onFaviconChange = (e) => {
    setIsFaviconImage(true);
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
        setIsFaviconImage(false);
      } else {
        var output = document.getElementById("image2");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setFavicon);
      }
    }
  };

  const onCancel = async (e) => {
    setIsCancel(true);
    setSiteTitle(siteSettingData?.site_title ? siteSettingData.site_title : "");
    setEmail(siteSettingData?.email ? siteSettingData.email : "");
    setPhone(siteSettingData?.phone ? siteSettingData.phone : "");
    setAddress(siteSettingData?.address ? siteSettingData.address : "");
    // setCompanyName(profileData?.company_name ? profileData.company_name : "");
    // setDesignation(profileData?.designation ? profileData.designation : "");
    // setSelectedRole(profileData?.role ? profileData.role : "");
    setIsCancel(false);
  };

  const onUpdate = async (e) => {
    setIsUpdate(true);
    try {
      let path='';
      let req={};
      if (id) {
        path = "site-setting/update";
        req = {
          id: id,
          site_title: siteTitle,
          email: email,
          phone: phone,
          address: address,
          // logo: image,
          // favicon: favicon,
          // company_name: companyName,
          // designation: designation,
          // role: selectedRole,
        };
      } else {
        path = "site-setting/create";
        req = {
          site_title: siteTitle,
          email: email,
          phone: phone,
          address: address,
          // logo: image,
          // favicon: favicon,
          // company_name: companyName,
          // designation: designation,
          // role: selectedRole,
        };
      }
      const result = await postData(path, req);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
        setSiteSettingData(result.data)
        //dispatch(authSettingsSetup(result.data));
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  const onLicenseCancel = async (e) => {
    setIsLicenseCancel(true);
    setLicenseId('');
    setLicenseNumber("");
    setLicenseExpiryDate("");
    setIsLicenseCancel(false);
  };

  const onLicenseUpdate = async (e) => {
    setIslicenseUpdate(true);
    try {
      let path='';
      let req={};
      if(licenseId){
        path = "license/update";
        req = {
          license_id: licenseId,
          license_number: licenseNumber,
          license_expiry_date: licenseExpiryDate,
        };
      }else{
        path = "license/create";
        req = {
          license_number: licenseNumber,
          license_expiry_date: licenseExpiryDate,
        };
      }      
      const result = await postData(path, req);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setLicenseNumber('');
        setLicenseExpiryDate('');
        setLicenseId('')
        setIslicenseUpdate(false);
        setIsLicense(true);
      } else {
        toastr.error(result.message);
        setIslicenseUpdate(false);
        setIsLicense(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIslicenseUpdate(false);
      setIsLicense(false);
    }
  };

  useEffect(() => {
    async function updateImage(){
      let req={};
      if(image){
        req={logo:image}
      }else if(favicon){
        req={favicon:favicon}
      }
      try {
        const result = await postData("site-setting/update-image", req);
        if (result.data) {
          setSiteSettingData(result.data)
          //dispatch(authSettingsSetup(result.data));
          setImage('');
          setFavicon('');
          setIsFaviconImage(false);
          setIsLogoImage(false);
        } else {
          console.log('image message',result.message)
          setIsLogoImage(false);
        }
      } catch (error) {
        console.log('Error profile image catch',error.message)
        setIsLogoImage(false);
      }
    }    
    if(image || favicon){
      updateImage();
    }
  }, [image,favicon]);

  const onEdit = async (id,license_number,license_date) => {
    setLicenseNumber(license_number);
    setLicenseExpiryDate(license_date);
    setLicenseId(id)
  }

 

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">{t('settings')}</div>
      </div>
      <div className="relative grid grid-cols-6 gap-4">
        <div className="col-span-1 space-y-4">
          <div className="relative bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden rounded-md">
                <Image
                  src={showLogo}
                  alt={"Admin"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                  id={"image1"}
                />
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={(isLogoImage)?'fa-light fa-spinner fa-spin':"fa-light fa-image"}
                buttonIconPosition={"left"}
                buttonLabel={t('change_logo')}
                buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"}
                functions={onImgChange}
                accepts={"image/*"}
              />
            </div>
          </div>

          <div className="relative bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden rounded-md">
                <Image
                  src={showFavicon}
                  alt={"Admin"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                  id={"image2"}
                />
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={(isFaviconImage)?'fa-light fa-spinner fa-spin':"fa-light fa-image"}
                buttonIconPosition={"left"}
                buttonLabel={t('change_favicon')}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"
                }
                functions={onFaviconChange}
                accepts={"image/*"}
              />
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">
                {t('site_settings')}
              </div>
              <div className="space-y-4 mt-4">
                <div className="relative">
                  <Input
                    label={t('company_name')}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={t('enter_site_title')}
                    inputValue={siteTitle}
                    setInput={setSiteTitle}
                  />
                </div>

                <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Input
                      label={t('email')}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={t('enter_email')}
                      inputValue={email}
                      setInput={setEmail}
                    />
                  </div>
                  <div className="relative w-full">
                    <Input
                      label={t('phone')}
                      labelClasses={"!text-xs"}
                      inputType={"number"}
                      inputPlaceholder={t('enter_phone_number')}
                      inputValue={phone}
                      setInput={setPhone}
                    />
                  </div>
                </div>
                <div className="relative">
                  <Textarea
                    label={t('address')}
                    labelClasses={"!text-xs"}
                    inputPlaceholder={t('enter_address')}
                    inputValue={address}
                    setTextarea={setAddress}
                  />
                </div>
              </div>
            </div>
            {/* <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">
                Company Information
              </div>
              <div className="space-y-4 mt-4">
                <div className="relative">
                  <Input
                    label={"Company Name"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter company name"}
                    inputValue={companyName}
                    setInput={setCompanyName}
                  />
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Input
                      label={"Designation"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter Designation"}
                      inputValue={designation}
                      setInput={setDesignation}
                    />
                  </div>
                  <div className="relative w-full">
                    <Select
                      label={"Role"}
                      labelClasses={"!text-xs"}
                      selectedValue={selectedRole}
                      xPlacement={"left"}
                      dropdownClass={"!w-full"}
                      dropdownData={[
                        { _id: "", label: "Select Role", value: "" },
                        ...roleList,
                      ]}
                      getSelectedValue={(e) => setSelectedRole(e._id)}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonType={"button"}
                  buttonIcon={(isCancel)? "fa-light fa-spinner fa-spin": "fa-light fa-times"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('cancel')}
                  buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                  functions={onCancel}
                  buttonHasLink={false}
                  buttonDisabled={isCancel}
                />
                <Button
                  buttonType={"button"}
                  buttonIcon={(isUpdate)? "fa-light fa-spinner fa-spin": "fa-light fa-check"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('save')}
                  functions={onUpdate}
                  buttonHasLink={false}
                  buttonDisabled={isUpdate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 space-y-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">
                {t('license_settings')}
              </div>
              <div className="space-y-4 mt-4">
                <div className="flex items-center w-full gap-4">
                  <div className="w-full">
                    <Input
                      inputType={"text"}
                      label={t('license_number')}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={t('enter_license_number')}
                      inputValue={licenseNumber}
                      setInput={setLicenseNumber}
                    />
                  </div>
                </div>
                <div className="flex items-center w-full gap-4">                  
                  <div className="w-full">
                    <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t('expiry_date')}</div>
                    <Flatpickr
                      ref={flatPicker}
                      type={"text"}
                      className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                      placeholder={"Expiry Date"}
                      value={licenseExpiryDate}
                      onChange={(selectedDates) => {
                        setLicenseExpiryDate(selectedDates[0]);
                      }}
                      options={{
                        minDate: "today",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonType={"button"}
                  buttonIcon={(isLicenseCancel)? "fa-light fa-spinner fa-spin": "fa-light fa-times"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('cancel')}
                  buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                  functions={onLicenseCancel}
                  buttonHasLink={false}
                  buttonDisabled={isLicenseCancel}
                />
                <Button
                  buttonType={"button"}
                  buttonIcon={(isLicenseUpdate)? "fa-light fa-spinner fa-spin": "fa-light fa-check"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('save')}
                  functions={onLicenseUpdate}
                  buttonHasLink={false}
                  buttonDisabled={isLicenseUpdate}
                />
              </div>
            </div>
          </div>
          {
            (licenseList && licenseList.length>0) && 
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">{t('license_list')}</div>
              <div className="space-y-4 mt-4">
                {
                licenseList?.map((item) => (
                  <div className="flex items-center w-full gap-4"key={item._id}>
                    <div className="w-2/3">
                      <div className="text-sm font-medium text-slate-500">                     
                        {item.license_number}
                      </div>
                    </div>
                    <div className="w-2/3">  
                    <div className="text-sm font-medium text-slate-500">  
                      {(item && item?.license_expiry_date)?moment(new Date(item?.license_expiry_date)).format('DD MMM YYYY'):''}  
                      </div>               
                      {/* <Flatpickr
                        ref={flatPicker}
                        type={"text"}
                        className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                        placeholder={"Expiry Date"}
                        value={item.license_expiry_date}
                        onChange={(selectedDates) => {
                          //handelUpdate(item?._id,selectedDates[0])
                          setLicenseExpiryDate(selectedDates[0])
                        }}
                        options={{
                          minDate: "today",
                        }}
                      /> */}
                    </div>
                    <div className="w-1/3">
                      <div className="text-sm font-medium text-slate-500">                     
                      <Button 
                        buttonIcon={(isEdit)?'fa-light fa-spinner fa-spin':'fa-light fa-edit'} 
                        buttonIconPosition={'left'} 
                        buttonClasses={'!h-6 !w-6 !min-w-[24px] !text-sm !p-0'} 
                        buttonType={'button'}
                        functions={(e) => onEdit(item?._id,item?.license_number,item?.license_expiry_date)}
                        buttonHasLink={false}
                        buttonDisabled={isEdit}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          }
          

          {/* <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">Social Settings</div>
              <div className="space-y-4 mt-4">
              {socialData.map((item) => (
                <div className="flex items-center w-full gap-4" key={item._id}>
                  <div className="w-1/3">
                    <div className="text-sm font-medium text-slate-500">{item.name}</div>
                  </div>
                  <div className="w-2/3">
                    <Input inputType={'text'} inputValue={item.value} />
                  </div>
                </div>
              ))}
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonType={'button'} buttonIcon={'fa-light fa-times'} buttonIconPosition={'left'} buttonLabel={'Cancel'} buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} />
                <Button buttonType={'button'} buttonIcon={'fa-light fa-check'} buttonIconPosition={'left'} buttonLabel={'Save'} />
              </div>
            </div>
          </div> */}

          {/* <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">Notification Settings</div>
              <div className="space-y-4 mt-4">
                <div className="flex justify-between">
                  <div className="mr-auto">
                    <div className="text-sm font-medium text-slate-600">Activity</div>
                    <div className="text-xs font-light text-slate-400">Lorem Ipsum is simply dummy text of the printing and typesetting</div>
                  </div>
                  <div className="ml-auto flex justify-center items-start">
                    <Switch checked={false}/>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="mr-auto">
                    <div className="text-sm font-medium text-slate-600">New user</div>
                    <div className="text-xs font-light text-slate-400">Lorem Ipsum is simply dummy text of the printing and typesetting</div>
                  </div>
                  <div className="ml-auto flex justify-center items-start">
                    <Switch checked={false}/>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="mr-auto">
                    <div className="text-sm font-medium text-slate-600">Site Update</div>
                    <div className="text-xs font-light text-slate-400">Lorem Ipsum is simply dummy text of the printing and typesetting</div>
                  </div>
                  <div className="ml-auto flex justify-center items-start">
                    <Switch checked={false}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonType={'button'} buttonIcon={'fa-light fa-times'} buttonIconPosition={'left'} buttonLabel={'Cancel'} buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} />
                <Button buttonType={'button'} buttonIcon={'fa-light fa-check'} buttonIconPosition={'left'} buttonLabel={'Save'} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Settings;
