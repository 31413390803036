import React from 'react';
import PageBanner from "../components/section/PageBanner";
import Testimonial from "../components/section/Shared/Testimonial";
import DemoContent from "../components/section/Demo2/DemoContent";
import AboutService from "../components/section/Aboutus/AboutService";

const Demo2 = () => {
  return (
    <>
      {/* <PageBanner sectionClass="!h-40"/> */}
      <DemoContent/>
      <AboutService
      headingOne={true} 
      headingTwo={true} 
      headingThree={true}
      />
      <Testimonial/>
    </>
  );
};

export default Demo2;