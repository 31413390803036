import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const SubscriptionReport = ({records, header, user}) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    subText: {
      fontSize: 8,
      marginTop: 2,
    },
    tableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableText: {
      fontSize: 7,
    },
    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 8,
      lineHeight: 1,
      whiteSpace: "nowrap",
      textAlign:"center"
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Section #1</Text>
        </View>        
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
              // src='https://successionnow.iosx.in/logo-succession-now.png'
            />
          </View>
          
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16, textTransform: "capitalize" }]}>
              {t('subscription_report')}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {records &&  records.length > 0 &&
          <View style={[ { marginTop: 10, marginBottom: 10 }]}>            
            <View style={{ border: "1px solid #e2e8f0", borderRadius: 4, overflow: "hidden" }}>
              <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              {
                header.length>0 && header.map((item,index) =>(
                  <View style={[styles.tableHeading, {}]} key={index}>
                    <Text style={[styles.tableHeadingText, {}]}>{item.name}</Text>
                  </View>
                ))
              }
              </View>
              <View style={[{borderTop: "1px solid #e2e8f0",  flexDirection: "column", width:"100%",}]}>
                {
                  records.map((item,index) => {
                    let noOfSuccessionPlan = item?.individual_details.filter((it) => it.conclusion_details.length>0);   
                    return (
                    <View style={[{flexDirection: "row", width:"100%",}]} key={index}>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{item?.company_name}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{item?.name}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{item?.number_of_employees}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{noOfSuccessionPlan.length}</Text>
                      </View> 
                      {/* <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item && item?.created_at)?moment(new Date(item?.created_at)).tz('America/Denver').format('MMM Do YYYY'):'N/A'}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{'$'+item?.original_price.toFixed(2)}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item && item?.end_date)?moment(new Date(item?.end_date)).tz('America/Denver').format('MMM Do YYYY'):'N/A'}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item && item.renewal_price !== undefined)?'$'+Number(item.renewal_price).toFixed(2):'N/A'}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item && item?.cancel_date)?moment(new Date(item?.cancel_date)).tz('America/Denver').format('MMM Do YYYY'):'N/A'}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.status && item?.status === 1)?'Active':(item?.status && item?.status === 2)?t('canceled'):t('pending')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.status && item?.status === 1)?t('paid'):(item?.status && item?.status === 2)?t('cancel'):t('unpaid')}</Text>
                      </View>  */}
                    </View>
                    )
                  })
                }
                {/* {records.length>0 && 
                  <View style={[ { display: "flex", flexDirection: "row" }]}>
                    {records.map((item,index) =>(
                      <>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                        {item?.company_name}
                        </Text>
                      </View>                      
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                          {item?.name}
                        </Text> 
                      </View>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                          {item?.number_of_employees}
                        </Text>
                      </View>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                          {item?.number_of_employees}
                        </Text>
                      </View>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                          --
                        </Text>
                      </View>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}} key={ index}>
                        <Text key={index} style={{ fontSize: 8,  width: "100%", marginTop: 2 }}>
                          {(item && item?.created_at)?moment(new Date(item?.created_at)).tz('America/Denver').format('MMM Do YYYY'):'N/A'}
                        </Text>
                      </View>
                      </>
                    ))}
                  </View>
                } */}
                
                    
                    {/* <View style={[{flexDirection: "row", width:"100%",}]}>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                    </View>
                    <View style={[{flexDirection: "row", width:"100%",}]}>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>Advanced Restoration</Text>
                      </View> 
                    </View> */}
              </View>              
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

export default SubscriptionReport;