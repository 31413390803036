import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Select from '../form/Select';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useTranslation } from "react-i18next";

const ResourceEnquiryPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => { 
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const closeModal = () =>{ setIsOpen(false) }   

  const handelSubmit = async () => {
    setLoading(true);
    try {
      const result = await postData("/enquery/create", {
        type:3,
        full_name: fullName,
        email: email,
        phone: phone,
        leadPath:'article'
      });
      if (result.status === 200) {
        setLoading(false);
        setEmail("");
        setPhone("");
        setFullName("");
        toastr.success(result.message)
      } else {
        setLoading(false);
        toastr.error(result.message)
      }
    } catch (e) {
      setLoading(false);
      toastr.error(e.message)
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    {/* <Dialog.Title 
                    as="h3" 
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >View the Demo</Dialog.Title> */}
                    <div className="absolute top-2 right-2 z-10">
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={''} 
                        buttonClasses={'!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    <div className="relative space-y-10">
                      <div className="space-y-4">
                        <h2 className="text-3xl xl:text-4xl font-bold text-dark-teal-400 tracking-tight">{t('watch_the_demo')}</h2>
                        <h4 className="text-lg text-black leading-tight">See why SuccessionNow is the #1 succession planning software.</h4>
                      </div>
                      <div className="relative space-y-5">                  
                        <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"text"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-slate-50"
                            }
                            inputPlaceholder={t('full_name')}
                            inputValue={fullName}
                            inputName={""}
                            errorType={""}
                            isDisabled={false}
                            setInput={setFullName}
                          />
                        </div>
                        <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"email"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-slate-50"
                            }
                            inputPlaceholder={t('work_email')}
                            inputValue={email}
                            inputName={""}
                            errorType={""}
                            setInput={setEmail}
                          />
                        </div>
                        {/* <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"text"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-slate-50"
                            }
                            inputPlaceholder={"Phone Number"}
                            inputValue={phone}
                            inputName={""}
                            errorType={""}
                            setInput={setPhone}
                          />
                        </div> */}
                        <div className="relative md:col-span-2">
                          <div className="flex justify-center mt-8">
                            <Button
                              buttonClasses="!bg-dark-teal-700 hover:!bg-dark-teal-900 !px-6 !w-full !h-14 !text-xl rounded-lg"
                              buttonLabelClasses="!text-xl"
                              buttonType={"button"}
                              buttonLabel={t('submit')}
                              buttonIcon={
                                loading
                                  ? "fa-light fa-spinner fa-spin"
                                  : ""
                              }
                              buttonIconPosition={"left"}
                              functions={handelSubmit}
                              buttonHasLink={false}
                              buttonDisabled={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default ResourceEnquiryPopup;
