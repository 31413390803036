import React,{useEffect,useState} from 'react';
import { postData } from '../../../services/api';

const AboutService = ({
  headingOne,
  headingTwo,
  headingThree
}) => {
  const [loading,setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [intro3, setIntro3] = useState({});
  const [intro2, setIntro2] = useState({});

  useEffect(() => {
    setLoading(true);
    const loadPageDetails = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'intro2'
        });
        if (result.data) {
          setPageData(result.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails();

    const loadPageDetails3 = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'intro3'
        });
        if (result.data) {
          setIntro3(result.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails3();

    const loadPageDetails2 = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'request-demo'
        });
        if (result.data) {
          setIntro2(result.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails2();
  }, []);

  

  return (
    <section className='relative w-full bg-white pb-20 py-10 xl:py-16'>
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="flex justify-center flex-wrap lg:flex-nowrap gap-10 lg:gap-5 xl:gap-8 2xl:gap-20">            
            <div className="w-full">
              <div className="relative space-y-4 lg:space-y-10">
                <h6 className="mb-3 lg:mb-6 block text-xl xl:text-2xl font-bold text-dark-teal-400 tracking-tight">
                {(headingOne)?'What Industry Leaders Say':''}
                </h6>               
                <ul className="pl-8 space-y-5">
                  {
                    (pageData?.content && pageData?.content.length>0) && 
                      pageData?.content.map((item,index) => (
                      <li key={index} className="relative font-semibold text-black border border-slate-200 p-3 bg-white rounded-xl before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:top-2 before:-left-8">
                        <span className="flex leading-tight text-sm xl:text-base">{item?.title}</span> 
                        <span className="flex justify-end text-xs text-slate-400 font-normal mt-1">- {item?.creator}</span>
                      </li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
            <div className="w-full">
              <div className="relative space-y-4 lg:space-y-10">
                <h2 className="mb-3 lg:mb-6 block text-xl xl:text-2xl font-bold text-dark-teal-400 tracking-tight">
                {(headingTwo)?'Benefits of Succession Planning':''}
                </h2>   
                <ul className="pl-8 grid grid-cols-2 gap-y-5 gap-x-10">
                  {
                    (intro3?.content && intro3?.content.length>0) && 
                    intro3?.content.map((item,index) => (
                      <li key={index} className="relative font-semibold text-sm xl:text-base text-black leading-tight before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:-top-[3px] before:-left-8">{item?.title}</li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
            <div className="w-full">
              <div className="relative space-y-4 lg:space-y-10">
                <h2 className="mb-3 lg:mb-6 block text-xl xl:text-2xl font-bold text-dark-teal-400 tracking-tight">
                {(headingThree)?'What the Numbers Say':''}
                </h2>   
                <ul className="pl-8 space-y-5">
                  {
                    (intro2?.content && intro2?.content.length>0) && 
                    intro2?.content.map((item,index) => (
                      <li key={index} className="relative font-semibold text-black border border-slate-200 p-3 bg-white rounded-xl before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:top-2 before:-left-8">
                        <span className="flex leading-tight text-sm xl:text-base">{item?.title}</span> 
                        <span className="flex justify-end text-xs text-slate-400 font-normal mt-1">- {item?.creator}</span>
                      </li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default AboutService;