import React,{useState,useEffect} from 'react';
import {setTitle} from '../helpers/MetaTag';

import { postData } from "../services/api";
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import Button from '../components/form/Button';

import { useTranslation } from "react-i18next";

const Instructions = () => {
  setTitle('Succession Now | Succession Instructions');
  const [ instructionData,setInstructionData ] = useState({});
  const [loading,setLoading] = useState(false);
  const role =  localStorage.getItem("role");
  const { i18n,t } = useTranslation();

  useEffect(() => {
    async function loadInstructionData() {
      setLoading(true);
      try {
        const result = await postData("instruction/details", {
            alias:'instruction'
        });
        if (result.data) {
          setInstructionData(result.data);
          setLoading(false);
        } else {
          console.log('Instruction Details message',result.message)
          setLoading(false);
        }
      } catch (error) {
        console.log('Error instruction details catch',error.message)
        setLoading(false);
      }
    }
    loadInstructionData();
  }, []); 

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-2xl text-slate-800 font-bold">{t('welcome_to_succession_now_the_premier_succession_planning_software')}</div>
        {
          (role && role === 'master-admin') && 
          <div className="ml-auto flex justify-end items-center">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-edit"}
              buttonIconPosition={"left"}
              buttonLabel={t('manage_instruction')}
              buttonHasLink={true}
              buttonLink={"/manage-instruction"}
            />
          </div>
        }
        
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="py-8 px-4">
          {
            (instructionData && Object.keys(instructionData).length>0) ?(
              <div 
              className="text-sm text-slate-600 space-y-4" 
              dangerouslySetInnerHTML={{__html:instructionData?.description}}>                
              </div>
            ):((loading)?<Searching label={t('searching')}/>:<NoDataFound label={t('no_data_found')}/>)
          }
        </div>
      </div>
    </>
  );
};

export default Instructions;