import React, { useState, useEffect,Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import Button from "../form/Button";

import { useTranslation } from "react-i18next";

const AlertPopup = ({
    isOpen,
    setIsOpen = () => {},
    data,
    getActionValue = () => {}, 
    message,
}) => {
    const { i18n,t } = useTranslation();
    const [loading,setLoading] = useState(false);
    const closeModal = () =>{ setIsOpen(false) } 

    const onSubmit = () => {
        setLoading(true);
        getActionValue({
            button_type : 'confirm',
            row_id : (data)?data?.id:'',
            current_status : '',
            data : data
        })
    }

    return (
        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-3xl font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('alert')}
                    </Dialog.Title>
                    <div className="py-8 px-4 text-center">
                    {
                        (message)?
                        <p>{message}</p>:
                        <p>{t('reminder_input_all_necessary_information')}</p>
                    }
                    </div>
                    <div className="flex items-center justify-center gap-4 mt-2">
                        <Button
                        buttonClasses='!bg-green-600'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('confirm')}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        </>
    )
};
export default AlertPopup;