import React from 'react';
import Button from "../form/Button";

const PricingPlanItem = ({ data }) => {
  return (
    <div className="relative shadow-sm bg-white border-2 border-[#F4F7FA] px-5 xs:px-7 pt-5 lg:pt-10 pb-7 lg:pb-14 rounded-lg">
      <div className="flex flex-col space-y-3 xs:space-y-5 xl:space-y-8 relative max-w-[340px] w-full mx-auto">
        {data.title && (
          <h4 className="font-PlusJakartaSans font-bold text-base text-dark-teal-300 uppercase tracking-widest text-center">
            {data.title}
          </h4>
        )}
        {data.price && (
          <div className="text-slate-950 text-xs lg:text-sm xl:text-base font-medium font-PlusJakartaSans capitalize text-center">
            <span className="text-lg xl:text-xl 2xl:text-2xl font-bold">$</span>
            <span className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold">
              {data.price}
            </span>
            /{data.recurring}
          </div>
        )}
        {data.talk && (
          <div className="text-[#20A17F] text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold font-PlusJakartaSans capitalize">
            {data.talk}
          </div>
        )}
        {data.description && (
          <div className="text-gray-400 text-sm font-PlusJakartaSans tracking-tight">
            {data.description}
          </div>
        )}

        <div className="relative max-w-[200px] m-auto">
          <Button
            buttonLabel={data.btnname}
            buttonTarget="_blank"
            buttonClasses={data.buttonClasses}
            buttonLabelClasses="capitalize lg:!text-base xl:!text-lg tracking-wider !font-bold !font-PlusJakartaSans"
            buttonHasLink={true}
            buttonLink={data.link}
          />
        </div>

        {Array.isArray(data.features) && data.features.length > 0 && (
          <ul className="text-center space-y-1 md:space-y-3 max-w-[200px] m-auto">
            {data.features.map((item) => (
              <li className="uppercase text-sm text-black font-bold font-PlusJakartaSans" key={item._id}>{item.name}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PricingPlanItem;
