import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import Dropdown from "../../components/form/Dropdown";
import ManageNewslaterPopup from "../../components/popup/ManageNewslaterPopup";
import { useTranslation } from "react-i18next";

const FreeAuthHeader = ({ settings }) => { 
  const isLogin = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");
  const { i18n,t } = useTranslation();
  const [enabledNewslatterPopup, setEnabledNewslatterPopup] = useState(false);
  const [actionValue,setActionValue] = useState({});

  const [isMenu, setIsMenu] = useState(false);
  const handleMenuToggle = () => {
    setIsMenu(!isMenu);
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);   

  const menu = [
    { _id: 1, name: t('about'), link:"/about-us" },
    { _id: 2, name: t('how_it_works'), sectionId:"successionbuild-section" },
    { _id: 3, name: t('faq'), sectionId:"faq-section" },
    // { _id: 3, name: "Reviews", sectionId:"testimonial-section" },
    { _id: 4, name: t('pricing'), sectionId:"pricingPlan-section"},
    // settings && settings.enabled_resource ? 
    // { _id: 5, name: "Resources", link:"/resources"}: '',
    { _id: 6, name: t('clients'), link:"/our-clients"},
  ]

  const handleSelect = (item) => {
    console.log('Selected item:', item);
  };

  const dropdownData = [
    { _id: 1, name: 'Article', link: '/#' },
    { _id: 2, name: 'Case Studeis', link: '/#' }
  ];

  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");      
      const scrollPosition = window.scrollY;      
      sections.forEach(section => {
        const sectionTop = section.offsetTop - 50; 
        const sectionHeight = section.sectionTop;
        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(section.id);
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = id => {
    const section = document.getElementById(id);    
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 160,
        behavior: 'smooth'
      });
    } else {
      window.location.href = `/#${id}`;       
    }
  };

window.addEventListener('load', () => {
  const hash = window.location.hash.substring(1);
  if (hash) {
      const section = document.getElementById(hash);
      if (section) {
          window.scrollTo({
              top: section.offsetTop - 160,
              behavior: 'smooth'
          });
          // Remove the hash from the URL after scrolling
          window.history.replaceState({}, document.title, window.location.pathname);
      }
  }
});

/*---------------start new code---------------*/
  return (
    <>      
      <header id="my-header" className={
          "sticky top-0 left-0 w-full  z-50 drop-shadow transition-all duration-200 " +
          (scroll ? " bg-white" : "bg-transparent")
        }
      >
        {/* <div className={
          "relative h-10 w-full bg-dark-teal-500 z-50 "
        }></div> */}
        <div className="w-full lg:max-w-5xl xl:max-w-7xl 2xl:max-w-8xl mx-auto flex justify-between items-center px-5 py-10">
          <div className="h-7 relative">
            <Link
              to="/"
              className="block w-full h-full cursor-pointer transition-all duration-200"
              >
              <Image
                src={"../../logo-succession-now.svg"}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain object-left"}
                id={"header-logo"}
              />
            </Link>
          </div>
          
          <div className={"fixed lg:relative top-0 lg:top-auto left-0 lg:left-auto w-[300px] lg:w-auto h-screen lg:h-auto p-5 lg:p-0 bg-white lg:bg-transparent mx-auto flex -translate-x-full lg:translate-x-0 transition-all duration-200 lg:items-center flex-col lg:flex-row gap-y-8 lg:gap-x-8 lg:gap-y-0 z-50" +
              (isMenu ? " !-translate-x-0" : "")
            }
          >
            {menu.map.length > 0 && (
              <div className="relative"  >
                <ul className="lg:flex items-center gap-x-4">
                  {menu.map((item) => (
                    <li key={item?._id}>
                      {
                        item.sectionId ?
                        <NavLink
                          to={`//#${item.sectionId}`}
                          className={
                            "block text-lg lg:text-base font-semibold cursor-pointer py-2 lg:px-0 lg:py-0 xl:px-4 transition-all duration-200 lg:text-black scroll-link"}
                          onClick={() => {
                            handleMenuToggle();
                            scrollToSection(item.sectionId);
                          }}
                        >
                          {item.name}
                        </NavLink>:
                        <NavLink
                        to={item.link}
                        className={
                          "block text-lg lg:text-base font-semibold cursor-pointer py-2 lg:px-0 lg:py-0 xl:px-4 transition-all duration-200 lg:text-black scroll-link"}
                      >
                        {item.name}
                      </NavLink>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            )}
           
           <div className="relative space-y-2 lg:space-y-0 block lg:flex  lg:flex-nowrap gap-2 lg:gap-4">
              {/* <div className="relative ">
                  <Dropdown
                      data={dropdownData}
                      buttonLabel="resources"
                      buttonLabelClass="border-none rounded font-bold font-PlusJakartaSans text-sm !text-white capitalize "
                      buttonClass="bg-dark-teal-500 hover:bg-dark-teal-700   rounded border-0 w-full"
                      handleSelect={handleSelect}
                  />
              </div> */}
              {isLogin ? (
              <NavLink to={ role === "master-admin"? "/dashboard-statistics": "/profiles"} className="flex justify-center items-center gap-2 bg-[#F4F7FA] hover:bg-dark-teal-200 text-dark-teal-500 rounded text-sm font-bold h-10  px-4 transition-all duration-200 cursor-pointer capitalize" duration={500}>
                  {t('my_account')}
              </NavLink>
              ) : (
              <NavLink to={"/signin"} className="flex justify-center items-center gap-2 bg-[#F4F7FA] hover:bg-dark-teal-200 text-dark-teal-500 rounded text-sm font-bold h-10  px-4 transition-all duration-200 cursor-pointer capitalize" duration={500}>
                  {t('login')}
              </NavLink>
              )}
              <a target="_blank" href="https://calendly.com/successionnow/30min?utm_source=website" className="flex justify-center items-center gap-2 bg-dark-teal-500 hover:bg-dark-teal-700 text-white rounded text-sm font-bold h-10 px-4 transition-all duration-200 cursor-pointer capitalize">
                    {('Schedule Your Demo')}
              </a>
              {/* <Button
                buttonClasses={"!bg-dark-teal-500 !hover:bg-dark-teal-700 !text-white"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-paper-plane"}
                buttonIconPosition={"left"}
                buttonLabel={t('newsletter')}
                buttonLabelClasses={""}
                buttonHasLink={false}
                functions={() => setEnabledNewslatterPopup(true)}
              /> */}
              
          </div>
          </div>
          <div className="block lg:hidden">
                  <Button
                    buttonClasses={
                      "!bg-transparent !w-10 !p-0 !text-black !text-3xl" +
                      (scroll ? " !text-slate-600" : "")
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-regular fa-bars"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonLabelClasses={""}
                    buttonHasLink={false}
                    functions={handleMenuToggle}
                  />
              </div>
          
        </div>
        <div
          className={
            "fixed top-0 left-0 z-[-1] bg-slate-900/50 w-full h-screen block lg:hidden" +
            (isMenu ? " opacity-100 visible" : " opacity-0 invisible")
          }
          onClick={handleMenuToggle}
        ></div>
      </header>   
      {          
          enabledNewslatterPopup &&
          <ManageNewslaterPopup
            isOpen={enabledNewslatterPopup}
            setIsOpen={(val) => setEnabledNewslatterPopup(val)}
            data={''}
            getActionValue={(obj) => {
              setActionValue(obj)  
              setEnabledNewslatterPopup(false);
              //setCompanyInfo({})   
            }}
          />
        }   
    </>
  );
};

export default FreeAuthHeader;
