import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const BoardMemberReport = ({ boardMembers, user }) => {
  const heading = [
    {
      name : "Years on the Board"
    },
    {
      name : "Board Retirement Window"
    },
    {
      name : "Current Board Position Held"
    },
    {
      name :"Previous Board Position Held 1"
    },
    {
      name :"Previous Board Position Held 2"
    },
    {
      name :"Previous Board Position Held 3"
    },
    {
      name :"Education"
    },
    {
      name :"Field of Study"
    },
    {
      name :"Industry Experience"
    },
    {
      name :"Primary Area of Expertise"
    },
    {
      name :"Primary Years of Experience"
    },
    {
      name :"Secondary Area of Expertise"
    },
    {
      name :"Secondary Years of Experience"
    },
    {
      name :"Management Level"
    },
    {
      name :"Years of Management Experience"
    }
  ]

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>Board Members</Text>
          </View>
          {/* <View style={[ { display: "flex", flexDirection:'row', marginTop: 5, textAlign: "center", justifyContent: "center", }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center",marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {user?.name}({user?.title})
              </Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View> */}
        </View>
        {boardMembers && boardMembers.length > 0 &&
        <>  
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              <Text style={[styles.tableHeading, { fontFamily: 'RobotoMedium', fontSize: 10 }]}>&nbsp;</Text>
              {boardMembers.map((item,index) => (
                <View key={index} style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{item.first_name+' '+item?.last_name}</Text>
                  {/* <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details? item?.position_details[0]?.short_name: "N/A"}</Text> */}
                </View>
              ))}
            </View> 
            { heading.map((items, index) => (
              <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                </View>

                {boardMembers.map((item,index2) => {
                return (
                  <View key={index+'_'+index2} style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                    {index === 0 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.board_year_details)?item?.board_year_details?.name:"N/A"}                      
                      </Text>
                    }
                    {index === 1 &&
                      <Text style={{ fontSize: 9 }}>
                      {(item?.retirement_window_details)? item?.retirement_window_details?.name : "N/A"}
                      </Text>
                    }
                    {index === 2 &&
                      <Text style={{ fontSize: 9 }}>
                      {(item?.position_details)?item?.position_details?.name : "N/A"}
                      </Text>
                    }
                    {index === 3 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.previous_position_details)? item?.previous_position_details.name: "N/A"}
                      </Text>
                    }
                    {index === 4 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.previous_position_two)? item?.previous_position_two.name: "N/A"}
                      </Text>
                    }
                    {index === 5 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.previous_position_three)? item?.previous_position_three.name: "N/A"}
                      </Text>
                    }
                    {index === 6 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.education_details)? item?.education_details?.name : "N/A"}
                      </Text>
                    }
                    {index === 7 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.study_details)? item?.study_details?.name:"N/A"}
                      </Text>                      
                    }
                    {index === 8 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.industry_details)? item?.industry_details?.name: "N/A"}
                      </Text>
                    }
                    {index === 9 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.primary_expertise_details) ? item?.primary_expertise_details?.name : ""}
                      </Text>
                    }
                    {index === 10 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.primary_experience)? (item?.primary_experience > 1)?item?.primary_experience+' Years':item?.primary_experience+' Year': ""}
                      </Text>
                    }
                    {index === 11 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.secondary_expertise_details) ? item?.secondary_expertise_details?.name : ""}
                      </Text>
                    }
                    {index === 12 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.secondary_experience)? (item?.secondary_experience > 1)?item?.secondary_experience+' Years':item?.secondary_experience+' Year': ""}
                      </Text>
                    }
                    {index === 13 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.management_level_details) ? item?.management_level_details?.name : ""}
                      </Text>
                    }
                    {index === 14 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.management_experience_details) ? item?.management_experience_details?.name : ""}
                      </Text>
                    }
                  </View>
                  )
                }
                )}

              </View>
            )) }

          </View>        
        </>
        }
      </Page>
    </Document>
  );
};

export default BoardMemberReport;