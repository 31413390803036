import React, { useEffect, useState } from "react";
import { postData } from "../../../services/api";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Textarea from "../../form/Textarea";
import toastr from "toastr";
import { useTranslation } from "react-i18next";


const RequestDemoContent = () => {
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [pageData, setPageData] = useState({});
  const [sitesetting,setSitesetting] = useState({});

  useEffect(()=>{
    const siteDetails=async()=>{
      try{
        const result = await postData("setting/details",{});
        if(result.data){
          setSitesetting(result.data)
        }

      }
      catch(err){
        console.log(err.message)
      }

    }
    siteDetails();
  },[])

  useEffect(() => {
    const loadPageDetails = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:"request-demo"
        });
        if (result.data) {
          setPageData(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails();
  }, []);

  const handelSubmit = async () => {
    setLoading(true);
    try {
      const result = await postData("/enquery/create", {
        type:3,
        full_name: fullName,
        email: email,
        phone: phone,
        leadPath : 'request-demo',
        //company: company,
        //message: message

      });
      if (result.status === 200) {
        setLoading(false);
        setEmail("");
        setPhone("");
        //setCompany("");
        //setMessage("");
        setFullName("");
        toastr.success(result.message)
      } else {
        setLoading(false);
        toastr.error(result.message)
      }
    } catch (e) {
      setLoading(false);
      toastr.error(e.message)
    }
  };

  return (
    <>
      <section className="relative w-full bg-white py-20 lg:py-24 xl:p-32">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="flex justify-center flex-wrap lg:flex-nowrap gap-10 xl:gap-20">
            <div className="w-full lg:w-1/2 xl:w-5/12">
              <div className="relative max-w-md lg:max-w-none mx-auto p-10 bg-gradient-to-r from-dark-teal-200 to-dark-teal-400 rounded-2xl overflow-hidden">
                <div className="relative">
                  <h2 className="mb-6 flex flex-col items-start">
                    <span className="inline-block xl:text-4xl text-3xl font-bold text-teal-900 border-b-4 border-teal-200 pb-4 mb-4">Schedule A Demo</span>
                    <span className="inline-block text-base text-white font-light">{sitesetting?.demo_request_sub_title?.[i18n?.language]}</span>
                  </h2>
                </div> 
                <div className="relative mt-10 grid gap-4">
                  <div className="relative">
                    <Input
                      labelClasses={"!text-white font-light"}
                      inputType={"text"}
                      inputClasses={
                        "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                      }
                      inputPlaceholder={"Enter your full name"}
                      inputValue={fullName}
                      inputName={""}
                      errorType={""}
                      isDisabled={false}
                      setInput={setFullName}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      labelClasses={"!text-white font-light"}
                      inputType={"email"}
                      inputClasses={
                        "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                      }
                      inputPlaceholder={"Enter your work email"}
                      inputValue={email}
                      inputName={""}
                      errorType={""}
                      setInput={setEmail}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      labelClasses={"!text-white font-light"}
                      inputType={"text"}
                      inputClasses={
                        "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                      }
                      inputPlaceholder={"Enter your phone number"}
                      inputValue={phone}
                      inputName={""}
                      errorType={""}
                      setInput={setPhone}
                    />
                  </div>
                  {/* <div className="relative">
                    <Input
                      labelClasses={"!text-white font-light"}
                      inputType={"company"}
                      inputClasses={
                        "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                      }
                      inputPlaceholder={"Enter your Company Name"}
                      inputValue={company}
                      inputName={""}
                      errorType={""}
                      setInput={setCompany}
                    />
                  </div> */}
                  <div className="relative">
                    <div className="flex justify-center mt-8">
                      <Button
                        buttonClasses="!bg-dark-teal-700 hover:!bg-dark-teal-900 !px-6 !w-full !h-14 !text-xl rounded-lg"
                        buttonLabelClasses="!text-xl"
                        buttonType={"button"}
                        buttonLabel={"Get a Demo"}
                        buttonIcon={
                          loading
                              ? "fa-light fa-spinner fa-spin"
                              : ""
                          }
                        buttonIconPosition={"left"}
                        functions={handelSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-5/12">
              <div className="relative space-y-10">
                <h2 className="mb-6 block text-3xl xl:text-5xl 2xl:text-6xl font-bold text-dark-teal-400 tracking-tight">
                  {(pageData?.description)?pageData?.description:''}
                </h2>
                <ul className="pl-8 space-y-5">
                  {
                    (pageData?.content && pageData?.content.length>0) && 
                    pageData?.content.map((item,index) => (
                      <li key={index} className="relative font-semibold text-black border border-slate-200 p-3 bg-white rounded-xl before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:top-2 before:-left-8">
                      <span className="flex leading-tight text-sm xl:text-base">{item?.title}</span> 
                      <span className="flex justify-end text-xs text-slate-400 font-normal mt-1">- {item?.creator}</span>
                      </li> 
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestDemoContent;