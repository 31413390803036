import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postData } from "../../../services/api";
import BannerSkeleton from "../../loader/BannerSkeleton";
import Image from "../../elements/Image";
import { API_URL } from "../../../config/host";

const Help = () => {
  const [helpData, setHelpData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
    const helpList = async () => {
      setLoading(true);
      try {
        const result = await postData("how_can_we_help/list", {
          status: 1,
        });
        if (result.data) {
          setHelpData(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    helpList();
  }, []);
 
  return (
    <>
      <section className="relative w-full bg-slate-50 py-10 lg:p-32 xl:p-32">
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative">
            <h2 className="mb-6">
              <span className="block xl:text-5xl text-3xl font-bold text-slate-700">
                Succession Now
              </span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">
                How can we help you?
              </span>
            </h2>
          </div>

          {loading ? (
            <BannerSkeleton />
          ) : (
            <>
              {Array.isArray(helpData) && helpData.length > 0 && (
                <div className="grid lg:grid-cols-2 xl:grid-cols-2 gap-5">
                  <div className="grid gap-5">
                    {Array.isArray(helpData) &&
                      helpData.length > 0 &&
                      helpData.slice(0, 3).map((item) => (
                        <Link
                          to={item?.link}
                          key={item?._id}
                          className="relative rounded-xl flex flex-col lg:h-32 xl:h-32 h-40 justify-center px-5 text-white bg-amber-500"
                        >
                          <h2 className="xl:text-3xl text-2xl font-boldd">{item?.title}</h2>
                          <div className="text-xl font-thin">
                            {item?.description}
                          </div>
                        </Link>
                      ))}
                  </div>

                  <div className="grid lg:grid-cols-2 xl:grid-cols-2 gap-5">
                    {Array.isArray(helpData) &&
                      helpData.length > 0 &&
                      helpData.slice(3, 5).map((item) => (
                        <Link
                          to={item?.link}
                          key={item?._id}
                          className="relative rounded-xl overflow-hidden flex flex-col justify-end p-5 z-0 group"
                        >
                          <div className="absolute top-0 left-0 w-full h-full -z-[1]">
                            <Image 
                              src={API_URL+item?.image} 
                              alt={''} 
                              width={'100%'}
                              height={'100%'} 
                              effect={'blur'} 
                              classes={'object-cover'}
                              id={""}
                            />
                          </div>
                          <h2 className="text-xl font-bold text-white drop-shadow">
                            {item?.title}
                          </h2>
                          <div className="absolute w-full h-full top-0 left-0 bg-emerald-500 p-5 flex flex-col justify-end gap-3 text-white opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                            <h2 className="text-lg font-semibold">
                              {item?.title}
                            </h2>
                            <div className="text-sm font-light">
                              {item?.description}
                            </div>
                            <div className="text-xs uppercase font-medium opacity-80">
                              Thinking
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                  {Array.isArray(helpData) &&
                    helpData.length > 0 &&
                    helpData.slice(5, 6).map((item) => (
                      <Link
                        to={item?.link}
                        key={item?._id}
                        className="relative rounded-xl overflow-hidden flex flex-col justify-end p-5 z-0 group h-80"
                      >
                        <div className="absolute top-0 left-0 w-full h-full -z-[1]">
                          <Image 
                            src={API_URL+item?.image} 
                            alt={''} 
                            width={'100%'}
                            height={'100%'} 
                            effect={'blur'} 
                            classes={'object-cover'}
                            id={""}
                          />
                        </div>
                        <h2 className="text-xl font-bold text-white drop-shadow">
                          {item?.title}
                        </h2>
                        <div className="absolute w-full h-full top-0 left-0 bg-orange-500 p-5 flex flex-col justify-end gap-3 text-white opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                          <h2 className="text-lg font-semibold">
                            {item?.title}
                          </h2>
                          <div className="text-sm font-light">
                            {item?.description}
                          </div>
                        </div>
                      </Link>
                    ))}

                  <Link
                    to="/"
                    className="relative rounded-xl overflow-hidden flex flex-col justify-between p-5 z-0 h-80 bg-pink-500"
                  >
                    <h2 className="text-3xl font-bold text-white drop-shadow">
                      What we do
                    </h2>
                    <div className="flex justify-end text-4xl text-white">
                      <i className="fa-light fa-fw fa-arrow-right-long-to-line"></i>
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Help;
