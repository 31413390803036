import React, { useState, useEffect } from 'react';
import { setTitle } from '../helpers/MetaTag';
import DropdownCheckbox from '../components/form/DropdownCheckbox';
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RetirementReport from './pdf/RetirementReport';
import { useSelector } from 'react-redux';

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const IndividualRetirementReport = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Retirement Report");
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user)
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { 'ordering': 1 },
          type: 1,
          status: 1,
          isStep : 1,
          //isReport : 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name+' ('+value?.short_name+')',
                isEnabled: true
              }
            })
          )
          //setPositions(result.data);
        } else {
          console.log('position list message', result.message)
        }
      } catch (error) {
        console.log('Error position list catch', error.message)
      }
    }
    positionData();
  }, []);

  const positionSelect = async (id, checked) => { 
    if(id && id === 'all'){
      setPositions(positions.map((pos) => {
        if (checked) {
          return { ...pos, isEnabled: checked }
        } else {
          return { ...pos, isEnabled: false }
        }
      }))
    }else{
      setPositions(positions.map((pos) => {
        if (pos._id === id) {
          return { ...pos, isEnabled: checked }
        } else {
          return pos;
        }
      }))
    }    
  }

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (users) {
      users.map((usr) => {
        if (usr.isEnabled) {
          SelectedUser.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0 || SelectedUser.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([])
    }

    // if(positions && SelectedPosition.length > 0){
    //   loadUsers(SelectedPosition);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, users]);

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0)
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        created_by: SelectedUser,
        isReport: true,
        is_interim: 2,
      });
      if (result.data) {
        setSuccessors(
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              let successors_details = item.successors_details.map((value, index) => {
                return {
                  ...value,
                };
              })
              return {
                name: item.name,
                position: item.position,
                retirement: item.retirement,
                pos_details: item.pos_details,
                created_retirement_details: item.created_retirement_details,
                successors_details: successors_details
              }
            } else {
              return [];
            }
          })
        );
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, '_blank');
    }
  }

  return (
    <>
     <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('company_retirement_profile')}</div>
          <div className="text-sm text-slate-800 font-bold">{t('please_make_a_selection_from_the_dropdown_to_generate_report')}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={'button'}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<RetirementReport successors={successors} user={user} />} fileName="profile_report.pdf">
                {({ blob, url, loading, error }) => (
                  loading ? t('loading_document') : t('download_pdf'))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div> 
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {
              totalDataCount > 0 ? (
                successors.map((row) => {
                  return (
                    <>
                      {
                        row.successors_details && (
                          <>
                            <tr className="bg-slate-100">
                              <td className="py-3 px-2" colSpan={11}></td>
                            </tr>
                            <tr className="border-b border-slate-200 bg-slate-300">
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md" colSpan={3}>
                                {row.position} : {row.name}
                              </td>
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md" colSpan={8}>
                                {t('retirement_window')} : {row.retirement}
                              </td>
                            </tr>
                          </>
                        )
                      }
                      {
                        row.successors_details && (
                        <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tl-md" colSpan={3}>{t('potential_successors')}</th>                          
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tr-md" colSpan={8}>{t('retirement_window')}</th>
                        </tr>
                        )
                      }                      
                      {
                        row.successors_details && row.successors_details.map((item, index) => (
                          <tr className="border-b border-slate-200" key={index} >
                            <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top" colSpan={3}>
                              {item?.name}
                              <span className="block text-xs font-bold leading-tight text-teal-500">
                              {(item?.position_details) ? item?.position_details[0]?.short_name : 'N/A'}
                              </span>
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "+((item?.retirement_details.length > 0 && item?.retirement_details[0].isPass)? '': 'text-red-600')} colSpan={8}>
                              {item?.retirement_details &&
                                item?.retirement_details.length > 0
                                ? item?.retirement_details[0]?.name
                                : "N/A"}
                            </td>
                          </tr>
                        ))
                      }
                    </>
                  )
                })
              ) : (
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {
                      loading ? <Searching label={t('searching')} /> : <NoDataFound label={t('please_make_a_selection_from_the_dropdown_to_generate_report')} />
                    }
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndividualRetirementReport;