import React, { useEffect, useState, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import toastr from "toastr";

import LogoutPopup from "../components/LogoutPopup";
import NoDataFound from "../components/NoDataFound";
import Searching from "../components/Searching";
import { postData } from "../services/api";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Plans = ({ }) => {
  setTitle('Succession Now | Plans');
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isPay, setIsPay] = useState(false);
  const [enabledLogoutPopup,setenabledLogoutPopup] = useState(false);

  useEffect(() => {
    async function planData() {
      setLoading(true);
      try {
        const result = await postData("plans/list", {
          status : 1,
          is_view : true
        });
        if (result.data) {
          setPlans(result.data);
          setLoading(false);
        } else {
          // console.log('plans list message',result.message)
          setLoading(false);
        }
      } catch (error) {
        // console.log('Error plans list catch',error.message)
        setLoading(false);
      }
    }
    planData();
  }, []);

  const onPay = async (id, price) => {
    if(isLogin){
      setIsPay(true);
      try {      
        const paymentData = await postData("stripe/plan-create", {
          planId: id,
          price: price
        });
        if (paymentData.status && paymentData.status === 200) {
          setIsPay(false);
          window.location.href = paymentData?.data?.redirect_url;
          //return navigate("/dashboard");
        } else {
          toastr.error(paymentData.message);
          setIsPay(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsPay(false);
      }
    }else{
      return navigate("/signin");
    }
   
  }

  return (
    <>
      <section className="relative flex items-center w-full min-h-screen bg-slate-50 py-20 overflow-hidden">
        <div className="w-full max-w-7xl mx-auto">
          <div className="text-center mb-10">
            <div className="text-4xl font-light text-dark-teal-400">{t('plan_&_pricing')}</div>
            <div className="text-sm font-medium text-slate-300 uppercase">Succession Now</div>
          </div>
          {(plans && plans.length > 0) ? (
            <>
            <div className="flex flex-wrap justify-center -mx-5">
              {plans.map((item, index) => (
                <div className="w-full flex max-w-[380px] p-5" key={item._id}>
                  <div className="w-full h-full flex flex-col bg-white rounded shadow overflow-hidden p-5 relative z-10 hover:ring-2 ring-dark-teal-400 transition-all duration-200 group">
                  {
                    (item && item.trans_details) && 
                    <div 
                    className="absolute z-10 top-5 left-5 bg-teal-500 py-1 px-2 rounded text-xs leading-none text-white"
                    >{t('active_plan')}
                    </div>
                  }
                    <div className="absolute right-0 top-7 z-[-1]">
                      <svg width="77" height="172" viewBox="0 0 77 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                        <defs>
                          <linearGradient id="paint0_linear" x1="86" y1="0" x2="86" y2="172" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#14b8a6" stopOpacity="0.09" />
                            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="absolute right-4 top-4 z-[-1]">
                      <svg width="41" height="89" viewBox="0 0 41 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="38.9138" cy="87.4849" r="1.42021" transform="rotate(180 38.9138 87.4849)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="74.9871" r="1.42021" transform="rotate(180 38.9138 74.9871)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="62.4892" r="1.42021" transform="rotate(180 38.9138 62.4892)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="38.3457" r="1.42021" transform="rotate(180 38.9138 38.3457)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="13.634" r="1.42021" transform="rotate(180 38.9138 13.634)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="50.2754" r="1.42021" transform="rotate(180 38.9138 50.2754)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="26.1319" r="1.42021" transform="rotate(180 38.9138 26.1319)" fill="#14b8a6"/>
                        <circle cx="38.9138" cy="1.42021" r="1.42021" transform="rotate(180 38.9138 1.42021)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="87.4849" r="1.42021" transform="rotate(180 26.4157 87.4849)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="74.9871" r="1.42021" transform="rotate(180 26.4157 74.9871)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="62.4892" r="1.42021" transform="rotate(180 26.4157 62.4892)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="38.3457" r="1.42021" transform="rotate(180 26.4157 38.3457)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="13.634" r="1.42021" transform="rotate(180 26.4157 13.634)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="50.2754" r="1.42021" transform="rotate(180 26.4157 50.2754)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="26.1319" r="1.42021" transform="rotate(180 26.4157 26.1319)" fill="#14b8a6"/>
                        <circle cx="26.4157" cy="1.4202" r="1.42021" transform="rotate(180 26.4157 1.4202)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="87.4849" r="1.42021" transform="rotate(180 13.9177 87.4849)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="74.9871" r="1.42021" transform="rotate(180 13.9177 74.9871)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="62.4892" r="1.42021" transform="rotate(180 13.9177 62.4892)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="38.3457" r="1.42021" transform="rotate(180 13.9177 38.3457)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="13.634" r="1.42021" transform="rotate(180 13.9177 13.634)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="50.2754" r="1.42021" transform="rotate(180 13.9177 50.2754)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="26.1319" r="1.42021" transform="rotate(180 13.9177 26.1319)" fill="#14b8a6"/>
                        <circle cx="13.9177" cy="1.42019" r="1.42021" transform="rotate(180 13.9177 1.42019)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="87.4849" r="1.42021" transform="rotate(180 1.41963 87.4849)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="74.9871" r="1.42021" transform="rotate(180 1.41963 74.9871)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="62.4892" r="1.42021" transform="rotate(180 1.41963 62.4892)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="38.3457" r="1.42021" transform="rotate(180 1.41963 38.3457)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="13.634" r="1.42021" transform="rotate(180 1.41963 13.634)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="50.2754" r="1.42021" transform="rotate(180 1.41963 50.2754)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="26.1319" r="1.42021" transform="rotate(180 1.41963 26.1319)" fill="#14b8a6"/>
                        <circle cx="1.41963" cy="1.4202" r="1.42021" transform="rotate(180 1.41963 1.4202)" fill="#14b8a6"/>
                      </svg>
                    </div>
                    <div className="h-[172px] flex flex-col justify-center border-b border-slate-200">
                      <div className="text-lg font-medium text-dark-teal-400">{item?.name}</div>
                      <div className="text-[42px] font-bold text-slate-700">${item?.price} 
                        <span className="text-body-color text-base font-medium text-slate-400"> / {item?.interval} </span>
                      </div>
                    </div>
                    <div className="py-5 mb-auto">
                      <div className="space-y-4 text-slate-500 text-[15px] font-medium">
                        {
                          (item.features && item.features.length > 0) && 
                            item.features.map((feature,idx) => {                              
                              return(
                                feature.isEnabled &&                            
                                <div key={idx}><span className="inline-flex font-semibold text-base text-dark-teal-300 mr-2">&#9769;</span>{feature?.name}</div>
                              )
                            })
                        }
                      </div>
                    </div>
                    <div className="relative mt-5 pt-5 border-t border-slate-200">
                      <Button
                        buttonLabelClasses={'font-semibold text-sm'}
                        buttonClasses={'w-full h-12 rounded-md '+((item && item.trans_details)?' !bg-slate-200 !text-slate-600 border border-slate-300':' group-hover:!bg-dark-teal-400')}
                        buttonType={"button"}
                        buttonIcon={(isPay) ? "fa-light fa-spinner fa-spin" : "fa-light fa-credit-card-front"}
                        buttonIconPosition={"left"}
                        buttonLabel={((item && item.trans_details)?t('active_plan'):t('pay_now'))}
                        functions={() => onPay(item?._id, item?.price)}
                        buttonHasLink={false}
                        buttonDisabled={(item && item.trans_details)?true:isPay}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {
              isLogin ?
              <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
                Take me to
                <Button 
                  buttonClasses={'!bg-transparent !text-dark-teal-400 !h-auto !px-0'} 
                  buttonType={''}
                  buttonHasLink={true}
                  buttonLabel={t('my_account')} 
                  buttonLabelClasses={'!font-semibold'}
                  buttonLink={"/profiles"}
                />
                or
                <Button 
                  buttonClasses={'!bg-transparent !text-dark-teal-400 !h-auto !px-0'} 
                  buttonType={''}
                  buttonHasLink={false}
                  buttonLabel={t('logout')} 
                  buttonLabelClasses={'!font-semibold'}
                  functions={() => setenabledLogoutPopup(true)}
                />
              </div>:
              <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
                Already have an account?
                <Button 
                  buttonClasses={'!bg-transparent !text-dark-teal-400 !h-auto !px-0'} 
                  buttonType={''}
                  buttonHasLink={true}
                  buttonLabel={t('login')} 
                  buttonLabelClasses={'!font-semibold'}
                  buttonLink={"/signin"}
                />
              </div>
            }
            </>
          ) : (
            loading ? <Searching label={t('searching')} /> : <NoDataFound label={t('no_data_found')} />
          )}
        </div>
        {
          enabledLogoutPopup &&
          <LogoutPopup
          isOpen={enabledLogoutPopup}
          setIsOpen={(val) => setenabledLogoutPopup(val)}
          />
        }
      </section>
    </>
  );

}
export default Plans;