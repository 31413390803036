import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import { postData } from "../../../services/api";
import ClientSkeleton from "../../loader/ClientSkeleton";
import Image from "../../elements/Image";
import { useTranslation } from "react-i18next";

const Client = () => {
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [clients, setClientsData] = useState([]);

  useEffect(() => {
   
    const clientsList = async () => {
      setLoading(true);
      try {
        const result = await postData("client/list", {
          status: 1,
        });
        if (result.data) {
          setClientsData(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    clientsList();
  }, []);

  return (
    <>
      <section
        className="relative flex items-center w-full bg-white py-20 overflow-hidden"
        id="testimonials"
      >
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative">
            <h2 className="mb-6 text-center">
              <span className="block xl:text-5xl text-3xl font-bold text-slate-700">
                {t('clients_we_have_worked_with')}
              </span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">
                Succession Now
              </span>
            </h2>
          </div>
          <div className="clients relative mt-10">
            {
              loading?(<ClientSkeleton/>):(<>
              <Swiper
              spaceBetween={20}
              slidesPerView={2}
              centeredSlides={false}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              navigation={false}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
            >  
              
               { Array.isArray(clients) &&
                clients.map((item) => {
                return (
                
                    <SwiperSlide key={item._id}>
                      <div className="h-16 grayscale hover:grayscale-0 transition-all duration-300" title={item?.name}>
                        <Image 
                          src={item?.image} 
                          alt={item?.name} 
                          width={'100%'}
                          height={'100%'} 
                          effect={'blur'} 
                          classes={'object-contain'}
                          id={""}
                        />
                      </div>
                    </SwiperSlide>
      
                );
              })}
            </Swiper>
              </>)
            }
          
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Client;
