import React,{useEffect,useState} from 'react';
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/section/PageBanner";
import AboutServices from "../components/section/Aboutus/AboutServices";
import HomeAboutUs from "../components/section/Home/HomeAboutUs";
import { postData } from '../services/api';
import AboutService from '../components/section/Aboutus/AboutService';
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | About Us");
  const { i18n,t } = useTranslation();
  const [pageData, setPageData] = useState({});
  const [intro3, setIntro3] = useState({});
  const [intro2, setIntro2] = useState({});

  useEffect(() => {
    const loadPageDetails = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'intro2'
        });
        if (result.data) {
          setPageData(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails();

    const loadPageDetails3 = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'intro3'
        });
        if (result.data) {
          setIntro3(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails3();

    const loadPageDetails2 = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:'request-demo'
        });
        if (result.data) {
          setIntro2(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails2();
  }, []);


  return (
    <>
      <PageBanner title={t('about_us')}/>
      <HomeAboutUs />
      <AboutService 
      headingOne={true} 
      headingTwo={true} 
      headingThree={true}
      />      
      {/* <AboutServices /> */}
      {/* <section className='relative w-full bg-white pb-20 xl:p-16 xl:pt-0'>
        <div className="max-w-[1440px] mx-auto xl:px-5 px-5">
          <div className="flex justify-center gap-20">            
            <div className="w-full md:w-5/12">
              <div className="relative space-y-10">
                <h6 className="mb-6 block xl:text-1xl text-2xl font-bold text-dark-teal-400 tracking-tight">
                What Industry Leaders Say
                </h6>               
                <ul className="pl-8 space-y-5">
                  {
                    (pageData?.content && pageData?.content.length>0) && 
                      pageData?.content.map((item,index) => (
                      <li className="relative font-semibold text-black border border-slate-200 p-3 bg-white rounded-xl before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:top-2 before:-left-8">
                        <span className="flex leading-tight">{item?.title}</span> 
                        <span className="flex justify-end text-xs text-slate-400 font-normal">- {item?.creator}</span>
                      </li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
            <div className="w-full md:w-5/12">
              <div className="relative space-y-10">
                <h2 className="mb-6 block xl:text-1xl text-2xl font-bold text-dark-teal-400 tracking-tight">
                What Succession Planning Can Do For You
                </h2>   
                <ul className="pl-8 grid grid-cols-2 gap-y-5 gap-x-10">
                  {
                    (intro3?.content && intro3?.content.length>0) && 
                    intro3?.content.map((item,index) => (
                      <li className="relative font-semibold text-black leading-tight before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:-top-[3px] before:-left-8">{item?.title}</li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
            <div className="w-full md:w-5/12">
              <div className="relative space-y-10">
                <h2 className="mb-6 block xl:text-1xl text-2xl font-bold text-dark-teal-400 tracking-tight">
                What the Numbers Say
                </h2>   
                <ul className="pl-8 space-y-5">
                  {
                    (intro2?.content && intro2?.content.length>0) && 
                    intro2?.content.map((item,index) => (
                      <li className="relative font-semibold text-black border border-slate-200 p-3 bg-white rounded-xl before:content-[''] before:bg-[url('assets/icon/check-circle.svg')] before:bg-contain before:absolute before:w-6 before:h-6 before:top-2 before:-left-8">
                        <span className="flex leading-tight">{item?.title}</span> 
                        <span className="flex justify-end text-xs text-slate-400 font-normal">- {item?.creator}</span>
                      </li> 
                      ))
                  }                                      
                </ul>                
              </div>
            </div>
          </div>
        </div>
      </section> */}
      
    </>
  );
};

export default AboutUsPage;