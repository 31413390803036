import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Image from "../../components/elements/Image";
import ReactPaginate from "react-paginate";
import { postData } from "../../services/api";
import BuyPlanPopup from "../../components/popup/BuyPlanPopup";
import NoDataFound from "../../components/NoDataFound";
import Searching from "../../components/Searching";
import moment from "moment";
import toastr from "toastr";
import { Tooltip } from "react-tooltip";
import MakePaymentPopup from "../../components/popup/MakePaymentPopup";
import MakeCreateAdditionalPaymentPopup from "../../components/popup/MakeCreateAdditionalPaymentPopup";
import { useTranslation } from "react-i18next";

const Payments = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Payment");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [enabledBuyPlanPopup, setEnabledBuyPlanPopup] = useState(false);
  const [enabledMakePaymentPopup, setEnabledMakePaymentPopup] = useState(false);
  const [enabledCreateAdditionalPaymentPopup, setEnabledCreateAdditionalPaymentPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPaymentList = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/payment-list", {
          keyword: keyword,
          limit: limit,
          offset: keyword ? 0 : offset,
          sortQuery: sortQuery,
          status: 1,
          code: "customer-admin",
        });
        if (result.data) {
          setPaymentList(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPaymentList();
  }, [limit, offset, keyword]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const onSubmit = async (plan_id, company_id) => {
    setLoading(true);
    try {
      const paymentData = await postData("stripe/plan-create", {
        planId: plan_id,
        company_id: company_id,
      });
      if (paymentData.status && paymentData.status === 200) {
        setLoading(false);
        window.location.href = paymentData?.data?.redirect_url;
      } else {
        toastr.error(paymentData.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("payment")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button
            //buttonClasses={'!bg-fuchsia-500'}
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("payment_request")}
            buttonHasLink={false}
            functions={() => setEnabledCreateAdditionalPaymentPopup(true)}
          />
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-full sm:w-auto sm:ml-auto flex sm:justify-end flex-wrap sm:flex-nowrap items-center gap-1 sm:gap-3">
          <div className="relative w-full sm:w-auto">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">{t("company")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("administrator_email")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("date")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("price")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("payment_mode")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("payment_status")}</th>
              <th className="py-4 px-2 text-right max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">{t("action")}</th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              paymentList.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="relative flex items-center">
                        <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full">
                          <Image src={item?.image} alt={"data"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-cover"} />
                        </div>
                        <div className={"text-sm font-normal text-slate-600 mx-2 "}>{item?.company_name}</div>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">{item && item?.email ? item?.email : "N/A"}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">{item && item?.created_at ? moment(new Date(item?.created_at)).format("Do MMM YYYY") : "N/A"}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">{item && item?.price ? "$" + item?.price.toFixed(2) : "$0.00"}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">{item && item?.type ? item?.type : "N/A"}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="flex items-center">
                      <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 " + (item && item.payment_status === 1 ? "bg-green-100 text-green-600" : item && item.payment_status === 3 ? "bg-amber-100 text-amber-600" : "bg-rose-100 text-rose-600")}>{item && item.payment_status === 1 ? "Paid" : item && item.payment_status === 3 ? t("unpaid") : t("cancel")}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-right max-w-[240px]">
                    {item && item.payment_status === 3 ? (
                      <div className="flex items-center justify-end gap-2">
                        <Button
                          buttonClasses={"!h-7 !px-2 !text-xs !bg-slate-400"}
                          buttonLabel={""}
                          buttonLabelClasses={"text-xs"}
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-credit-card"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => {
                            setEnabledBuyPlanPopup(true);
                            setCompanyData(item);
                          }}
                          tooltipContent={t("manage_payment")}
                        />
                        <Tooltip id="button-tooltip" place="left"></Tooltip>
                        <Button buttonClasses={"!h-7 !px-2 !text-xs"} buttonLabel={""} buttonLabelClasses={"text-xs"} buttonType={"button"} buttonIcon={"fa-regular fa-arrow-right-to-arc"} buttonIconPosition={"left"} buttonHasLink={false} functions={() => onSubmit(item?.plan_id, item?._id)} tooltipContent={t("make_payment")} />
                      </div>
                    ) : (
                      <div className="flex items-center justify-end gap-2">
                        <Tooltip id="button-tooltip" place="left"></Tooltip>
                        <Button
                          buttonClasses={"!h-7 !px-2 !text-xs !bg-fuchsia-400"}
                          buttonLabel={""}
                          buttonLabelClasses={"text-xs"}
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => {
                            setEnabledMakePaymentPopup(true);
                            setCompanyData(item);
                          }}
                          tooltipContent={t("make_payment")}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={7}>
                  {loading ? <Searching label={t("searching")} /> : <NoDataFound label={t("no_data_found")} />}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate breakLabel="..." breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>} nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2" activeClassName="hover:bg-teal-500 bg-teal-500 text-white" pageLinkClassName="flex justify-center items-center w-full h-full" previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>} pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" renderOnZeroPageCount={null} />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}  
        </div>
      )}
      {enabledBuyPlanPopup && (
        <BuyPlanPopup
          isOpen={enabledBuyPlanPopup}
          setIsOpen={(val) => setEnabledBuyPlanPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"predictors"}
        ></BuyPlanPopup>
      )}
      {enabledMakePaymentPopup && (
        <MakePaymentPopup
          isOpen={enabledMakePaymentPopup}
          setIsOpen={(val) => setEnabledMakePaymentPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></MakePaymentPopup>
      )}
      {enabledCreateAdditionalPaymentPopup && (
        <MakeCreateAdditionalPaymentPopup
          isOpen={enabledCreateAdditionalPaymentPopup}
          setIsOpen={(val) => setEnabledCreateAdditionalPaymentPopup(val)}
          data={""}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></MakeCreateAdditionalPaymentPopup>
      )}
    </>
  );
};

export default Payments;
