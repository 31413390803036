import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Select from '../form/Select';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useTranslation } from "react-i18next";

const SchedualeDemoPopup2 = ({
  isOpen,
  setIsOpen = () => {},
  data,
  leadPath,
  getActionValue = () => {},
}) => { 
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [pageData, setPageData] = useState({});
  const [sitesetting,setSitesetting] = useState({});  

  const closeModal = () =>{ setIsOpen(false) } 
  
  useEffect(()=>{
    const siteDetails=async()=>{
      try{
        const result = await postData("setting/details",{});
        if(result.data){
          setSitesetting(result.data)
        }

      }
      catch(err){
        console.log(err.message)
      }

    }
    siteDetails();
  },[])

  useEffect(() => {
    const loadPageDetails = async () => {
      try {
        const result = await postData("/lead-content/details", {
          slug:"request-demo"
        });
        if (result.data) {
          setPageData(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPageDetails();
  }, []);

  // const handelSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await postData("/enquery/create", {
  //       type:2,
  //       full_name: fullName,
  //       email: email,
  //       phone: phone,
  //       company: company,
  //       message: message

  //     });
  //     if (result.status === 200) {
  //       setLoading(false);
  //       setEmail("");
  //       setPhone("");
  //       setCompany("");
  //       setMessage("");
  //       setFullName("");
  //       toastr.success(result.message)
  //     } else {
  //       setLoading(false);
  //       toastr.error(result.message)
  //     }
  //   } catch (e) {
  //     setLoading(false);
  //     toastr.error(e.message)
  //   }
  // };

  const handelSubmit = async () => {
    setLoading(true);
    try {
      const result = await postData("/enquery/create", {
        type:3,
        full_name: fullName,
        email: email,
        phone: phone,
        leadPath: leadPath
      });
      if (result.status === 200) {
        setLoading(false);
        setEmail("");
        setPhone("");
        setFullName("");
        toastr.success(result.message)
      } else {
        setLoading(false);
        toastr.error(result.message)
      }
    } catch (e) {
      setLoading(false);
      toastr.error(e.message)
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl text-left align-middle shadow-xl transition-all">
                    {/* <Dialog.Title 
                    as="h3" 
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >View the Demo</Dialog.Title> */}
                    <div className="absolute top-2 right-2 z-10">
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={''} 
                        buttonClasses={'!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    <div className="relative p-10 bg-gradient-to-r from-dark-teal-200 to-dark-teal-400 rounded-2xl overflow-hidden">
                      <div className="relative">
                        <h2 className="mb-6 flex flex-col items-start">
                          <span className="inline-block xl:text-4xl text-3xl font-bold text-teal-900 border-b-4 border-teal-200 pb-4 mb-4">{t('view_demo')}</span>
                          {/* <span className="inline-block text-base text-white font-light">{sitesetting?.demo_request_sub_title}</span> */}
                        </h2>
                      </div> 
                      <div className="relative mt-10 flex flex-col gap-4">   
                        <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"text"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                            }
                            inputPlaceholder={t('enter_your_full_name')}
                            inputValue={fullName}
                            inputName={""}
                            errorType={""}
                            isDisabled={false}
                            setInput={setFullName}
                          />
                        </div>               
                        <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"email"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                            }
                            inputPlaceholder={t('enter_your_work_email')}
                            inputValue={email}
                            inputName={""}
                            errorType={""}
                            setInput={setEmail}
                          />
                        </div>                  
                        <div className="relative">
                          <Input
                            labelClasses={"!text-white font-light"}
                            inputType={"text"}
                            inputClasses={
                              "h-12 rounded-lg focus:!border-dark-teal-300 focus:!bg-dark-teal-50"
                            }
                            inputPlaceholder={t('enter_your_phone_number')}
                            inputValue={phone}
                            inputName={""}
                            errorType={""}
                            setInput={setPhone}
                          />
                        </div>
                        <div className="relative md:col-span-2">
                          <div className="flex justify-center mt-8">
                            <Button
                              buttonClasses="!bg-dark-teal-700 hover:!bg-dark-teal-900 !px-6 !w-full !h-14 !text-xl rounded-lg"
                              buttonLabelClasses="!text-xl"
                              buttonType={"button"}
                              buttonLabel={t('view_demo')}
                              buttonIcon={
                                loading
                                    ? "fa-light fa-spinner fa-spin"
                                    : ""
                                }
                              buttonIconPosition={"left"}
                              functions={handelSubmit}
                              buttonHasLink={false}
                              buttonDisabled={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default SchedualeDemoPopup2;
