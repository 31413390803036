import React, { useEffect, useState } from 'react';
import Input from "../../form/Input";
import Textarea from "../../form/Textarea";
import Button from "../../form/Button";
import toastr from 'toastr';
import { postData } from '../../../services/api';
import { useTranslation } from "react-i18next";

const NewContactForm = () => {
    const { i18n,t } = useTranslation();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [isSubmit,setIsSubmit] = useState(false);
    
    const handelSubmit = async () => {
        setIsSubmit(true);
        try {
          const result = await postData("/enquery/create", {
            name: name,
            phone: phone,
            email: email,
            subject :subject,
            message: message,
          });
          if (result.status === 200) {
            setSubject("");
            setMessage("");
            setEmail("");
            setName("");
            setPhone("");
            setIsSubmit(false);
            toastr.success(result.message)
          } else {
            toastr.error(result.message)
            setIsSubmit(false);
          }
        } catch (e) {
          toastr.error(e.message)
          setIsSubmit(false);
        }
    };
    
    return (
        <div className="py-10 lg:py-14 xl:py-16 2xl:py-20 relative  w-full">
            <div className="w-full max-w-[90%] xs:max-w-md sm:max-w-xl lg:max-w-4xl  mx-auto ">
            <form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <div className="w-full space-y-7">
                        <div className="relative">
                            <Input
                                label={t('first_&_last_name')}
                                inputPlaceholder="i.e.John Doe"
                                labelClasses="capitalize font-PlusJakartaSans text-slate-900 !font-bold !text-base"
                                inputClasses="bg-transparent h-12"
                                inputValue={name}
                                setInput={setName}
                            />
                        </div>
                        <div className="relative">
                            <Input
                                label={t('phone_number')}
                                inputPlaceholder="i.e. +1-234-567-7890"
                                labelClasses="capitalize font-PlusJakartaSans text-slate-900 !font-bold !text-base"
                                inputClasses="bg-transparent h-12"
                                inputValue={phone}
                                setInput={setPhone}
                            />
                        </div>
                        <div className="relative">
                            <Input
                                label={t('email')}
                                inputPlaceholder="i.e. john@mail.com"
                                labelClasses="capitalize font-PlusJakartaSans text-slate-900 !font-bold !text-base"
                                inputClasses="bg-transparent h-12"
                                inputValue={email}
                                setInput={setEmail}
                            />
                        </div>
                    </div>
                    <div className="w-full space-y-5">
                        <div className="relative">
                            <Input
                                label={t('subject')}
                                inputPlaceholder="i.e. I need a help"
                                labelClasses="capitalize font-PlusJakartaSans text-slate-900 !font-bold !text-base"
                                inputClasses="bg-transparent h-12"
                                inputValue={subject}
                                setInput={setSubject}
                            />
                        </div>
                        <div className="relative">
                            <Textarea
                            label={t('message')}
                            labelClasses="capitalize font-PlusJakartaSans text-slate-900 !font-bold !text-base"
                            inputPlaceholder="Type your message"
                            inputClasses="bg-transparent h-12"
                            inputValue={message}
                            setTextarea={setMessage}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full mt-7">
                    <div className="relative">
                        <Button
                            buttonLabel={t('send_message')}
                            buttonClasses="!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6"
                            buttonLabelClasses="capitalize lg:!text-base xl:!text-lg tracking-wider !font-bold"
                            buttonIconPosition="right"
                            buttonIcon={
                                isSubmit
                                    ? "fa-light fa-spinner fa-spin"
                                    : "fa-light fa-arrow-right"
                            }
                            functions={handelSubmit}
                            buttonHasLink={false}
                            buttonDisabled={isSubmit}
                        />
                    </div>
                </div>
            </form>
            </div>
        </div>
        
    
    );
};

export default NewContactForm;