import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import { Editor } from "@tinymce/tinymce-react";

import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { postData } from "../services/api";
import { tinymceApiKey } from "../helpers";

import { useTranslation } from "react-i18next";

const ManageEmailTemplate = () => {
  const { id } = useParams();
  const editorRef = useRef(null);
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Email Template");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Email Template");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [subject, setSubject] = useState("");
  const [fromEmail, setFromEmail] = useState();
  const [content, setContent] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  //const [isCancel,setIsCancel] = useState(false);

  useEffect(() => {
    // Email template details
    const loadEmailTemplateDetails = async () => {
      try {
        const result = await postData("email-template/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadEmailTemplateDetails();
  }, [id]);

  useEffect(() => {
    setSubject(details?.subject);
    setFromEmail(details?.from_email);
    setContent(details?.content);
  }, [details]);

  // const onCancel = async (e) => {
  //     setIsCancel(true);
  //     if(id){
  //         setSubject(details?.subject);
  //         setFromEmail(details?.from_email);
  //     }else{
  //         setSubject('');
  //         setFromEmail('');
  //     }
  //     setIsCancel(false);
  // }

  const onSubmit = async (e) => {
    setIsAdd(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "email-template/update";
        payload = {
          id: id,
          subject: subject,
          from_email: fromEmail,
          content: content,
        };
      } else {
        path = "email-template/create";
        payload = {
          subject: subject,
          from_email: fromEmail,
          content: content,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsAdd(false);
        return navigate("/email-templates");
      } else {
        toastr.error(result.message);
        setIsAdd(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsAdd(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_email_template") : t("add_email_template")} </div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              {/* <div className="text-xl font-bold text-slate-900">Successor Information</div> */}
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("subject")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_subject")} inputValue={subject} setInput={setSubject} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("from_email")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_from_email")} inputValue={fromEmail} setInput={setFromEmail} />
                  </div>
                </div>
              </div>
              <div className="space-y-4 mt-4">
                <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Editor
                      apiKey={tinymceApiKey()}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={content}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: ["code", "advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                        toolbar: "code | undo redo | formatselect | " + "bold italic backcolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help ",
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newText) => setContent(newText)}
                    />
                    {/* <Editor
                        editorState={content}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}                
                        /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                {/* <Button 
                    buttonLabelClasses=''
                    buttonType={'button'} 
                    buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={'Cancel'} 
                    buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                    functions={onCancel}
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                    /> */}
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isAdd} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageEmailTemplate;
