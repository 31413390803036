import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { postData } from "../../services/api";
import toastr from "toastr";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ManageRenewalDatePopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {}, type }) => {
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const role = localStorage.getItem("role");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const currentTime = moment(new Date(date + " 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
      const result = await postData("subscription/update", {
        id: data?._id,
        end_date: new Date(currentTime).toUTCString(),
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({ button_type: "edit", row_id: data?._id, current_status: "", date: date });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDate(moment(new Date(data?.date)).tz("America/Denver").format("YYYY-MM-DD"));
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("manage_reminder")}
                  </Dialog.Title>
                  <div className="relative mt-4">
                    <div className="relative mb-3">
                      <Flatpickr
                        ref={flatPicker}
                        className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                        placeholder={""}
                        value={date}
                        options={{ dateFormat: "Y-m-d", minDate: new Date() }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setDate(dateStr);
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button buttonClasses="!bg-teal-500" buttonLabelClasses="" buttonType={"button"} buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("confirm")} functions={onSubmit} buttonHasLink={false} buttonDisabled={loading} />
                    <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={"fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={closeModal} buttonHasLink={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageRenewalDatePopup;
