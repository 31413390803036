import React from 'react';
import {  Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import logo from '../../../src/logo-succession-now.png';

const PolicyReport = ({data}) => {
  const styles = StyleSheet.create({
    image:{
      width: 200,
      height:80,
      objectFit:'contain',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      margin:'0  auto'
    },
    sectionTitle: {
      fontSize: 18,
      fontWeight: 700,
      textDecoration: "underline",
      marginBottom: 10
    },
    page: {
      padding: 15
    },
    subDes: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.4,
      marginBottom: 10,
    },
    flexPart: {
      display: "flex",
      flexDirection: "column",
    },
  });


  return (
    <Document>
      <Page size="A4" orientation='landscape' style={styles.page} >
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 16,
          }}
        >
          <Image
            style={styles.image}
            src={logo}
          />
        </View>
        <View style={styles.flexPart}>
            <Text style={styles.sectionTitle}>Policy Statement</Text>
            <Text style={styles.subDes}>{data}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PolicyReport;