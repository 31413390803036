import React from 'react';
import { classNames } from "../../helper/classNames";

const Checkbox = ({
  checkboxClass,
  checkboxName, 
  checkboxInputClass, 
  isChecked, 
  checkboxLabel, 
  checkboxLableClass,
  checkboxValue,
  getCheckboxValue = () => {}, 
  isDisabled=false,
  tooltipContent='',
  divClass,
  ...props
}) => {
  return (
    <>
      <div className={classNames( "relative overflow-hidden flex items-center",divClass)}  data-tooltip-id="checkbox-tooltip" data-tooltip-content={tooltipContent}>
        <input 
          type="checkbox" 
          name={checkboxName} 
          className={classNames(
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10",
            checkboxInputClass,
            isDisabled?"cursor-default":" cursor-pointer"
          )} 
          //defaultChecked={isChecked}
          checked={isChecked}
          onChange={(e) => getCheckboxValue(checkboxValue,e)}
          disabled={isDisabled}
        />
        <div className={classNames(
          "w-5 h-5 min-w-[20px] border border-slate-300 rounded flex items-center justify-center text-sm text-black/0 peer-checked:bg-teal-500 peer-checked:border-teal-500 peer-checked:text-white", checkboxClass,
          isDisabled?" bg-slate-200":" bg-white"
          )}>
          <i className="fa-light fa-fw fa-check"></i>
        </div>
        <div 
        
        className={classNames(
          "ml-2 text-sm text-slate-500 w-full",
          checkboxLableClass,
          isDisabled?"opacity-50":""
          )}>{checkboxLabel}</div>
      </div>
    </>
  );
};

export default Checkbox;