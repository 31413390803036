import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';

const FreeAuthRoute = ({ auth, children }) => {
  if(localStorage.getItem('accessToken') || auth.user) {
    //console.log('auth.user',auth.user)
    if(localStorage.getItem('role') === 'master-admin' || localStorage.getItem('role') === 'basic-administrator'|| localStorage.getItem('role') === 'sales-admin' || localStorage.getItem('role') === 'sales-staff' || localStorage.getItem('role') === 'content-manager'){
      return <Navigate to="/dashboard-statistics" />
    }else{ 
      if(auth.user && auth.user.code === 'customer-user'){
        return <Navigate to="/profiles" />
      }else if(auth.user && auth.user.code === 'customer-boardmember'){
        return <Navigate to="/boardroom" />
      }else if(auth.user && auth.user.code === 'customer-executive'){
        return <Navigate to="/add-executive" />
      }else{
        return <Navigate to="/dashboard" />
      }
    }
    
  }
  return children;
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, null)(FreeAuthRoute);