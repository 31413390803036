import React from "react";

const TestimonialSkeleton = () => {
  return (
    <>
      <div className="w-full bg-white rounded-xl shadow p-6 md:p-10 my-4 text-center flex justify-center items-center">
        <div className="w-full max-w-md">
          <div className="space-y-6">
            {/* <div className="w-20 h-20 overflow-hidden rounded-full ring-2 ring-offset-4 ring-slate-200 mx-auto bg-slate-100"></div> */}
            <div className="space-y-1">
              <div className="w-40 mx-auto h-3 bg-slate-100 rounded-full"></div>
              <div className="w-20 mx-auto h-2 bg-slate-100 rounded-full"></div>
              <div className="w-32 mx-auto h-2 bg-slate-100 rounded-full"></div>
            </div>
            <div className="space-y-2">
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
              <div className="w-full h-2 bg-slate-100 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialSkeleton;
