import React from "react";
import { classNames } from "../../helper/classNames";

const Search = ({ placeholder, classes, divClasses, iconClasses, icon = "fa-regular fa-fw fa-magnifying-glass", searchValue = "", setInput, onKeyDown = () => {} }) => {
  return (
    <>
      <div className={classNames("relative flex h-10 w-full bg-white rounded border border-slate-200", divClasses)}>
        <div className={classNames("w-10 h-10 flex items-center justify-center text-slate-400 flex-shrink-0", iconClasses)}>
          <i className={classNames("fa-fw", icon)}></i>
        </div>
        <input
          type="text"
          placeholder={placeholder}
          className={"!border-0 !ring-0 bg-transparent text-slate-600 text-sm p-0 pr-3 " + classes}
          //value={searchValue}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </div>
    </>
  );
};

export default Search;
