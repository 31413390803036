import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Radio from '../form/Radio';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";

const MakeCreateAdditionalPaymentPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => {
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role"); 
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [amount,setAmount] = useState('');
  const [purpose,setPurpose] = useState('');
  const [subscriptionType,setSubscriptionType] = useState('stripe');
  const [paymentMode,setPaymentMode] = useState('payment');  
  const [isPay, setIsPay] = useState(false);
  const [isVisabled,setIsVisabled] = useState(false);
  const [paymentUrl,setPaymentUrl] = useState('');
  const [isCopy,setIsCopy] = useState(false);

  const closeModal = () =>{ setIsOpen(false) } 

  const onMakePayment = async () => {
    setIsPay(true);
    if(name === ''){
      toastr.error(t('name_field_is_required'))
      setIsPay(false);
    }else if(email === ''){
      toastr.error(t('email_field_is_required'))
      setIsPay(false);
    }else if(amount === ''){
      toastr.error(t('price_field_is_required'))
      setIsPay(false);
    }else if(purpose === ''){
      toastr.error(t('comments_field_is_required'))
      setIsPay(false);
    }else{
      try {
        const results = await postData('new-additional-payment', {
          name : name,
          email : email,
          amount : amount,
          purpose: purpose,      
          subscriptionType : subscriptionType,
          paymentMode : paymentMode, 
        });
        if(results.status && results.status === 200){
          setIsPay(false);          
          setPaymentUrl(results?.data?.redirect_url);
          setIsVisabled(true);   
          setIsOpen(false);
          toastr.success(results.message);
        }else{
          console.log(results.message);
          setIsPay(false);
        }
      } catch (error) {
        console.log(error.message);
        setIsPay(false);
      }
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('request_payment')}
                    </Dialog.Title>
                    <div className="absolute top-2 right-2 z-10">
                      <Button 
                      buttonLabelClasses=''
                      buttonType={'button'} 
                      buttonIcon={'fa-light fa-times'} 
                      buttonIconPosition={'left'} 
                      buttonLabel={''} 
                      buttonClasses={'!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                      functions={closeModal}
                      buttonHasLink={false}
                      />
                    </div>
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Input 
                        label={t('name')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_name')}
                        inputValue={name}  
                        setInput={setName} 
                        />
                      </div>
                      <div className="relative mb-3">
                        <Input 
                        label={t('email')} 
                        labelClasses={'!text-xs'} 
                        inputType={'email'}                   
                        inputPlaceholder={t('enter_email')}
                        inputValue={email}  
                        setInput={setEmail} 
                        />
                      </div>
                      <div className="relative mb-3">
                        <Input 
                        label={t('price')} 
                        labelClasses={'!text-xs'} 
                        inputType={'number'}                   
                        inputPlaceholder={t('enter_price')}
                        inputValue={amount}  
                        setInput={setAmount} 
                        />
                      </div>
                      <div className="relative mb-3">
                        <Textarea 
                        label={t('comments')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_comments')}
                        inputValue={purpose}  
                        setTextarea={setPurpose}
                        />
                      </div>                      
                      <div className="relative mb-3">
                        <Button
                          buttonClasses='!bg-teal-500 mx-auto'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(isPay)?'fa-light fa-spinner fa-spin':'fa-light fa-arrow-right-to-arc'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('process')}
                          functions={onMakePayment}
                          buttonHasLink={false}
                          buttonDisabled={isPay}
                        />                        
                      </div>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default MakeCreateAdditionalPaymentPopup;
