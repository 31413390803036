import React,{useState,useEffect} from 'react';
import Button from '../../../form/Button';
import Banner from "../../../../assets/image/Version4-ezgif.com-video-to-gif-converter.gif"
import { API_URL } from '../../../../config/host';

import { postData } from '../../../../services/api';
import { useTranslation } from "react-i18next";

const HomeBanner = () => {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const { i18n,t } = useTranslation();

  useEffect(() => {
    // Banner List Data
    const loadBannerList = async () => {
      setLoading(true);
      try {
        const result = await postData("banner/list", {
          status: 1,
        });
        if (result.data) {
          setBanners(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadBannerList();
  }, []);

  return (
    <>
      <section className="relative py-5 xs:py-10 lg:py-16 xl:py-20 2xl:py-28 " id="homebanner-section">
          <div className="w-full lg:max-w-5xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-5">
              <div className="block lg:flex flex-wrap justify-between space-y-5 lg:space-y-0">
                  <div className="lg:w-5/12 xl:w-1/2 w-full">
                      <div className="max-w-2xl w-full flex flex-col space-y-2 xs:space-y-6 xl:space-y-10">
                          {/* <div className="text-sm xs:text-base xl:text-lg text-slate-700 font-Inter" >Secure Your Company’s Leadership Future.</div> */}
                          <h1 className="text-2xl xs:text-3xl lg:text-4xl xl:text-5xl  font-PlusJakartaSans font-extrabold text-slate-950">{(banners && banners.length>0)?banners[0]?.name:''}</h1>
                          <div className="text-sm xs:text-base xl:text-lg text-slate-700 font-Inter" >
                          {(banners && banners.length>0)?banners[0]?.description:''}
                          </div>
                          <div className="relative flex">
                              <Button
                                  buttonTarget="_blank"
                                  buttonLabel="Schedule Your Demo"
                                  buttonLabelClasses="capitalize font-PlusJakartaSans !font-bold tracking-wider lg:text-base xl:!text-lg"
                                  buttonClasses="lg:h-12 xl:h-14 !bg-dark-teal-500 px-5"
                                  buttonHasLink={true}
                                  buttonLink="//calendly.com/successionnow/30min?utm_source=website"
                              />
                          </div>
                      </div>
                  </div>
                  <div className="lg:w-1/2 xl:w-1/2 w-full flex flex-col">
                      <div className="relative w-full flex justify-center lg:justify-end">
                          <div className="w-full relative lg:aspect-auto xl:aspect-[3/2] max-w-full lg:max-w-xl shadow bg-transparent rounded-lg overflow-hidden p-2">
                          <iframe
                            src="https://www.youtube.com/embed/RTQt3OEBF_0?enablejsapi=1&rel=0"
                            title="YouTube video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="w-full h-full"
                          ></iframe>
                            {/* {
                              (banners && banners.length>0)?
                              <img src={API_URL+(banners && banners.length>0)?banners[0]?.image:''} alt="Home Banner" className="w-full h-full object-cover relative z-[-1]"/>:
                              <img src={Banner} alt="Home Banner" className="w-full h-full object-cover relative z-[-1]"/>
                            } */}
                              
                              {/* <button type="button" className="m-auto absolute w-16 h-16 xs:w-20 md:w-28 xs:h-20 md:h-28 bg-white/70 rounded-full   top-0 left-0 right-0 bottom-0 z-[1] flex items-center justify-center drop-shadow-2xl text-xl">
                                  <i className="fa-solid fa-play"></i>
                              </button> */}
                              
                          </div>
                          
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  );
};

export default HomeBanner;